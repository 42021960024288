import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { usersService } from "../../@core/api/base-api/users.api";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { NbIconConfig, NbToastrService } from "@nebular/theme";

@Component({
  selector: "app-edit-user-title-name",
  templateUrl: "./edit-user-title-name.component.html",
  styleUrls: ["./edit-user-title-name.component.scss"],
})
export class EditUserTitleNameComponent implements OnInit {
  editForm: FormGroup;
  roles: any;
  idTitle: string;
  data: any;
  loading: boolean;
  constructor(
    private fb: FormBuilder,
    private apiU: usersService,
    private location: Location,
    private route: ActivatedRoute,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.loading = false;
    this.idTitle = this.route.snapshot.paramMap.get("id");
    if (!this.idTitle) {
      this.goBack();
    }

    this.apiU.getUserTitleName(this.idTitle).subscribe((res) => {
      this.setData(res);
      this.apiU.getRoles().subscribe((data) => {
        this.roles = data;
      });
    });

    this.createForm();
  }

  setData(data) {
    this.editForm.get("name").setValue(data["name"]);
    this.editForm.get("roles").setValue(data["roles"]);
  }

  createForm() {
    this.editForm = this.fb.group({
      name: ["", [Validators.required, Validators.minLength(3)]],
      roles: [[], [Validators.required]],
    });
  }

  get nameInvalid() {
    return (
      this.editForm.get("name").invalid && this.editForm.get("name").touched
    );
  }

  get rolesInvalid() {
    return (
      this.editForm.get("roles").invalid && this.editForm.get("roles").touched
    );
  }

  goBack() {
    this.location.back();
  }

  save() {
    this.loading = true;
    if (this.editForm.invalid) {
      this.loading = false;
      return Object.values(this.editForm.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }

    this.apiU
      .updatetUserTitleNames(this.idTitle, this.editForm.value)
      .subscribe(
        (data) => {
          this.loading = false;
          this.showToast(
            "Message",
            "User Title Name Updated!",
            "success",
            "checkmark-outline"
          );
          this.location.back();
        },
        (err) => {
          this.loading = false;
          this.showToast(
            "Message",
            "An error occurred, please try again",
            "danger",
            "alert-circle-outline"
          );
        }
      );
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
