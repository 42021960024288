import { Component, OnInit, ViewChild } from "@angular/core";
import { DocumentsService } from "../@core/api/base-api/documents.api";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { AuthService } from "../@auth/auth.service";
import { WindowDeleteConfirmComponent } from "../shared/window-delete-confirm/window-delete-confirm.component";
import { NbDialogService, NbIconConfig, NbToastrService } from "@nebular/theme";

@Component({
  selector: "app-requests-sent",
  templateUrl: "./requests-sent.component.html",
  styleUrls: ["./requests-sent.component.scss"],
})
export class RequestsSentComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  requests: any = [];
  school: string;
  displayedColumns: string[] = [
    "documentId.documentName",
    "respond.userId.firstName",
    "dateRequest",
    "respond.status",
    "view",
  ];
  dataSource = new MatTableDataSource();
  loading: boolean;
  permiDelete: boolean;
  permiCreate: boolean;
  constructor(
    private api: DocumentsService,
    private auth: AuthService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.school = localStorage.getItem("urlId");
    this.getData();
    this.auth.permissions$.subscribe((data) => {
      this.permiCreate = data.includes("create:request");
      this.permiDelete = data.includes("delete:request");
    });
  }

  getData() {
    this.api.listRequest().subscribe((data) => {
      this.requests = data;
      this.dataSource = new MatTableDataSource(this.requests);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
        return this.getPropertyByPath(data, sortHeaderId);
      };
      this.loading = false;
      this.dataSource.filterPredicate = (data, filter: string) => {
        const accumulator = (currentTerm, key) => {
          return this.nestedFilterCheck(currentTerm, data, key);
        };
        const dataStr = Object.keys(data).reduce(accumulator, "").toLowerCase();
        // Transform the filter by converting it to lowercase and removing whitespace.
        const transformedFilter = filter.trim().toLowerCase();
        return dataStr.indexOf(transformedFilter) !== -1;
      };
    });
  }

  getPropertyByPath(obj: Object, pathString: string) {
    return pathString.split(".").reduce((o, i) => o[i], obj);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === "object") {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }

  delete(request) {
    this.dialogService
      .open(WindowDeleteConfirmComponent, {
        context: {
          title: "Request",
          name: request.documentId.documentName,
        },
      })
      .onClose.subscribe((val) => {
        if (val) {
          this.api.deleteRequest(request._id).subscribe(
            (data) => {
              this.showToast(
                "Message",
                "Deleted Request!",
                "success",
                "checkmark-outline"
              );
              this.getData();
            },
            (err) => {
              this.showToast(
                "Message",
                "An error occurred, please try again",
                "danger",
                "alert-circle-outline"
              );
            }
          );
        }
      });
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
