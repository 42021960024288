import { Component } from "@angular/core";
import { AuthService } from "../../../@auth/auth.service";

@Component({
  selector: "app-main-layout",
  styleUrls: ["./main.layout.scss"],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed *ngIf="auth.loggedIn">
        <app-header></app-header>
      </nb-layout-header>

      <nb-sidebar
        class="menu-sidebar"
        tag="menu-sidebar"
        responsive
        start
        *ngIf="auth.loggedIn"
      >
        <ng-content select="nb-menu"></ng-content>
        <div class="watermark-div">
          <img class="img-fluid mb-2" src="assets/images/watermark.png" />
        </div>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <!--<nb-layout-footer fixed>
        <app-footer></app-footer>
      </nb-layout-footer>-->
    </nb-layout>
  `,
})
export class MainLayoutComponent {
  constructor(public auth: AuthService) {}
}
