import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { SchoolsService } from "../../@core/api/base-api/schools.api";
import { AuthService } from "../../@auth/auth.service";
import { Location } from "@angular/common";
import { MatTableDataSource } from "@angular/material/table";
import { PeriodicElement } from "../../schools/edit-school/edit-school.component";
import { DocumentsService } from "../../@core/api/base-api/documents.api";
import { usersService } from "../../@core/api/base-api/users.api";
import { NbToastrService, NbIconConfig } from "@nebular/theme";

@Component({
  selector: "app-edit-campus",
  templateUrl: "./edit-campus.component.html",
  styleUrls: ["./edit-campus.component.scss"],
})
export class EditCampusComponent implements OnInit, OnChanges {
  @Input() call: boolean;
  @Input() idE: string;
  @Input() schoolE: string;
  @Input() typeP: string;
  @Output() statusP: EventEmitter<any> = new EventEmitter<any>();
  editCampus: FormGroup;
  campusId: string;
  id: string;
  role: string;
  accountNumber: string;
  form: boolean;
  programs: any = [];
  schoolId: string;
  loading: boolean;
  loading1: boolean;
  loading2: boolean;
  loading3: boolean;
  loading4: boolean;
  loading5: boolean;
  tab: number;
  dataO1: any;
  dataO2: any;
  dataO3: any;
  dataO4: any;
  dataO5: any;
  state: boolean;
  state1: boolean;
  state2: boolean;
  state3: boolean;
  state4: boolean;
  state5: boolean;
  columnsPrograms: any;
  columnsCourses: any;
  columnsStudents: any;
  columns3: any;
  columns4: any;
  columns5: any;
  title1: string;
  title2: string;
  title3: string;
  title4: string;
  title5: string;
  bucket: string;
  driveS: string;
  cView: boolean;
  admin: string;
  getA: string;
  cDate: any;
  printv: boolean;
  programsD: any = [];
  drives: any = [];
  public date1 = new Date();
  public date2 = new Date();
  formatPhone: string;
  driveSelected: string;
  loadingD: boolean;
  school: string;
  responseFile: any;
  path: string;
  schoolImg: string;
  viewLogo: boolean;
  imgInvalid: boolean;
  infoImgInvalid: string;
  file: File;
  fileName: string;
  object: {};
  logo: string;
  permiStudent: boolean;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private api: SchoolsService,
    private apiD: DocumentsService,
    private auth: AuthService,
    private location: Location,
    private apiS: usersService,
    private toastrService: NbToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.idE && changes.idE) {
      if (changes.idE.currentValue != changes.idE.previousValue) {
        this.auth.getUser$().subscribe((data) => {
          this.campusId = changes.idE.currentValue;
          this.schoolId = changes.schoolE.currentValue;
          this.typeP = changes.typeP.currentValue;
          this.getData();
        });
      }
    }
  }

  onFileChanged(event: any) {
    this.imgInvalid = false;
    this.infoImgInvalid = "";
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].size > 1048000) {
        this.imgInvalid = true;
        this.infoImgInvalid = "Invalid size file, only accept  < 1MB";
      } else if (
        event.target.files[0].type == "image/jpeg" ||
        event.target.files[0].type == "image/jpg" ||
        event.target.files[0].type == "image/png"
      ) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.schoolImg = reader.result as string;
          this.viewLogo = true;
          var img = new Image();
          img.onload = function (scope) {
            if (img.width > 550 || img.height > 300) {
              this.imgInvalid = true;
              this.file = "";
              this.fileName = "";
              this.schoolImg = "";
              this.infoImgInvalid =
                "Width: " + img.width + "px Height: " + img.height + "px";
            }
          }.bind(this);
          img.src = reader.result as string;
        };
        this.file = event.target.files[0];
        this.fileName = this.file.name;
      } else {
        this.imgInvalid = true;
        this.infoImgInvalid = "Invalid file extension, only accept  PNG, JPG";
      }
    }
  }

  ngOnInit() {
    this.loading = true;
    this.loadingD = false;
    this.cView = true;
    this.campusId = this.route.snapshot.paramMap.get("id");
    this.schoolId = this.route.snapshot.paramMap.get("schoolId");

    if (!this.campusId && !this.call) {
      this.router.navigate(["campus"]);
      return;
    }
    this.columnsPrograms = [
      "select",
      "programTitle",
      "credentialIssued",
      "labHours",
      "directorEducationName",
      "options",
    ];
    this.columnsCourses = [
      "select",
      "courseName",
      "totalClockhours",
      "courseTypes",
      "totalCredits",
      "mainCourseInstructorName",
      "options",
    ];
    this.columnsStudents = [
      "select",
      "userName",
      "email",
      "systemStatus",
      "cellPhone",
      "options",
    ];
    this.columns4 = [
      "select",
      "userName",
      "userInformation",
      "documentTemplateList",
      "rolesandPerimissions",
      "renewalBy",
      "approval",
    ];
    this.columns5 = ["select", "name", "options"];
    this.title1 = "Programs";
    this.title2 = "Courses";
    this.title3 = "Drives";
    this.title4 = "Students";
    // this.title5 = "School/Campus Drive";
    this.form = false;
    if (!this.call) {
      this.auth.permissions$.subscribe((res) => {
        this.permiStudent = res.includes("read:campus-student");
        this.getData();
      });
    }
  }

  getData() {
    this.school = localStorage.getItem("urlId");
    if (this.school == "admin") {
      this.admin = "/admin";
    }
    if (this.permiStudent) {
      this.createForm();
      const value = this.admin === "/admin" ? this.schoolId : this.school;
      this.api.getTenant(value).subscribe((data) => {
        this.bucket = data["bucket"];
        this.api.getCampus(this.campusId).subscribe((data) => {
          this.setData(data);
        });
      });
    } else {
      const value = this.admin === "/admin" ? this.schoolId : this.school;
      this.api.getTenant(value).subscribe((data) => {
        this.bucket = data["bucket"];
        this.auth.getUser$().subscribe((data) => {
          this.id = data["sub"];
          this.role = data["https://cor4edu.com/roles"][0];
          this.createForm();
          this.form = true;
          const value = this.schoolId ? this.schoolId : "";
          this.api.listPrograms(value, this.admin).subscribe((data) => {
            this.programs = data;
          });
          if (this.role == "cor-admin" || this.role == "ag-admin-us") {
            this.api
              .getCampus(this.campusId, `/${this.schoolId}`, this.admin)
              .subscribe((data) => {
                this.setData(data);
              });
          } else {
            this.api.getCampus(this.campusId).subscribe((data) => {
              this.setData(data);
            });
          }
        });
      });
    }
  }

  drive(val) {
    this.columns5 = [
      "select",
      "name",
      "description",
      "expiration",
      "recipient",
      "options",
    ];
    this.driveSelected = val;
    const value = this.admin === "/admin" ? `/${this.schoolId}` : "";
    this.apiD
      .getListDocumentsByDrive(val, value, this.admin)
      .subscribe((data) => {
        this.loading5 = true;
        this.dataO3 = data["documents"];
        this.state = true;
        this.driveS = data["name"];
        this.loading5 = false;
      });
  }

  public tableR(name: any): void {
    this.getData();
  }

  public refreshR(data: any): void {
    this.columns5 = ["select", "name", "options"];
    const value = this.admin === "/admin" ? `/${this.schoolId}` : "";
    this.api
      .updateRelationshipCampus(
        this.campusId,
        { [data[1]]: [data[0]] },
        this.admin,
        value
      )
      .subscribe((data) => {
        this.getData();
      });
  }

  refresh = function (data) {
    this.loading = true;
    this.loading = true;
    this.dataO1 = data;
    this.data1 = new MatTableDataSource<PeriodicElement>(this.dataO1);
    this.state = false;
    this.loading = false;
  };
  refresh1 = function (data) {
    this.loading1 = true;
    this.dataO2 = data;
    this.data2 = new MatTableDataSource<PeriodicElement>(this.dataO2);
    this.state1 = false;
    this.loading1 = false;
  };

  refresh2 = function (data) {
    this.loading2 = true;
    this.dataO3 = data;
    this.data3 = new MatTableDataSource<PeriodicElement>(this.dataO3);
    this.state = false;
    this.loading3 = false;
  };

  refresh3 = function (data) {
    this.loading2 = true;
    this.dataO4 = data;
    this.data4 = new MatTableDataSource<PeriodicElement>(this.dataO3);
    this.state4 = false;
    this.loading4 = false;
  };

  refresh4 = function (data) {
    this.loading5 = true;
    this.dataO5 = data;
    this.data5 = new MatTableDataSource<PeriodicElement>(this.dataO5);
    this.state5 = false;
    this.loading5 = false;
  };

  public nameDrive(val: any): void {
    if (!this.isEmpty(val)) {
      if (val.name1 != undefined) {
        this.tab = 1;
        this.drive(val.name1);
      } else if (val.name2 != undefined) {
        this.tab = 2;
        this.drive(val.name2);
      }
    } else {
      //this.refresh();
      // this.refresh2();
      // this.refresh3();
    }
  }

  dismiss(event: boolean) {
    this.statusP.emit(false);
  }

  setData(data) {
    this.cDate = data;
    if (!this.call) {
      this.loading = true;
      this.refresh(data["programs"]);
      this.refresh1(data["courses"]);
      this.drives = data["drives"];
      this.refresh2(this.drives);
      this.refresh3(data["students"]);
      //this.refresh4();
    }
    this.editCampus
      .get("CampusStateIDNumber")
      .setValue(data["CampusStateIDNumber"]);
    this.editCampus.get("campusName").setValue(data["campusName"]);
    this.editCampus.get("campusAddress").setValue(data["campusAddress"]);
    if (this.cDate.campusPhone !== null) {
      this.formatPhone =
        "(" +
        this.cDate.campusPhone.toString().substring(0, 3) +
        ") " +
        this.cDate.campusPhone.toString().substring(3, 6) +
        "-" +
        this.cDate.campusPhone.toString().substring(6, 10);
    }
    this.editCampus.get("campusPhone").setValue(data["campusPhone"]);
    this.editCampus
      .get("campusAdmissionEmail")
      .setValue(data["campusAdmissionEmail"]);
    this.editCampus
      .get("campusDirectorName")
      .setValue(data["campusDirectorName"]);
    this.editCampus
      .get("mainContactVisitsName")
      .setValue(data["mainContactVisitsName"]);
    this.editCampus
      .get("directorEducationName")
      .setValue(data["directorEducationName"]);
    this.editCampus
      .get("directorAdmissionsName")
      .setValue(data["directorAdmissionsName"]);
    this.editCampus
      .get("directorCareerServicesName")
      .setValue(data["directorCareerServicesName"]);
    this.editCampus
      .get("directorFinancialAidName")
      .setValue(data["directorFinancialAidName"]);
    this.editCampus.get("bursarName").setValue(data["bursarName"]);
    this.editCampus
      .get("academicAdvisorName")
      .setValue(data["academicAdvisorName"]);
    this.editCampus
      .get("administrativeAssistantName")
      .setValue(data["administrativeAssistantName"]);
    this.editCampus.get("libararianName").setValue(data["libararianName"]);
    this.editCampus
      .get("libraryResourcesName")
      .setValue(data["libraryResourcesName"]);
    this.date1 = new Date(data["libraryResourcesExpirationDate"]);
    this.editCampus.get("libraryResourcesExpirationDate").setValue(this.date1);
    this.date2 = new Date(data["fireInspectionExpirationDate"]);
    this.editCampus.get("fireInspectionExpirationDate").setValue(this.date2);
    this.editCampus
      .get("accreditationName")
      .setValue(data["accreditationName"]);
    this.editCampus
      .get("usingTitleIVFunding")
      .setValue(data["usingTitleIVFunding"]);
    this.editCampus.get("websiteUrl").setValue(data["websiteUrl"]);
    if (data["logo"] != undefined) {
      this.apiD
        .getFile(this.bucket, decodeURIComponent(data["logo"]))
        .subscribe((data) => {
          this.logo = data["url"];
        });
    }
    if (data.programs) {
      data.programs.forEach((e: any) => {
        this.programsD.push(e._id);
      });
    }
    this.editCampus.get("programs").setValue(this.programsD);
    if (this.typeP === "print") {
      this.printv = true;
    }
    this.loadingD = false;
  }

  print() {
    window.print();
    this.printv = false;
    this.dismiss(false);
  }

  createForm() {
    this.editCampus = this.fb.group({
      CampusStateIDNumber: [
        "",
        [Validators.required, Validators.pattern("^[0-9]*$")],
      ],
      campusName: ["", [Validators.required]],
      campusAddress: ["", [Validators.required]],
      campusPhone: ["", []],
      campusAdmissionEmail: ["", [Validators.email]],
      campusDirectorName: ["", []],
      mainContactVisitsName: ["", []],
      directorEducationName: ["", []],
      directorAdmissionsName: ["", []],
      directorCareerServicesName: ["", []],
      directorFinancialAidName: ["", []],
      bursarName: ["", []],
      academicAdvisorName: ["", []],
      administrativeAssistantName: ["", []],
      libararianName: ["", []],
      libraryResourcesName: ["", []],
      libraryResourcesExpirationDate: ["", []],
      fireInspectionExpirationDate: ["", []],
      accreditationName: [""],
      usingTitleIVFunding: [false, []],
      programs: [[]],
      websiteUrl: ["", []],
    });
  }

  get CampusStateIDNumberInvalid() {
    return (
      this.editCampus.get("CampusStateIDNumber").invalid &&
      this.editCampus.get("CampusStateIDNumber").touched
    );
  }

  get campusNameInvalid() {
    return (
      this.editCampus.get("campusName").invalid &&
      this.editCampus.get("campusName").touched
    );
  }

  get campusAddressInvalid() {
    return (
      this.editCampus.get("campusAddress").invalid &&
      this.editCampus.get("campusAddress").touched
    );
  }

  /*get campusPhoneInvalid() {
    return (
      this.editCampus.get("campusPhone").invalid &&
      this.editCampus.get("campusPhone").touched
    );
  }

  get campusAdmissionEmailInvalid() {
    return (
      this.editCampus.get("campusAdmissionEmail").invalid &&
      this.editCampus.get("campusAdmissionEmail").touched
    );
  }

  get campusDirectorNameInvalid() {
    return (
      this.editCampus.get("campusDirectorName").invalid &&
      this.editCampus.get("campusDirectorName").touched
    );
  }

  get mainContactVisitsNameInvalid() {
    return (
      this.editCampus.get("mainContactVisitsName").invalid &&
      this.editCampus.get("mainContactVisitsName").touched
    );
  }

  get directorEducationNameInvalid() {
    return (
      this.editCampus.get("directorEducationName").invalid &&
      this.editCampus.get("directorEducationName").touched
    );
  }

  get directorAdmissionsNameInvalid() {
    return (
      this.editCampus.get("directorAdmissionsName").invalid &&
      this.editCampus.get("directorAdmissionsName").touched
    );
  }

  get directorCareerServicesNameInvalid() {
    return (
      this.editCampus.get("directorCareerServicesName").invalid &&
      this.editCampus.get("directorCareerServicesName").touched
    );
  }

  get directorFinancialAidNameInvalid() {
    return (
      this.editCampus.get("directorFinancialAidName").invalid &&
      this.editCampus.get("directorFinancialAidName").touched
    );
  }

  get bursarNameInvalid() {
    return (
      this.editCampus.get("bursarName").invalid &&
      this.editCampus.get("bursarName").touched
    );
  }

  get academicAdvisorNameInvalid() {
    return (
      this.editCampus.get("academicAdvisorName").invalid &&
      this.editCampus.get("academicAdvisorName").touched
    );
  }

  get administrativeAssistantNameInvalid() {
    return (
      this.editCampus.get("administrativeAssistantName").invalid &&
      this.editCampus.get("administrativeAssistantName").touched
    );
  }

  get libararianNameInvalid() {
    return (
      this.editCampus.get("libararianName").invalid &&
      this.editCampus.get("libararianName").touched
    );
  }

  get libraryResourcesNameInvalid() {
    return (
      this.editCampus.get("libraryResourcesName").invalid &&
      this.editCampus.get("libraryResourcesName").touched
    );
  }

  get libraryResourcesExpirationDateInvalid() {
    return (
      this.editCampus.get("libraryResourcesExpirationDate").invalid &&
      this.editCampus.get("libraryResourcesExpirationDate").touched
    );
  }

  get fireInspectionExpirationDateInvalid() {
    return (
      this.editCampus.get("fireInspectionExpirationDate").invalid &&
      this.editCampus.get("fireInspectionExpirationDate").touched
    );
  }

  get usingTitleIVFundingInvalid() {
    return (
      this.editCampus.get("usingTitleIVFunding").invalid &&
      this.editCampus.get("usingTitleIVFunding").touched
    );
  }*/

  goBack() {
    this.location.back();
  }

  goEdit() {
    this.cView = false;
  }

  goCancel() {
    this.cView = true;
  }

  save() {
    this.loadingD = true;
    if (this.editCampus.invalid) {
      this.loadingD = false;
      return Object.values(this.editCampus.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }

    if (this.file) {
      this.object = { file: this.file, bucket: this.bucket };
      this.apiD.uploadFile(this.object).subscribe((response) => {
        this.responseFile = response;
        if (this.responseFile.status == "ok") {
          this.apiD.deleteFile(this.bucket, this.path).subscribe((res) => {
            this.object = Object.assign(this.editCampus.value, {
              logo: this.responseFile.file,
            });
            const value = this.admin === "/admin" ? `/${this.schoolId}` : "";
            this.api
              .updateCampus(this.campusId, this.object, value, this.admin)
              .subscribe((data) => {
                this.showToast(
                  "Message",
                  "Campus Updated!",
                  "success",
                  "checkmark-outline"
                );
                this.cView = true;
                this.getData();
              });
          });
        }
      });
    } else {
      if (this.role == "cor-admin" || this.role == "ag-admin-us") {
        this.api
          .updateCampus(
            this.campusId,
            this.editCampus.value,
            `/${this.schoolId}`,
            this.admin
          )
          .subscribe(
            (data) => {
              this.showToast(
                "Message",
                "Campus Updated!",
                "success",
                "checkmark-outline"
              );
              if (!this.call) {
                this.loadingD = false;
                this.location.back();
              }
              this.loadingD = false;
              this.statusP.emit(true);
            },
            (err) => {
              this.loadingD = false;
              this.showToast(
                "Message",
                "An error occurred, please try again",
                "danger",
                "alert-circle-outline"
              );
            }
          );
      } else {
        this.api.updateCampus(this.campusId, this.editCampus.value).subscribe(
          (data) => {
            this.showToast(
              "Message",
              "Campus Updated!",
              "success",
              "checkmark-outline"
            );
            if (!this.call) {
              this.loadingD = false;
              this.location.back();
            }
            this.loadingD = false;
            this.statusP.emit(true);
          },
          (err) => {
            this.loadingD = false;
            this.showToast(
              "Message",
              "An error occurred, please try again",
              "danger",
              "alert-circle-outline"
            );
          }
        );
      }
    }

    this.cView = true;
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
