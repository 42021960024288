export const environment = {
  auth0: {
    apiDomain: "dev-94hrnk8b.us.auth0.com",
    clientID: "8JztYYAiDdx5sex2fT834vKtD3r9SPXc",
    audience: "backendServicesDev",
    connection: "Cor4Edu",
    // tslint:disable-next-line: max-line-length
    apiToken: "abc",
  },
  apiUser: "https://a314-users-services.dev.cor4edu.com",
  apiDocuments: "https://a314-documents-services.dev.cor4edu.com",
  apiDocumentsStorage: "https://a314-storage-services.dev.cor4edu.com",
  apiSchools: "https://a314-schools-services.dev.cor4edu.com",
  production: true,
};
