import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { SchoolsService } from "../../@core/api/base-api/schools.api";
import { AuthService } from "../../@auth/auth.service";
import { Location } from "@angular/common";
import { DocumentsService } from "../../@core/api/base-api/documents.api";
import { NbToastrService, NbIconConfig } from "@nebular/theme";

@Component({
  selector: "app-add-campus",
  templateUrl: "./add-campus.component.html",
  styleUrls: ["./add-campus.component.scss"],
})
export class AddCampusComponent implements OnInit {
  @Output() status: EventEmitter<any> = new EventEmitter<any>();
  @Input() hidden: boolean;
  @Input() schoolE: string;
  @Input() call: boolean;
  @Input() tab: string;
  addCampus: FormGroup;
  id: string;
  role: string;
  accountNumber: string;
  form: boolean;
  programs: any = [];
  schoolId: string;
  school: string;
  admin: string;
  create: boolean;
  class: string;
  idSchool: string;
  loading: boolean;
  constructor(
    private fb: FormBuilder,
    private api: SchoolsService,
    private auth: AuthService,
    private location: Location,
    private route: ActivatedRoute,
    private apiD: DocumentsService,
    private toastrService: NbToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.schoolE && changes.schoolE) {
      if (changes.schoolE.currentValue != changes.schoolE.previousValue) {
        this.schoolId = changes.schoolE.currentValue;
      }
    }
  }

  ngOnInit() {
    this.loading = false;
    this.form = false;
    this.schoolId = this.route.snapshot.paramMap.get("schoolId");
    this.school = localStorage.getItem("urlId");
    if (this.school == "admin") {
      this.admin = "/admin";
    }
    this.schoolId = this.schoolId ? this.schoolId : this.schoolE;
    this.auth.getUser$().subscribe((data) => {
      this.id = data["sub"];
      this.role = data["https://cor4edu.com/roles"][0];
      this.createForm();
      this.form = true;
      if (this.role == "cor-admin" || this.role == "ag-admin-us") {
        if (this.school == "admin") {
          this.school = this.schoolE;
        }
        this.api.listPrograms(this.schoolId, this.admin).subscribe((data) => {
          this.programs = data;
        });
      } else {
        this.api.listPrograms().subscribe((data) => {
          this.programs = data;
        });
      }
    });
    const value = this.admin === "/admin" ? this.schoolId : this.school;
    this.api.getTenant(value).subscribe((data) => {
      this.idSchool = data["_id"];
    });
  }

  createForm() {
    this.addCampus = this.fb.group({
      CampusStateIDNumber: [
        "",
        [Validators.required, Validators.pattern("^[0-9]*$")],
      ],
      campusName: ["", [Validators.required]],
      campusAddress: ["", [Validators.required]],
      campusPhone: ["", []],
      campusAdmissionEmail: ["", [Validators.email]],
      campusDirectorName: ["", []],
      mainContactVisitsName: ["", []],
      directorEducationName: ["", []],
      directorAdmissionsName: ["", []],
      directorCareerServicesName: ["", []],
      directorFinancialAidName: ["", []],
      bursarName: ["", []],
      academicAdvisorName: ["", []],
      administrativeAssistantName: ["", []],
      libararianName: ["", []],
      libraryResourcesName: ["", []],
      libraryResourcesExpirationDate: ["", []],
      fireInspectionExpirationDate: ["", []],
      accreditationName: [""],
      usingTitleIVFunding: [false, []],
      accountNumber: [this.accountNumber],
      programs: [[]],
      websiteUrl: ["", []],
    });
  }

  get CampusStateIDNumberInvalid() {
    return (
      this.addCampus.get("CampusStateIDNumber").invalid &&
      this.addCampus.get("CampusStateIDNumber").touched
    );
  }

  get campusNameInvalid() {
    return (
      this.addCampus.get("campusName").invalid &&
      this.addCampus.get("campusName").touched
    );
  }

  get campusAddressInvalid() {
    return (
      this.addCampus.get("campusAddress").invalid &&
      this.addCampus.get("campusAddress").touched
    );
  }

  /*get campusPhoneInvalid() {
    return (
      this.addCampus.get("campusPhone").invalid &&
      this.addCampus.get("campusPhone").touched
    );
  }

  get campusAdmissionEmailInvalid() {
    return (
      this.addCampus.get("campusAdmissionEmail").invalid &&
      this.addCampus.get("campusAdmissionEmail").touched
    );
  }

  get campusDirectorNameInvalid() {
    return (
      this.addCampus.get("campusDirectorName").invalid &&
      this.addCampus.get("campusDirectorName").touched
    );
  }

  get mainContactVisitsNameInvalid() {
    return (
      this.addCampus.get("mainContactVisitsName").invalid &&
      this.addCampus.get("mainContactVisitsName").touched
    );
  }

  get directorEducationNameInvalid() {
    return (
      this.addCampus.get("directorEducationName").invalid &&
      this.addCampus.get("directorEducationName").touched
    );
  }

  get directorAdmissionsNameInvalid() {
    return (
      this.addCampus.get("directorAdmissionsName").invalid &&
      this.addCampus.get("directorAdmissionsName").touched
    );
  }

  get directorCareerServicesNameInvalid() {
    return (
      this.addCampus.get("directorCareerServicesName").invalid &&
      this.addCampus.get("directorCareerServicesName").touched
    );
  }

  get directorFinancialAidNameInvalid() {
    return (
      this.addCampus.get("directorFinancialAidName").invalid &&
      this.addCampus.get("directorFinancialAidName").touched
    );
  }

  get bursarNameInvalid() {
    return (
      this.addCampus.get("bursarName").invalid &&
      this.addCampus.get("bursarName").touched
    );
  }

  get academicAdvisorNameInvalid() {
    return (
      this.addCampus.get("academicAdvisorName").invalid &&
      this.addCampus.get("academicAdvisorName").touched
    );
  }

  get administrativeAssistantNameInvalid() {
    return (
      this.addCampus.get("administrativeAssistantName").invalid &&
      this.addCampus.get("administrativeAssistantName").touched
    );
  }

  get libararianNameInvalid() {
    return (
      this.addCampus.get("libararianName").invalid &&
      this.addCampus.get("libararianName").touched
    );
  }

  get libraryResourcesNameInvalid() {
    return (
      this.addCampus.get("libraryResourcesName").invalid &&
      this.addCampus.get("libraryResourcesName").touched
    );
  }

  get libraryResourcesExpirationDateInvalid() {
    return (
      this.addCampus.get("libraryResourcesExpirationDate").invalid &&
      this.addCampus.get("libraryResourcesExpirationDate").touched
    );
  }

  get fireInspectionExpirationDateInvalid() {
    return (
      this.addCampus.get("fireInspectionExpirationDate").invalid &&
      this.addCampus.get("fireInspectionExpirationDate").touched
    );
  }

  get usingTitleIVFundingInvalid() {
    return (
      this.addCampus.get("usingTitleIVFunding").invalid &&
      this.addCampus.get("usingTitleIVFunding").touched
    );
  }*/

  goBack() {
    this.location.back();
  }

  save() {
    this.loading = true;
    if (this.addCampus.invalid) {
      this.loading = false;
      return Object.values(this.addCampus.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }
    const value = this.admin === "admin" ? `${this.schoolId}` : "";
    if (this.role == "cor-admin" || this.role == "ag-admin-us") {
      const data = Object.assign(this.addCampus.value, {
        schoolId: this.idSchool,
      });
      this.api.createCampus(data, this.schoolId, this.admin).subscribe(
        (data) => {
          const object = {
            name: data["campusName"],
            schoolId: this.idSchool,
            ownerId: this.idSchool,
            private: false,
            default: false,
          };
          this.apiD.createDrive(object, this.schoolId, this.admin).subscribe(
            (res) => {
              this.api
                .updateRelationship(this.idSchool, {
                  campus: [data["_id"]],
                  drives: [res["_id"]],
                })
                .subscribe(
                  (res) => {
                    if (!this.call) {
                      this.showToast(
                        "Message",
                        "Campus Saved!",
                        "success",
                        "checkmark-outline"
                      );
                      this.loading = false;
                      this.location.back();
                    } else {
                      this.loading = false;
                      this.afterCall(data["_id"]);
                    }
                  },
                  (err) => {
                    this.loading = false;
                    this.showToast(
                      "Message",
                      "An error occurred, please try again",
                      "danger",
                      "alert-circle-outline"
                    );
                  }
                );
            },
            (err) => {
              this.loading = false;
              this.showToast(
                "Message",
                "An error occurred, please try again",
                "danger",
                "alert-circle-outline"
              );
            }
          );
        },
        (err) => {
          this.loading = false;
          this.showToast(
            "Message",
            "An error occurred, please try again",
            "danger",
            "alert-circle-outline"
          );
        }
      );
    } else {
      const data = Object.assign(this.addCampus.value, {
        schoolId: this.idSchool,
      });
      this.api.createCampus(this.addCampus.value).subscribe(
        (data) => {
          const object = {
            name: data["campusName"],
            schoolId: this.idSchool,
            ownerId: data["_id"],
            private: false,
            default: false,
          };

          this.apiD.createDrive(object).subscribe(
            (res) => {
              this.api
                .updateRelationship(this.idSchool, {
                  campus: [data["_id"]],
                  drives: [res["_id"]],
                })
                .subscribe(
                  (res) => {},
                  (err) => {
                    this.loading = false;
                    this.showToast(
                      "Message",
                      "An error occurred, please try again",
                      "danger",
                      "alert-circle-outline"
                    );
                  }
                );
              if (!this.call) {
                this.showToast(
                  "Message",
                  "Campus Saved!",
                  "success",
                  "checkmark-outline"
                );
                this.loading = false;
                this.location.back();
              } else {
                this.loading = false;
                this.afterCall(data["_id"]);
              }
            },
            (err) => {
              this.loading = false;
              this.showToast(
                "Message",
                "An error occurred, please try again",
                "danger",
                "alert-circle-outline"
              );
            }
          );
        },
        (err) => {
          this.loading = false;
          this.showToast(
            "Message",
            "An error occurred, please try again",
            "danger",
            "alert-circle-outline"
          );
        }
      );
    }
  }

  afterCall(id) {
    this.create = true;
    this.showToast(
      "Message",
      "Campus Created!",
      "success",
      "checkmark-outline"
    );
    this.class = "fade-in";
    this.status.emit([id, "campus"]);
    this.addCampus.reset({
      programs: [[]],
    });
    setTimeout(() => {
      this.class = "fadeOut";
      this.create = false;
    }, 5000);
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
