import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { AuthService } from "../../../@auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class InterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.auth.isAuthenticated$.pipe(
      mergeMap((authenticated: boolean) => {
        if (authenticated) {
          return this.auth.getTokenSilently$().pipe(
            mergeMap((token) => {
              const tokenReq = req.clone({
                setHeaders: { Authorization: `Bearer ${token}` },
              });
              return next.handle(tokenReq);
            })
          );
        } else {
          return next.handle(req);
        }
      })
    );
  }
}
