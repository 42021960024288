import { Component, OnDestroy, OnInit } from "@angular/core";
import { NbSidebarService, NbMenuItem } from "@nebular/theme";
import { AuthService } from "./@auth/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnDestroy, OnInit {
  title = "cor4edu-fe";
  menu: NbMenuItem[];
  alive = true;

  constructor(
    private sidebarService: NbSidebarService,
    public auth: AuthService
  ) {}

  ngOnInit() {
    //this.initMenu();
  }

  toggle() {
    this.sidebarService.toggle(true);
    return false;
  }

  // initMenu() {
  //   this.appMenu.getMenu()
  //     .pipe(takeWhile(() => this.alive))
  //     .subscribe(menu => {
  //       this.menu = menu;
  //     })
  //     ;
  // }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
