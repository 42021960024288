import { Component, OnInit, TemplateRef } from "@angular/core";
import { AuthService } from "../@auth/auth.service";
import { SchoolService } from "../@core/services/school.service";
import { SchoolsService } from "../@core/api/base-api/schools.api";
import { usersService } from "../@core/api/base-api/users.api";
import { ActivatedRoute, Router } from "@angular/router";
import * as Cookie from "js-cookie";
import { NbDialogService } from "@nebular/theme";
import { ShowcaseDialogComponent } from "../shared/showcase-dialog/showcase-dialog.component";
import { NbDialogRef } from "@nebular/theme";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  schoolId: string;
  userId: string;
  id: string;
  res: string;
  reload: boolean;
  reload2: boolean;
  loading: boolean;
  role: string;
  permissions: any;
  result: TemplateRef<any>;
  school: string;
  schools: any = [];
  students: any = [];
  admin: string;
  accounts: number;
  users: number;
  campus: number;
  courses: number;
  programs: number;
  permiCampus: boolean;
  permiPrograms: boolean;
  permiCourses: boolean;
  permiusers: boolean;
  permiDocuments: boolean;
  sAdmin: boolean;
  constructor(
    public auth: AuthService,
    private route: ActivatedRoute,
    private schoolService: SchoolService,
    private dialogService: NbDialogService,
    private router: Router,
    private userService: usersService,
    private api: SchoolsService
  ) {}
  ngOnInit() {
    this.sAdmin = false;
    this.schoolId = this.route.snapshot.paramMap.get("id");
    this.school = localStorage.getItem("urlId");

    if (this.school == "admin") {
      this.admin = "/admin";
    }
    this.auth.permissions$.subscribe((res) => {
      this.permiPrograms = res.includes("read:programs");
      this.permiusers = res.includes("read:users");
      this.permiCampus = res.includes("read:campus");
      this.permiCourses = res.includes("read:courses");
      this.permiDocuments = res.includes("read:documents");
    });
    this.loading = true;
    this.auth.isAuthenticated$.subscribe((data) => {
      if (data) {
        this.getUser();
      } else {
        this.auth.logout();
      }
    });

    this.api.listSchools().subscribe((data) => {
      this.schools = data;
    });

    this.auth.getUser$().subscribe((data) => {
      this.role = data["https://cor4edu.com/roles"][0];
      if (this.role == "cor-admin" || this.role == "ag-admin-us") {
        this.sAdmin = true;
        this.api.getAcoountData("?all=1").subscribe((res: number) => {
          this.accounts = res["accounts"];
          this.users = res["users"];
          this.campus = res["campus"];
          this.courses = res["courses"];
          this.programs = res["programs"];
          this.loading = false;
        });
      } else {
        // this.userService.getUsersByAccount().subscribe((data) => {
        //   this.students = data;
        // });
        this.api.getAcoountData("?school=1").subscribe((res: number) => {
          this.users = res["users"];
          this.campus = res["campus"];
          this.courses = res["courses"];
          this.programs = res["programs"];
          this.loading = false;
        });
      }
    });
    //this.getSchool();
    this.reload = false;
    this.reload2 = false;
  }

  val() {
    return this.schoolService.getValue();
  }

  getUser() {
    this.auth.getUser$().subscribe((data) => {
      if (data != undefined) {
        this.id = data["sub"];
        this.userService.getUserById(this.id).subscribe((data) => {
          if (data != null) {
            if (data["systemAccess"] == false) {
              this.res = "ACCESS DENIED: " + data["systemStatus"];
              this.open();
            }
          }
        });
      }
    });
  }

  open() {
    this.dialogService
      .open(ShowcaseDialogComponent, {
        hasBackdrop: true,
        closeOnBackdropClick: false,
        context: { title: "Error", message: this.res },
      })
      .onClose.subscribe((data) => {
        this.auth.logout();
      });
  }

  getSchool() {
    this.schoolId = localStorage.getItem("urlId");

    this.api.getTenant(this.schoolId).subscribe((data) => {
      if (data) {
        if (data["urlId"] != this.schoolId) {
          this.res = "ACCESS DENIED: User does not belong to this school";
          this.open();
        }
      }
    });
  }
}
