import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { NbDialogRef, NbIconConfig, NbToastrService } from "@nebular/theme";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { DocumentsService } from "../../@core/api/base-api/documents.api";
import { ActivatedRoute } from "@angular/router";
import { SchoolsService } from "../../@core/api/base-api/schools.api";

@Component({
  selector: "app-window-copy-document",
  templateUrl: "./window-copy-document.component.html",
  styleUrls: ["./window-copy-document.component.scss"],
})
export class WindowCopyDocumentComponent implements OnInit {
  @Input() data: any;
  @Input() drives: string;
  @Input() school: string;
  @Input() type: string;
  object: {};
  schoolId: string;
  admin: string;
  addForm: FormGroup;
  bucket: string;
  constructor(
    protected dialogRef: NbDialogRef<WindowCopyDocumentComponent>,
    private fb: FormBuilder,
    private apiD: DocumentsService,
    private route: ActivatedRoute,
    private apiS: SchoolsService,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.schoolId = localStorage.getItem("urlId");
    if (this.schoolId == "admin") {
      this.admin = "/admin";
    }
    const value = this.admin === "/admin" ? this.school : this.schoolId;
    this.apiS.getTenant(value).subscribe((data) => {
      this.bucket = data["bucket"];
    });
    this.createForm();
  }

  createForm() {
    this.addForm = this.fb.group({
      drive: [Validators.required, Validators.minLength(3)],
    });
  }

  get driveInvalid() {
    return (
      this.addForm.get("drive").invalid && this.addForm.get("drive").touched
    );
  }

  save() {
    console.log("entre", this.addForm);
    if (this.addForm.invalid) {
      return Object.values(this.addForm.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }
    if (this.data["file"]) {
      this.object = {
        id: this.addForm.value.drive,
        file: this.data["file"],
        bucket: this.bucket,
        newLocation: this.addForm.value.drive,
      };

      if (this.type === "copy") {
        this.apiD.copyFile(this.object).subscribe((response) => {
          const value = this.school ? `${this.school}` : "";
          if (response["message"] == "ok") {
            this.data = this.data;
            this.data = Object.assign(this.data, {
              file: this.addForm.value.drive + "/" + response["data"],
              drive: this.addForm.value.drive,
            });

            delete this.data["_id"];

            this.apiD.createDocument(this.data, value, this.admin).subscribe(
              (data) => {
                this.apiD
                  .updateRelationshipDrives(
                    this.addForm.value.drive,
                    {
                      documents: [data["_id"]],
                    },
                    this.admin,
                    `/${value}`
                  )
                  .subscribe((res) => {
                    this.showToast(
                      "Message",
                      "Copied Document!",
                      "success",
                      "checkmark-outline"
                    );
                    this.dismiss(true);
                  });
              },
              (err) => {
                this.showToast(
                  "Message",
                  "An error occurred, please try again",
                  "danger",
                  "alert-circle-outline"
                );
              }
            );
          }
        });
      } else if (this.type === "upload") {
        const value = this.admin === "/admin" ? `${this.school}` : "";
        this.apiD
          .uploadFile({
            id: this.addForm.value.drive,
            file: this.data["file"],
            bucket: this.bucket,
          })
          .subscribe((response) => {
            this.data = Object.assign(this.data, {
              file: this.addForm.value.drive + "/" + response["file"],
              drive: this.addForm.value.drive,
            });
            this.apiD.createDocument(this.data, value, this.admin).subscribe(
              (data) => {
                this.apiD
                  .updateRelationshipDrives(
                    this.addForm.value.drive,
                    {
                      documents: [data["_id"]],
                    },
                    this.admin,
                    `/${value}`
                  )
                  .subscribe((res) => {
                    this.showToast(
                      "Message",
                      "Copied Document!",
                      "success",
                      "checkmark-outline"
                    );
                    this.dismiss(true);
                  });
              },
              (err) => {
                this.showToast(
                  "Message",
                  "An error occurred, please try again",
                  "danger",
                  "alert-circle-outline"
                );
              }
            );
          });
      } else {
        this.apiD.moveFile(this.object).subscribe(
          (response) => {
            const value = this.school ? `${this.school}` : "";
            if (response["message"] == "ok") {
              this.apiD
                .updateDocument(
                  this.data["_id"],
                  {
                    file: response["data"],
                    drive: this.addForm.value.drive,
                  },
                  `/${value}`,
                  this.admin
                )
                .subscribe(
                  (resp) => {
                    this.showToast(
                      "Message",
                      "Copied Document!",
                      "success",
                      "checkmark-outline"
                    );
                  },
                  (err) => {
                    this.showToast(
                      "Message",
                      "An error occurred, please try again",
                      "danger",
                      "alert-circle-outline"
                    );
                  }
                );
              const valueD = this.school ? `/${this.school}` : "";
              this.apiD
                .deleteRelationshipDrives(
                  this.data["drive"],
                  {
                    documents: [this.data["_id"]],
                  },
                  this.admin,
                  valueD
                )
                .subscribe(
                  (res) => {
                    this.apiD
                      .updateRelationshipDrives(
                        this.addForm.value.drive,
                        {
                          documents: [this.data["_id"]],
                        },
                        this.admin,
                        valueD
                      )
                      .subscribe(
                        (res) => {
                          this.dismiss(true);
                        },
                        (err) => {
                          this.showToast(
                            "Message",
                            "An error occurred, please try again",
                            "danger",
                            "alert-circle-outline"
                          );
                        }
                      );
                  },
                  (err) => {
                    this.showToast(
                      "Message",
                      "An error occurred, please try again",
                      "danger",
                      "alert-circle-outline"
                    );
                  }
                );
            }
          },
          (err) => {
            this.showToast(
              "Message",
              "An error occurred, please try again",
              "danger",
              "alert-circle-outline"
            );
          }
        );
      }
    } else {
      const value = this.school ? `${this.school}` : "";
      this.apiD
        .uploadFile({
          id: this.addForm.value.drive,
          file: this.data["file"],
          bucket: this.bucket,
        })
        .subscribe((data) => {
          this.apiD.createDocument(this.data, value, this.admin).subscribe(
            (data) => {
              this.apiD
                .updateRelationshipDrives(
                  this.addForm.value.drive,
                  {
                    documents: [data["_id"]],
                  },
                  this.admin,
                  `/${value}`
                )
                .subscribe((res) => {
                  this.showToast(
                    "Message",
                    "Copied Document!",
                    "success",
                    "checkmark-outline"
                  );
                  this.dismiss(true);
                });
            },
            (err) => {
              this.showToast(
                "Message",
                "An error occurred, please try again",
                "danger",
                "alert-circle-outline"
              );
            }
          );
        });
    }
  }

  dismiss(value: boolean) {
    this.dialogRef.close(value);
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
