import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";
import { map, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { AuthService } from "../../../@auth/auth.service";
import * as Cookie from "js-cookie";
import { Router, ActivatedRoute } from "@angular/router";
import { SchoolsService } from "../../../@core/api/base-api/schools.api";
import { DocumentsService } from "../../../@core/api/base-api/documents.api";
import { usersService } from "../../../@core/api/base-api/users.api";

@Component({
  selector: "app-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly = false;
  user: any;
  schoolId: String;
  logo: string;
  bucket: string;
  name: string;
  data: any = [];
  permiSent: boolean;
  permiReceived: boolean;
  notification: boolean;
  sAdmin: boolean;
  img: string;
  currentTheme = "default";

  userMenu: any;

  constructor(
    private sidebarService: NbSidebarService,
    private themeService: NbThemeService,
    public auth: AuthService,
    private breakpointService: NbMediaBreakpointsService,
    private route: ActivatedRoute,
    private api: SchoolsService,
    private apiD: DocumentsService,
    private apiU: usersService
  ) {
    this.schoolId = localStorage.getItem("urlId");
  }

  getMenuItems() {
    if (this.sAdmin) {
      return [
        { title: "My Documents", link: `${this.schoolId}/documents-staff` },
        { title: "Log out", link: "" },
      ];
    } else {
      return [
        { title: "Profile", link: `${this.schoolId}/profile` },
        { title: "My Documents", link: `${this.schoolId}/documents-staff` },
        { title: "Log out", link: "" },
      ];
    }
  }

  ngOnInit() {
    this.notification = false;
    this.userMenu = false;

    // this.schoolId = localStorage.getItem('urlId');
    // this.SchoolService.setValue(this.schoolId);
    // Cookie.set("schoolId", this.schoolId);
    this.logo = "assets/images/logo.png";
    this.auth.permissions$.subscribe((data) => {
      this.permiSent = data.includes("read:requests-sent");
      this.permiReceived = data.includes("read:requests-received");
    });
    this.api.getTenant(this.schoolId).subscribe((data) => {
      if (data == undefined) {
        this.logo = "assets/images/logo.png";
      } else {
        if (data["logo"]) {
          this.name = data["accountOwnerCompanyName"];
          this.bucket = data["bucket"];
          this.apiD.getFile(this.bucket, data["logo"]).subscribe((data) => {
            this.logo = data["url"];
          });
        }
      }
      this.auth.getUser$().subscribe((res) => {
        this.apiU.getProfileById(res["sub"]).subscribe((data) => {
          if (data && data["picture"] != "") {
            this.apiD
              .getFile(
                encodeURIComponent(this.bucket),
                encodeURIComponent(`${res["sub"]}/${data["picture"]}`)
              )
              .subscribe((data) => {
                this.img = data["url"];
              });
          }
        });
        const conditions = ["ag-admin-us", "cor-admin"];
        this.sAdmin = conditions.some((el) =>
          res["https://cor4edu.com/roles"].includes(el)
        );
        this.userMenu = this.getMenuItems();
        this.currentTheme = this.themeService.currentTheme;

        const { xl } = this.breakpointService.getBreakpointsMap();
        this.themeService
          .onMediaQueryChange()
          .pipe(
            map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
            takeUntil(this.destroy$)
          )
          .subscribe(
            (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
          );

        this.themeService
          .onThemeChange()
          .pipe(
            map(({ name }) => name),
            takeUntil(this.destroy$)
          )
          .subscribe((themeName) => (this.currentTheme = themeName));
        this.apiU.getProfileById(res["sub"]).subscribe((data) => {
          if (data) {
            this.apiD.listRequestByUser(data["_id"]).subscribe((res) => {
              this.data = res;
              if (this.data) {
                this.notification = this.data.some(
                  (item) => item.notification === true
                );
              }
            });
          }
        });
      });
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    // this.layoutService.changeLayoutSize();
    return false;
  }

  navigateHome() {
    // this.menuService.navigateHome();
    return false;
  }
}
