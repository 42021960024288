import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { PeriodicElement } from "../../schools/edit-school/edit-school.component";
import { SelectionModel } from "@angular/cdk/collections";
import { DocumentsService } from "../../@core/api/base-api/documents.api";
import { NbDialogService, NbIconConfig } from "@nebular/theme";
import { ShowcaseDialogComponent } from "../showcase-dialog/showcase-dialog.component";
import { WindowFormEditDocumentComponent } from "../../schools/window-form-edit-document/window-form-edit-document";
import printJS from "print-js";
import { WindowFormEditProgramComponent } from "../table-programs/window-form-edit-program/window-form-edit-program";
import { SchoolsService } from "../../@core/api/base-api/schools.api";
import { WindowDeleteConfirmComponent } from "../window-delete-confirm/window-delete-confirm.component";
import { WindowCopyDocumentComponent } from "../window-copy-document/window-copy-document.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { NbToastrService } from "@nebular/theme";
import { from } from "rxjs";
import { AuthService } from "../../@auth/auth.service";

@Component({
  selector: "app-table-dynamic",
  templateUrl: "./table-dynamic.component.html",
  styleUrls: ["./table-dynamic.component.scss"],
})
export class TableDocumentsComponent implements OnInit, OnChanges {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Output() nameDrive: EventEmitter<any> = new EventEmitter<any>();
  @Output() status: EventEmitter<any> = new EventEmitter<any>();
  @Output() tableR: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshUsers: EventEmitter<any> = new EventEmitter<any>();
  displayedColumns: string[];
  @Input() dataO1;
  @Input() data1;
  @Input() school: string;
  @Input() campus: string;
  @Input() program: string;
  @Input() bucket: string;
  @Input() course: string;
  @Input() state: boolean;
  @Input() driveS: string;
  @Input() title: string;
  @Input() columns: any;
  @Input() delete: any;
  @Input() drives: any = [];
  nameD: any;
  loading: boolean;
  admin: string;
  getA: string;
  navDrive: boolean;
  permiOperate: boolean;
  selection = new SelectionModel<Element>(true, []);
  constructor(
    private apiD: DocumentsService,
    private dialogService: NbDialogService,
    private apiS: SchoolsService,
    private toastrService: NbToastrService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.navDrive = false;
    if (this.columns != undefined) {
      if (this.columns.find((item) => item === "name")) {
        this.navDrive = true;
      }
    }
    this.auth.permissions$.subscribe((res) => {
      this.permiOperate = res.includes("read-documents-drives-courses");
    });
    //
    this.loading = true;
    this.data1 = new MatTableDataSource<PeriodicElement>(this.dataO1);
    this.data1.paginator = this.paginator;
    this.data1.sort = this.sort;
    this.getA = localStorage.getItem("urlId");
    if (this.getA == "admin") {
      this.admin = "/admin";
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loading = false;
    if (this.dataO1 && changes.dataO1) {
      if (changes.dataO1.currentValue != changes.dataO1.previousValue) {
        const data = changes.dataO1.currentValue;
        if (data.some((item) => item.documents != undefined)) {
          this.navDrive = true;
        }
        this.data1 = new MatTableDataSource<PeriodicElement>(
          changes.dataO1.currentValue
        );
        this.data1.paginator = this.paginator;
        this.data1.sort = this.sort;
      }
    }

    if (this.bucket && changes.bucket) {
      if (changes.bucket.currentValue != changes.bucket.previousValue) {
        this.bucket = changes.bucket.currentValue;
      }
    }
    if (changes.columns && changes.columns) {
      if (changes.columns.currentValue != undefined) {
        this.displayedColumns = this.columns;
      }
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRowsMinusExcluded = this.data1.data.filter((row) => !row.default)
      .length;

    return numSelected === numRowsMinusExcluded;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.data1.data.forEach((row) => {
          if (!row.default) {
            this.selection.select(row);
          }
        });
  }

  open() {
    this.dialogService.open(ShowcaseDialogComponent, {
      context: {
        title: "Error",
        message: "An error occurred, I tried again",
      },
    });
  }

  public drive(name1: any, name2 = ""): void {
    const value = { name1, name2 };
    this.nameD = value;
    this.nameDrive.emit(value);
  }
  public refresh(): void {
    this.status.emit(true);
    this.selection.clear();
  }

  removeSelectedRows() {
    const names = [];
    this.selection.selected.forEach((item) => {
      if (!item["name"]) {
        names.push(
          item["documentName"] ||
            item["programTitle"] ||
            item["courseName"] ||
            item["form"] ||
            item["campusName"] ||
            item["name"] ||
            item["firstName"] + " " + item["lastName"]
        );
      }
    });
    let context;
    const value = this.admin === "/admin" ? `/${this.school}` : "";
    if (
      this.selection.selected[0]["name"] != "" &&
      this.selection.selected[0]["name"] != undefined
    ) {
      context = {
        context: {
          title: "User Title Name",
          name: names,
          dataT: "drive",
          data: this.selection.selected[0],
          drives: this.drives,
        },
        hasScroll: true,
      };
    } else {
      context = {
        context: {
          title: "User Title Name",
          name: names,
        },
        hasScroll: true,
      };
    }

    if (!this.selection.selected[0]["name"]) {
      this.dialogService
        .open(WindowDeleteConfirmComponent, context)
        .onClose.subscribe((val) => {
          if (val) {
            this.selection.selected.forEach((item) => {
              if (
                item["documentName"] ||
                item["programTitle"] ||
                item["courseName"] ||
                item["form"] ||
                item["firstName"] ||
                item["campusName"]
              ) {
                if (item["programTitle"]) {
                  this.apiS
                    .deleteProgram(item["_id"], value, this.admin)
                    .subscribe(
                      (data) => {
                        this.showToast(
                          "Message",
                          "Program Deleted!",
                          "success",
                          "checkmark-outline"
                        );
                        this.apiS
                          .deleteRelationship(this.school, {
                            programs: [item["_id"]],
                          })
                          .subscribe(
                            (data) => {},
                            (err) => {
                              this.showToast(
                                "Message",
                                "An error occurred, please try again",
                                "danger",
                                "alert-circle-outline"
                              );
                            }
                          );
                      },
                      (err) => {
                        this.showToast(
                          "Message",
                          "An error occurred, please try again",
                          "danger",
                          "alert-circle-outline"
                        );
                      }
                    );
                  let index: number = this.dataO1.findIndex((d) => d === item);
                  this.dataO1.splice(index, 1);
                  this.data1 = new MatTableDataSource<Element>(this.dataO1);
                } else if (item["documentName"]) {
                  this.apiD
                    .deleteDocument(item["_id"], value, this.admin)
                    .subscribe(
                      (data) => {
                        this.showToast(
                          "Message",
                          "Document Deleted!",
                          "success",
                          "checkmark-outline"
                        );
                        this.apiD
                          .deleteFile(
                            this.bucket,
                            encodeURIComponent(item["file"])
                          )
                          .subscribe(
                            (data) => {
                              if (data["message"] === "error") {
                                // this.open();
                              }
                            },
                            (err) => {
                              this.showToast(
                                "Message",
                                "An error occurred, please try again",
                                "danger",
                                "alert-circle-outline"
                              );
                            }
                          );
                      },
                      (err) => {
                        this.showToast(
                          "Message",
                          "An error occurred, please try again",
                          "danger",
                          "alert-circle-outline"
                        );
                      }
                    );
                  let index: number = this.dataO1.findIndex((d) => d === item);
                  this.dataO1.splice(index, 1);
                  this.data1 = new MatTableDataSource<Element>(this.dataO1);
                } else if (item["courseName"]) {
                  this.apiS
                    .deleteCourse(item["_id"], value, this.admin)
                    .subscribe(
                      (data) => {
                        this.showToast(
                          "Message",
                          "Course Deleted!",
                          "success",
                          "checkmark-outline"
                        );
                        this.apiS
                          .deleteRelationship(this.school, {
                            courses: [item["_id"]],
                          })
                          .subscribe(
                            (data) => {},
                            (err) => {
                              this.showToast(
                                "Message",
                                "An error occurred, please try again",
                                "danger",
                                "alert-circle-outline"
                              );
                            }
                          );
                      },
                      (err) => {
                        this.showToast(
                          "Message",
                          "An error occurred, please try again",
                          "danger",
                          "alert-circle-outline"
                        );
                      }
                    );
                  let index: number = this.dataO1.findIndex((d) => d === item);
                  this.dataO1.splice(index, 1);
                  this.data1 = new MatTableDataSource<Element>(this.dataO1);
                } else if (item["form"] && ["name"]) {
                  this.apiD
                    .deleteForm(item["_id"], `/${this.school}`)
                    .subscribe(
                      (data) => {
                        this.showToast(
                          "Message",
                          "Form Deleted!",
                          "success",
                          "checkmark-outline"
                        );
                      },
                      (err) => {
                        this.showToast(
                          "Message",
                          "An error occurred, please try again",
                          "danger",
                          "alert-circle-outline"
                        );
                      }
                    );
                  let index: number = this.dataO1.findIndex((d) => d === item);
                  this.dataO1.splice(index, 1);
                  this.data1 = new MatTableDataSource<Element>(this.dataO1);
                } else if (item["firstName"]) {
                  const value =
                    this.admin === "/admin" ? `/${this.school}` : "";
                  if (this.course) {
                    this.apiS
                      .deleteRelationshipCourses(
                        this.course,
                        {
                          students: [item["_id"]],
                        },
                        this.admin,
                        value
                      )
                      .subscribe(
                        (data) => {
                          if (!data) {
                            this.showToast(
                              "Message",
                              "An error occurred, please try again",
                              "danger",
                              "alert-circle-outline"
                            );
                          }
                          this.showToast(
                            "Message",
                            "User Deleted!",
                            "success",
                            "checkmark-outline"
                          );
                        },
                        (err) => {
                          this.showToast(
                            "Message",
                            "An error occurred, please try again",
                            "danger",
                            "alert-circle-outline"
                          );
                        }
                      );
                  } else if (this.campus) {
                    this.apiS
                      .deleteRelationshipCampus(
                        this.campus,
                        {
                          students: [item["_id"]],
                        },
                        this.admin,
                        value
                      )
                      .subscribe(
                        (data) => {
                          this.showToast(
                            "Message",
                            "User Deleted!",
                            "success",
                            "checkmark-outline"
                          );
                        },
                        (err) => {
                          this.showToast(
                            "Message",
                            "An error occurred, please try again",
                            "danger",
                            "alert-circle-outline"
                          );
                        }
                      );
                  } else if (this.program) {
                    this.apiS
                      .deleteRelationshipPrograms(
                        this.program,
                        {
                          students: [item["_id"]],
                        },
                        this.admin,
                        value
                      )
                      .subscribe(
                        (data) => {
                          this.showToast(
                            "Message",
                            "User Deleted!",
                            "success",
                            "checkmark-outline"
                          );
                        },
                        (err) => {
                          this.showToast(
                            "Message",
                            "An error occurred, please try again",
                            "danger",
                            "alert-circle-outline"
                          );
                        }
                      );
                  } else if (this.school) {
                    const value =
                      this.admin === "/admin" ? this.school : this.getA;
                    this.apiS
                      .deleteRelationship(value, {
                        students: [item["_id"]],
                      })
                      .subscribe(
                        (data) => {
                          this.showToast(
                            "Message",
                            "User Deleted!",
                            "success",
                            "checkmark-outline"
                          );
                        },
                        (err) => {
                          this.showToast(
                            "Message",
                            "An error occurred, please try again",
                            "danger",
                            "alert-circle-outline"
                          );
                        }
                      );
                  }

                  let index: number = this.dataO1.findIndex((d) => d === item);
                  this.dataO1.splice(index, 1);
                  this.data1 = new MatTableDataSource<Element>(this.dataO1);
                  this.refreshUsers.emit(true);
                } else if (item["campusName"]) {
                  this.apiS
                    .deleteCampus(item["_id"], value, this.admin)
                    .subscribe(
                      (data) => {
                        this.showToast(
                          "Message",
                          "Campus Deleted!",
                          "success",
                          "checkmark-outline"
                        );
                        this.apiS
                          .deleteRelationship(this.school, {
                            campus: [item["_id"]],
                          })
                          .subscribe(
                            (data) => {},
                            (err) => {
                              this.showToast(
                                "Message",
                                "An error occurred, please try again",
                                "danger",
                                "alert-circle-outline"
                              );
                            }
                          );
                      },
                      (err) => {
                        this.showToast(
                          "Message",
                          "An error occurred, please try again",
                          "danger",
                          "alert-circle-outline"
                        );
                      }
                    );

                  let index: number = this.dataO1.findIndex((d) => d === item);
                  this.dataO1.splice(index, 1);
                  this.data1 = new MatTableDataSource<Element>(this.dataO1);
                }
              } else {
              }
            });
            this.selection = new SelectionModel<Element>(true, []);
          }
        });
    } else {
      // llamada inicial
      this.procesar(this.selection.selected, 0);
    }
  }

  procesar(items, index) {
    if (index == items.length) return;

    let item = items[index];
    const context = {
      context: {
        title: "User Title Name",
        name: item["name"],
        dataT: "drive",
        data: item,
        drives: this.drives,
      },
      hasScroll: true,
    };
    this.dialogService
      .open(WindowDeleteConfirmComponent, context)
      .onClose.subscribe((val) => {
        const value = this.admin === "/admin" ? `/${this.school}` : "";
        if (val === true) {
          this.apiD.deleteDrive(item["_id"], value, this.admin).subscribe(
            (data) => {
              this.showToast(
                "Message",
                "Drive Deleted!",
                "success",
                "checkmark-outline"
              );
            },
            (err) => {
              this.showToast(
                "Message",
                "An error occurred, please try again",
                "danger",
                "alert-circle-outline"
              );
            }
          );
        } else if (typeof val === "object") {
          this.apiD
            .updateRelationshipDrives(
              val["drive"],
              {
                documents: item["documents"],
              },
              this.admin,
              value
            )
            .subscribe(
              (res) => {
                this.apiD.deleteDrive(item["_id"], value, this.admin).subscribe(
                  (data) => {
                    this.showToast(
                      "Message",
                      "Drive Deleted!",
                      "success",
                      "checkmark-outline"
                    );
                    this.apiD
                      .deleteDriveStorage(this.bucket, item["_id"])
                      .subscribe((res) => {});
                  },
                  (err) => {
                    this.showToast(
                      "Message",
                      "An error occurred, please try again",
                      "danger",
                      "alert-circle-outline"
                    );
                  }
                );
              },
              (err) => {
                this.showToast(
                  "Message",
                  "An error occurred, please try again",
                  "danger",
                  "alert-circle-outline"
                );
              }
            );
        }
        let indexS: number = this.dataO1.findIndex((d) => d === item);
        this.dataO1.splice(indexS, 1);
        this.data1 = new MatTableDataSource<Element>(this.dataO1);
        console.log(index++);
        this.procesar(items, index++);
      });
    this.selection = new SelectionModel<Element>(true, []);
  }

  copy(data) {
    this.dialogService
      .open(WindowCopyDocumentComponent, {
        context: {
          data,
          drives: this.drives,
          school: this.school,
          type: "copy",
        },
        hasScroll: true,
      })
      .onClose.subscribe((name) => {});
  }

  move(data) {
    this.dialogService
      .open(WindowCopyDocumentComponent, {
        context: {
          data,
          drives: this.drives,
          school: this.school,
          type: "move",
        },
        hasScroll: true,
      })
      .onClose.subscribe((name) => {});
  }

  openWindowForm(val, data) {
    if (data) {
      if (data["documentName"]) {
        this.dialogService
          .open(WindowFormEditDocumentComponent, {
            context: {
              title: data["documentName"],
              document: data["documentName"],
              approver1: data["approver1"],
              approver2: data["approver2"],
              approver3: data["approver3"],
              description: data["description"],
              drive: data["drive"],
              expiration: data["expiration"],
              file: data["file"],
              first: data["first"],
              formId: data["formId"],
              id: data["_id"],
              recipient: data["recipient"],
              type: data["type"],
              school: this.school,
              bucket: this.bucket,
              typeP: val,
            },
            hasScroll: true,
          })
          .onClose.subscribe((name) => {
            this.nameDrive.emit(this.nameD);
          });
      } else if (data["courseName"]) {
        this.dialogService
          .open(WindowFormEditProgramComponent, {
            context: {
              title: `Edit Course`,
              id: data["_id"],
              dataT: "course",
              school: this.school,
              typeP: val,
            },
            hasScroll: true,
          })
          .onClose.subscribe((name) => {
            this.tableR.emit(name);
          });
      } else if (data["programTitle"]) {
        this.dialogService
          .open(WindowFormEditProgramComponent, {
            context: {
              title: `Edit Program`,
              id: data["_id"],
              dataT: "program",
              school: this.school,
              typeP: val,
            },
            hasScroll: true,
          })
          .onClose.subscribe((name) => {
            this.tableR.emit(name);
          });
      } else if (data["campusName"]) {
        const prueba = this.dialogService
          .open(WindowFormEditProgramComponent, {
            context: {
              title: `Edit Program`,
              id: data["_id"],
              dataT: "campus",
              school: this.school,
              typeP: val,
            },
            hasScroll: true,
          })
          .onClose.subscribe((name) => {
            this.tableR.emit(name);
          });
      } else if (data["form"] && data["name"]) {
        this.dialogService
          .open(WindowFormEditProgramComponent, {
            context: {
              title: `Edit Form`,
              id: data["_id"],
              dataT: "form",
              school: this.school,
              typeP: val,
            },
            hasScroll: true,
          })
          .onClose.subscribe((name) => {
            this.tableR.emit(name);
          });
      } else if (data["firstName"]) {
        this.dialogService
          .open(WindowFormEditProgramComponent, {
            context: {
              title: `Edit User`,
              id: data["idAuth"],
              dataT: "user",
              school: this.school,
              typeP: val,
            },
            hasScroll: true,
          })
          .onClose.subscribe((name) => {
            this.tableR.emit(name);
          });
      } else if (data["name"]) {
        this.dialogService
          .open(WindowFormEditProgramComponent, {
            context: {
              title: `Edit Drive`,
              id: data["_id"],
              dataT: "drive",
              school: this.school,
              name: data["name"],
              typeP: "edit",
            },
            hasScroll: true,
          })
          .onClose.subscribe((name) => {
            this.tableR.emit(name);
          });
      }

      this.selection.clear();
    } else {
    }
  }

  print(data) {
    if (data["documentName"] && data["file"]) {
      this.apiD
        .getFile(
          encodeURIComponent(this.bucket),
          encodeURIComponent(data["file"])
        )
        .subscribe(
          (data1) => {
            const extension = data["file"].split(".").pop();
            if (
              extension == "png" ||
              extension == "jpg" ||
              extension == "jpeg"
            ) {
              printJS(decodeURI(data1["url"]), "image");
            } else {
              printJS({
                printable: decodeURI(data1["url"]),
                type: "pdf",
                showModal: true,
                modalMessage: "Retrieving document from external server...",
              });
            }
          },
          (err) => {
            this.showToast(
              "Message",
              "An error occurred, please try again",
              "danger",
              "alert-circle-outline"
            );
          }
        );
    } else {
      this.openWindowForm("print", data);
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.data1.filter = filterValue;
  }
  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
