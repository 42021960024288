import { Component, OnInit, ViewChild } from "@angular/core";
import { SchoolsService } from "../@core/api/base-api/schools.api";
import { AuthService } from "../@auth/auth.service";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import * as Cookie from "js-cookie";
import { WindowDeleteConfirmComponent } from "../shared/window-delete-confirm/window-delete-confirm.component";
import { NbDialogService, NbIconConfig, NbToastrService } from "@nebular/theme";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-forms",
  templateUrl: "./programs.component.html",
  styleUrls: ["./programs.component.scss"],
})
export class ProgramsComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  data: any = [];
  id: string;
  role: string;
  schoolId: string;
  accountNumber: number;
  admin: string;
  displayedColumns: string[] = ["programName", "programType", "options"];
  dataSource = new MatTableDataSource();
  loading: boolean;
  permiStudent: boolean;
  idUser: string;
  constructor(
    private api: SchoolsService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private location: Location,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.schoolId = this.route.snapshot.paramMap.get("schoolId");
    this.id = localStorage.getItem("urlId");
    if (this.id == "admin") {
      this.admin = "/admin";
    }
    this.auth.getUser$().subscribe((data) => {
      this.auth.permissions$.subscribe((res) => {
        this.permiStudent = res.includes("read:programs-student");
        this.role = data["https://cor4edu.com/roles"][0];
        this.idUser = data["sub"];
        this.refresh();
      });
    });
  }

  delete(program) {
    this.dialogService
      .open(WindowDeleteConfirmComponent, {
        context: {
          title: "program",
          name: program.programTitle,
        },
      })
      .onClose.subscribe((val) => {
        if (val) {
          if (this.role == "cor-admin" || this.role == "ag-admin-us") {
            this.api
              .deleteProgram(program._id, `/${this.schoolId}`, this.admin)
              .subscribe(
                (data) => {
                  this.showToast(
                    "Message",
                    "Program Deleted!",
                    "success",
                    "checkmark-outline"
                  );
                  this.refresh();
                },
                (err) => {
                  this.showToast(
                    "Message",
                    "An error occurred, please try again",
                    "danger",
                    "alert-circle-outline"
                  );
                }
              );
          } else {
            this.api.deleteProgram(program._id).subscribe(
              (data) => {
                this.showToast(
                  "Message",
                  "Program Deleted!",
                  "success",
                  "checkmark-outline"
                );
                this.refresh();
              },
              (err) => {
                this.showToast(
                  "Message",
                  "An error occurred, please try again",
                  "danger",
                  "alert-circle-outline"
                );
              }
            );
          }
        }
      });
  }

  refresh() {
    if (this.permiStudent) {
      this.api.listProgramsStudent(this.idUser).subscribe((data: any) => {
        data.forEach((element) => {
          if (element.courses.length > 0) {
            this.data.push(element);
          }
        });

        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      });
    } else {
      if (this.role == "cor-admin" || this.role == "ag-admin-us") {
        this.api.listPrograms(this.schoolId, this.admin).subscribe((data) => {
          this.data = data;
          this.dataSource = new MatTableDataSource(this.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.loading = false;
        });
      } else {
        this.api.listPrograms().subscribe((data) => {
          this.data = data;
          this.dataSource = new MatTableDataSource(this.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.loading = false;
        });
      }
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  goBack() {
    this.location.back();
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
