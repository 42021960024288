import { Component, OnInit } from "@angular/core";
import { DocumentsService } from "../@core/api/base-api/documents.api";
import { AuthService } from "../@auth/auth.service";
import { usersService } from "../@core/api/base-api/users.api";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-documents",
  templateUrl: "./documents-staff.component.html",
  styleUrls: ["./documents-staff.component.scss"],
})
export class DocumentsStaffComponent implements OnInit {
  public responseFile;
  public data: any = [];
  public users: any = [];
  public userDocument: any = [];
  public role: String;
  public id: String;
  public userId: any;
  public name: String;
  files: FileList;
  object: {};
  Arr = Array;
  flag: Boolean;

  constructor(
    private api: DocumentsService,
    private apiU: usersService,
    private auth: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.flag = false;
    this.userId = this.route.snapshot.paramMap.get("id");
    if (this.userId) {
      this.apiU.getUserById(this.userId).subscribe((data) => {
        this.name = data["name"];
        this.flag = true;
      });
    }

    this.auth.getUser$().subscribe((data) => {
      this.id = data["sub"];
      this.role = data["https://cor4edu.com/roles"][0];
      this.refresh();
    });
  }

  public Upload(event) {
    const idDocument = event.target.id;
    this.files = event.target.files;
    this.object = { id: this.id, file: this.files[0] };
    this.api.uploadFile(this.object).subscribe(
      (response) => {
        this.responseFile = response;
        if (this.responseFile.status == "ok") {
          const data = {
            document: idDocument,
            user: this.id,
            file: this.responseFile.file,
          };
          this.api.postDocument(data).subscribe((data) => {
            this.refresh();
          });
        } else {
          // console.log("error");
        }
      },
      (error) => {
        const data = {
          document: idDocument,
          user: this.id,
        };
        this.api.postDocument(data).subscribe((data) => {
          this.refresh();
        });
      }
    );
  }

  toggle(checked: boolean, id: String) {
    const object = { iagree: checked };
    this.api.updateStatusDocument(object, id).subscribe((data) => {});
  }

  approver(status, approver, idD) {
    const object = { [approver]: { user: this.id, status: status } };
    this.api.updateStatusDocument(object, idD).subscribe((data) => {
      this.refresh();
    });
  }

  refresh() {
    if (this.role == "staff") {
      this.api.getDocumentStaffbyId(this.id).subscribe((data) => {
        this.data = data;
      });
    } else {
      this.apiU.getUsers().subscribe((data) => {
        this.users = data;
      });
      this.api.getDocumentStaffbyId(this.userId).subscribe((data) => {
        this.data = data;
      });
    }
  }
}
