import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
} from "@angular/core";

@Component({
  selector: "app-viewer",
  templateUrl: "./viewer.component.html",
  styleUrls: ["./viewer.component.scss"],
})
export class ViewerComponent implements OnInit, OnChanges {
  @ViewChild("card", { static: true }) public card: ElementRef;

  id: string;
  file: string;
  name: string;
  loading: boolean;

  role: string;
  school: string;
  schoolId: string;
  @Input() fileV: string;
  @Input() extension: string;
  @Input() width: string;
  @Input() height: string;
  @Output() statusDoc: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    this.loading = true;
    this.file = this.fileV;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.fileV && changes.fileV) {
      if (changes.fileV.currentValue != changes.fileV.previousValue) {
        this.file = changes.fileV.currentValue;
        this.extension = changes.extension.currentValue;
      }
    }
  }

  loadSuccess(e) {
    this.loading = false;
    let element: HTMLElement = document.getElementById("card") as HTMLElement;
    element.click();
  }
}
