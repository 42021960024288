import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { PeriodicElement } from "../../schools/edit-school/edit-school.component";
import { SelectionModel } from "@angular/cdk/collections";
import { NbDialogService } from "@nebular/theme";
import { ShowcaseDialogComponent } from "../showcase-dialog/showcase-dialog.component";
import printJS from "print-js";

import { SchoolsService } from "../../@core/api/base-api/schools.api";
import { WindowFormEditProgramComponent } from "./window-form-edit-program/window-form-edit-program";

@Component({
  selector: "app-table-programs",
  templateUrl: "./table-programs.component.html",
  styleUrls: ["./table-programs.component.scss"],
})
export class TableProgramsComponent implements OnInit, OnChanges {
  @Output() tableR: EventEmitter<any> = new EventEmitter<any>();
  @Output() status: EventEmitter<any> = new EventEmitter<any>();
  displayedColumns: string[] = [
    "select",
    "programName",
    "degreeEarned",
    "totalCredits",
    "totalClockhours",
    "quarterSemester",
    "approvedOn",
  ];
  @Input() dataO1;
  @Input() data1;
  @Input() school: string;
  @Input() bucket: string;
  @Input() state: boolean;
  @Input() driveS: string;
  @Input() title: string;
  nameD: any;
  loading: boolean;
  selection = new SelectionModel<Element>(true, []);
  constructor(
    private api: SchoolsService,
    private dialogService: NbDialogService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.data1 = new MatTableDataSource<PeriodicElement>(this.dataO1);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loading = false;
    if (this.dataO1 && changes.dataO1) {
      if (changes.dataO1.currentValue != changes.dataO1.previousValue) {
        this.data1 = new MatTableDataSource<PeriodicElement>(
          changes.dataO1.currentValue
        );
      }
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data1.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.data1.data.forEach((row) => this.selection.select(row));
  }

  open() {
    this.dialogService.open(ShowcaseDialogComponent, {
      context: {
        title: "Error",
        message: "An error occurred, I tried again",
      },
    });
  }

  public drive(name1: any): void {
    const value = name1;
    this.nameD = value;
    this.tableR.emit(value);
  }
  public refresh(): void {
    this.status.emit(true);
    this.selection.clear();
  }

  removeSelectedRows() {
    this.selection.selected.forEach((item) => {
      this.api
        .deleteProgram(item["_id"], `/${this.school}`)
        .subscribe((data) => {});
      let index: number = this.dataO1.findIndex((d) => d === item);
      this.dataO1.splice(index, 1);
      this.data1 = new MatTableDataSource<Element>(this.dataO1);
    });
    this.selection = new SelectionModel<Element>(true, []);
  }

  openWindowForm(val) {
    if (this.selection.selected[0] != undefined) {
      this.dialogService
        .open(WindowFormEditProgramComponent, {
          context: {
            title: `Edit Program`,
            id: this.selection.selected[0]["_id"],
            school: this.school,
            typeP: val,
          },
          hasScroll: false,
        })
        .onClose.subscribe((name) => {
          this.tableR.emit(name);
        });
    }

    this.selection.clear();
  }

  print() {
    if (
      this.selection.selected[0]["document"] &&
      this.selection.selected[0]["file"]
    ) {
      const extension = this.selection.selected[0]["file"].split(".").pop();
      if (extension == "png" || extension == "jpg" || extension == "jpeg") {
        printJS(decodeURI("url"), "image");
      } else {
        printJS(decodeURI("url"));
      }
    }
  }
}
