import {
  Component,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
} from "@angular/core";
import { DocumentsService } from "../@core/api/base-api/documents.api";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../@auth/auth.service";
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { NbDialogService, NbIconConfig, NbToastrService } from "@nebular/theme";
import * as html2pdf from "html2pdf.js";
import printJS from "print-js";
import { WindowCopyDocumentComponent } from "../shared/window-copy-document/window-copy-document.component";
import { SchoolsService } from "../@core/api/base-api/schools.api";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit {
  @Input() formId: String;
  @Input() formValues: object;
  @Input() submit: boolean;
  @Input() dataDocument: any;
  @Input() sent: boolean;
  @Output() dataO: EventEmitter<any> = new EventEmitter<any>();
  fields: any[];
  @Input() call: boolean;
  permiCopy: boolean;
  school: string;
  object: {};
  DataForm: any;
  dataSave = { entries: [] };
  state = false;
  data: {};
  id: string;
  flag: boolean;
  idD: string;
  formData: FormGroup;
  form: boolean;
  public role: string;
  idRD: string;
  checked: boolean;
  nameForm: string;
  drives: any = [];
  loading: boolean;
  constructor(
    private api: DocumentsService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private apiS: SchoolsService
  ) {
    this.getUser();
  }

  ngOnInit() {
    this.school = this.route.snapshot.paramMap.get("id");
    this.auth.permissions$.subscribe((data) => {
      this.permiCopy = data.includes("copy-documents-requests");
    });
    this.apiS.getTenant(this.school).subscribe((data) => {
      this.apiS.getSchool(data["idAuth"]).subscribe((data) => {
        this.drives = data["drives"];
      });
    });

    if (!this.call) {
      this.formId = this.route.snapshot.paramMap.get("id");
    }
    if (!this.formId) {
      this.router.navigateByUrl("/");
      return;
    }

    this.loadForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formId && changes.formId) {
      if (changes.formId.currentValue != changes.formId.previousValue) {
        this.formId = changes.formId.currentValue;
        this.submit = changes.submit.currentValue;
      }
    }
  }

  toPDF() {
    const options = {
      filename: "test.pdf",
      image: { type: "jpeg" },
      html2canvas: {},
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    const content: Element = document.getElementById("print");

    html2pdf().from(content).set(options).save();
  }

  async print() {
    const options = {
      filename: "test.pdf",
      image: { type: "jpeg" },
      html2canvas: {},
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    const content: Element = document.getElementById("print");

    let worker = await html2pdf()
      .set(options)
      .from(content)
      .toPdf()
      .output("blob")
      .then((data: Blob) => {
        return data;
      });

    const pdfFile = new Blob([worker], {
      type: "application/pdf",
    });
    const pdfUrl = URL.createObjectURL(pdfFile);
    printJS(pdfUrl);
  }

  toformGroup() {
    let group: any = {};
    this.form = false;
    this.fields.forEach((element) => {
      switch (element.type) {
        case "checkbox":
        case "radio":
          group[element.name] = this.createCheckRadio(element);
          break;
        default:
          group[element.name] = element.required
            ? new FormControl("", [Validators.required])
            : new FormControl("");
          break;
      }
    });

    this.formData = new FormGroup(group);
    this.form = true;
  }

  cargarDataInicial() {
    let group: any = {};
    for (const dt in this.DataForm) {
      group[dt] = this.DataForm[dt];
    }

    this.formData.reset(group);
  }

  createCheckRadio(dat: any) {
    const arr = dat.values.map((data: any) => {
      return new FormControl(false);
    });
    return new FormArray(arr);
  }

  loadForm() {
    this.api.getDocumenbyId(this.formId).subscribe(
      (res) => {
        if (this.role == "Pre-Student") {
          this.getForm(res);
        } else {
          this.idRD = res["_id"];
          this.id = res["user"];
          this.api.getDocumenbyId(this.idRD).subscribe((resp) => {
            this.getForm(resp);
            this.formId = resp["_id"];
          });
        }
      },
      (err) => console.log(err)
    );
  }

  getForm(data: any) {
    this.api.getForm(data["formId"]).subscribe(
      (res) => {
        this.nameForm = res["name"];
        this.fields = res["form"];
        //this.idD = res["_id"];

        this.toformGroup();
        this.getFormByStudent();
      },
      (err) => console.log(err)
    );
  }

  getUser() {
    this.auth.getUser$().subscribe(
      (res) => {
        this.role = res["https://cor4edu.com/roles"][0];
        if (this.role == "Pre-Student") {
          this.id = res["sub"];
        }
      },
      (err) => console.log(err)
    );
  }

  getFormByStudent() {
    if (!this.call) {
      this.api.getFormByStudent(this.formId, this.id).subscribe(
        (res: any) => {
          if (res != null) {
            this.state = true;
            this.DataForm = res["form"];
            this.idD = res["_id"];
            //this.mapDataValueForm();
            this.cargarDataInicial();
          }
        },
        (err) => console.log(err)
      );
    } else {
      this.DataForm = this.formValues;
      this.cargarDataInicial();
    }
  }

  mapDataValueForm() {
    if (this.DataForm != null) {
      for (const elCon in this.DataForm) {
        this.fields.forEach((ele: any) => {
          if (elCon == ele.name) {
            ele["value"] = this.DataForm[elCon];
          }
        });
      }
    }
  }

  toggleValue(e, v) {
    const elementSaveData = v;
    const objIndex = this.dataSave.entries.findIndex(
      (obj) => obj.input_name === elementSaveData.input_name
    );
    // verificar si el check ha sido seleccionado
    e.toElement.checked
      ? this.dataSave.entries.push(elementSaveData)
      : this.dataSave.entries.splice(objIndex, 1);
    // por alguna razon no esta funcionando en onChange lo veo despues.
  }

  onChange(event: any, element: any, index: any) {
    const elementSaveData = {
      input_name: element.name,
      value: element.value,
    };
    const objIndex = this.dataSave.entries.findIndex(
      (obj) => obj.input_name === elementSaveData.input_name
    );

    objIndex === -1
      ? this.dataSave.entries.push(elementSaveData)
      : this.validateInputValue(objIndex, element);

    if (
      this.dataSave.entries[objIndex].value == null ||
      this.dataSave.entries[objIndex].value == 0
    ) {
      // delete input.value = null
      this.dataSave.entries.splice(objIndex, 1);
    } else if (this.dataSave.entries[objIndex].value == undefined) {
      return null;
    }
  }

  validateInputValue(objIndex, element) {
    if (this.dataSave.entries[objIndex].value !== undefined) {
      this.dataSave.entries[objIndex].value = element.value;
    }
  }

  toggle(checked: boolean) {
    this.checked = checked;
  }

  onSubmit() {
    this.loading = true;
    if (this.formData.invalid) {
      if (this.formData.invalid) {
        Object.values(this.formData.controls).forEach((control) => {
          control.markAsTouched();
        });
        return;
      }
    }
    this.data = {
      document: this.formId,
      user: this.id,
      form: this.formData.value,
    };

    if (!this.call) {
      if (this.state) {
        this.api.updateFormbyUser(this.idD, this.data).subscribe((data) => {
          this.router.navigate(["documents-student"]);
        });
      } else {
        this.api.postDocument(this.data).subscribe((data) => {
          this.router.navigate(["documents-student"]);
        });
      }
    } else {
      this.dataO.emit(this.formData.value);
    }
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }

  async copy() {
    const options = {
      filename: "test.pdf",
      image: { type: "jpeg" },
      html2canvas: {},
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    const content: Element = document.getElementById("print");

    let worker = await html2pdf()
      .set(options)
      .from(content)
      .toPdf()
      .output("blob")
      .then((data: Blob) => {
        return data;
      });
    const file = new File(
      [worker],
      `${this.dataDocument.documentId.documentName}.pdf`
    );
    this.dialogService
      .open(WindowCopyDocumentComponent, {
        context: {
          data: {
            documentName: this.dataDocument.documentId.documentName,
            description: this.dataDocument.documentId.description,
            type: "Template",
            expiration: false,
            recipient: "Sign",
            file,
          },
          drives: this.drives,
          school: this.school,
          type: "upload",
        },
        hasScroll: true,
      })
      .onClose.subscribe((name) => {});
  }
}
