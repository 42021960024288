import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { usersService } from "../../@core/api/base-api/users.api";
import { AuthService } from "../../@auth/auth.service";
import * as Cookie from "js-cookie";
import { SchoolsService } from "../../@core/api/base-api/schools.api";
import { NbToastrService, NbIconConfig } from "@nebular/theme";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit, OnChanges {
  @Output() status: EventEmitter<any> = new EventEmitter<any>();
  @Input() hidden: boolean;
  @Input() schoolE: string;
  @Input() school: string;
  @Input() call: boolean;
  @Input() tab: string;
  roles: any = [];
  titlesName: any = [];
  selectedOption: string;
  selectedOptionSA: string;
  printedOption: string;
  addForm: FormGroup;
  schoolId: string;
  role: string;
  id: string;
  create: boolean;
  class: string;
  admin: string;
  selectedStatus: any = [];
  rolesSelected: any = [];
  loading: boolean;
  public dropdownsYes = [
    { key: "", value: "System Status" },
    { key: "Active", value: "Active" },
    { key: "Pre-Enrolled", value: "Pre-Enrolled" },
    { key: "Graduate", value: "Graduate" },
    { key: "Completer", value: "Completer" },
  ];
  public dropdownsNo = [
    { key: "", value: "System Status" },
    { key: "Withdrawn", value: "Withdrawn" },
    { key: "ADMIN Withdrawn", value: "ADMIN Withdrawn" },
    { key: "Financial Issue", value: "Financial Issue" },
    { key: "Leave of Absense", value: "Leave of Absense" },
    { key: "ADMIN Faculty-Leave", value: "ADMIN Faculty-Leave" },
    { key: "Archive", value: "Archive" },
    { key: "Delete", value: "Delete" },
  ];
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private api: usersService,
    private userService: usersService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private apiS: SchoolsService,
    private toastrService: NbToastrService
  ) {
    this.createForm();
    this.addForm.controls.systemAccess.setValue(true);
    this.selectedStatus = this.dropdownsYes;
    this.addForm.controls.systemStatus.setValue("");
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.schoolE && changes.schoolE) {
      if (changes.schoolE.currentValue != changes.schoolE.previousValue) {
        this.auth.getUser$().subscribe(
          (data) => {
            this.schoolId = changes.schoolE.currentValue;
            this.id = data["sub"];
            this.role = data["https://cor4edu.com/roles"][0];
            if (this.schoolId != "admin") {
              this.getData();
            }
          },
          (err) => {
            this.showToast(
              "Message",
              "An error occurred, please try again",
              "danger",
              "alert-circle-outline"
            );
          }
        );
      }
    }
  }

  ngOnInit() {
    this.loading = false;
    this.schoolId = this.route.snapshot.paramMap.get("schoolId");
    this.id = localStorage.getItem("urlId");

    if (this.id == "admin") {
      this.admin = "/admin";
    }
    if (!this.call) {
      this.getData();
    }
  }

  getData() {
    const value = this.schoolId ? this.schoolId : this.id;
    this.apiS.getTenant(value).subscribe(
      (data) => {
        this.addForm.controls["schoolId"].setValue(data["_id"]);
      },
      (err) => {
        this.showToast(
          "Message",
          "An error occurred, please try again",
          "danger",
          "alert-circle-outline"
        );
      }
    );

    this.api.getRoles().subscribe(
      (data) => {
        this.roles = data;
        this.auth.getUser$().subscribe((data) => {
          this.role = data["https://cor4edu.com/roles"][0];
          if (this.role == "cor-admin" || this.role == "ag-admin-us") {
            this.api
              .getUserTitleNames(`/${this.schoolId}`, this.admin)
              .subscribe(
                (data) => {
                  this.titlesName = data;
                },
                (err) => {
                  this.showToast(
                    "Message",
                    "An error occurred, please try again",
                    "danger",
                    "alert-circle-outline"
                  );
                }
              );
          } else {
            this.api.getUserTitleNames().subscribe(
              (data) => {
                this.titlesName = data;
              },
              (err) => {
                this.showToast(
                  "Message",
                  "An error occurred, please try again",
                  "danger",
                  "alert-circle-outline"
                );
              }
            );
          }
        });
      },
      (err) => {
        this.showToast(
          "Message",
          "An error occurred, please try again",
          "danger",
          "alert-circle-outline"
        );
      }
    );
  }

  get firstNameInvalid() {
    return (
      this.addForm.get("firstName").invalid &&
      this.addForm.get("firstName").touched
    );
  }

  /* get secondNameInvalid() {
    return (
      this.addForm.get("secondName").invalid &&
      this.addForm.get("secondName").touched
    );
  }*/
  get lastNameInvalid() {
    return (
      this.addForm.get("lastName").invalid &&
      this.addForm.get("lastName").touched
    );
  }
  /*get secondLastNameInvalid() {
    return (
      this.addForm.get("secondLastName").invalid &&
      this.addForm.get("secondLastName").touched
    );
  }*/
  get emailInvalid() {
    return (
      this.addForm.get("email").invalid && this.addForm.get("email").touched
    );
  }

  /*get secondaryEmailInvalid() {
    return (
      this.addForm.get("secondaryEmail").invalid &&
      this.addForm.get("secondaryEmail").touched
    );
  }

  get stateInvalid() {
    return (
      this.addForm.get("address.state").invalid &&
      this.addForm.get("address.state").touched
    );
  }

  get cityInvalid() {
    return (
      this.addForm.get("address.city").invalid &&
      this.addForm.get("address.city").touched
    );
  }

  get zipCodeInvalid() {
    return (
      this.addForm.get("address.zipCode").invalid &&
      this.addForm.get("address.zipCode").touched
    );
  }

  get homePhoneInvalid() {
    return (
      this.addForm.get("homePhone").invalid &&
      this.addForm.get("homePhone").touched
    );
  }

  get cellPhoneInvalid() {
    return (
      this.addForm.get("cellPhone").invalid &&
      this.addForm.get("cellPhone").touched
    );
  }

  get workPhoneInvalid() {
    return (
      this.addForm.get("workPhone").invalid &&
      this.addForm.get("workPhone").touched
    );
  }

  get dateOfBirthInvalid() {
    return (
      this.addForm.get("dateOfBirth").invalid &&
      this.addForm.get("dateOfBirth").touched
    );
  }*/

  // get expectedGraduationDateInvalid(){
  //   return this.addForm.get('expectedGraduationDate').invalid && this.addForm.get('expectedGraduationDate').touched
  // }

  // get programStartDateInvalid(){
  //   return this.addForm.get('programStartDate').invalid && this.addForm.get('programStartDate').touched
  // }

  // get hiredDateInvalid(){
  //   return this.addForm.get('hiredDate').invalid && this.addForm.get('hiredDate').touched
  // }

  /*get emergencyContactName() {
    return (
      this.addForm.get("emergencyContactName").invalid &&
      this.addForm.get("emergencyContactName").touched
    );
  }

  get emergencyContactPhoneInvalid() {
    return (
      this.addForm.get("emergencyContactPhone").invalid &&
      this.addForm.get("emergencyContactPhone").touched
    );
  }*/
  get rolesInvalid() {
    return (
      this.addForm.get("roles").invalid && this.addForm.get("roles").touched
    );
  }

  get userTitleNameInvalid() {
    return (
      this.addForm.get("userTitleName").invalid &&
      this.addForm.get("userTitleName").touched
    );
  }

  get systemAccessInvalid() {
    return (
      this.addForm.get("systemAccess").invalid &&
      this.addForm.get("systemAccess").touched
    );
  }

  get systemStatusInvalid() {
    return (
      this.addForm.get("systemStatus").invalid &&
      this.addForm.get("systemStatus").touched
    );
  }

  /*get usVeteranInvalid() {
    return (
      this.addForm.get("usVeteran").invalid &&
      this.addForm.get("usVeteran").touched
    );
  }

  get countryOfCitizenshipInvalid() {
    return (
      this.addForm.get("countryOfCitizenship").invalid &&
      this.addForm.get("countryOfCitizenship").touched
    );
  }*/

  changedTitleName(id) {
    if (this.role == "cor-admin" || this.role == "ag-admin-us") {
      this.userService
        .getUserTitleName(id, `/${this.schoolId}`, this.admin)
        .subscribe(
          (res) => {
            this.addForm.get("roles").setValue(res["roles"]);
          },
          (err) => {
            this.showToast(
              "Message",
              "An error occurred, please try again",
              "danger",
              "alert-circle-outline"
            );
          }
        );
    } else {
      this.userService.getUserTitleName(id).subscribe(
        (res) => {
          this.addForm.get("roles").setValue(res["roles"]);
        },
        (err) => {
          this.showToast(
            "Message",
            "An error occurred, please try again",
            "danger",
            "alert-circle-outline"
          );
        }
      );
    }
  }

  createForm() {
    this.addForm = this.fb.group({
      schoolId: [""],
      firstName: ["", [Validators.required, Validators.minLength(3)]],
      secondName: ["", []],
      lastName: ["", [Validators.required, Validators.minLength(3)]],
      secondLastName: ["", []],
      roles: [[], [Validators.required]],
      userTitleName: ["", [Validators.required]],
      systemAccess: [true, [Validators.required]],
      systemStatus: ["", [Validators.required]],
      dateOfBirth: ["", []],
      homePhone: ["", []],
      cellPhone: ["", []],
      workPhone: ["", []],
      email: ["", [Validators.required, Validators.email]],
      secondaryEmail: ["", [Validators.email]],
      emergencyContactName: ["", []],
      emergencyContactPhone: ["", []],
      countryOfCitizenship: ["", []],
      usVeteran: [false, []],
      programSelection: ["", []],
      expectedGraduationDate: ["", []],
      programStartDate: ["", []],
      cohortName: ["", []],
      hiredDate: ["", []],
      address: this.fb.group({
        state: ["", []],
        city: ["", []],
        zipCode: ["", []],
      }),
    });
  }

  save() {
    this.loading = true;
    if (this.addForm.invalid) {
      this.loading = false;
      return Object.values(this.addForm.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }

    if (this.role == "cor-admin" || this.role == "ag-admin-us") {
      this.userService
        .createUser(this.addForm.value, `/${this.schoolId}`, this.admin)
        .subscribe(
          (data) => {
            const userID = data["data"]["_id"];
            const schoolID = data["data"]["schoolId"];
            const object = {
              students: [userID],
            };
            this.apiS.updateRelationship(schoolID, object).subscribe(
              (res) => {
                if (!this.call) {
                  this.loading = false;
                  this.showToast(
                    "Message",
                    "User Created!",
                    "success",
                    "checkmark-outline"
                  );
                  this.router.navigate(["/", this.id, "users", this.schoolId]);
                } else {
                  this.loading = false;
                  this.afterCall(data["data"]["_id"]);
                }
              },
              (err) => {
                this.loading = false;
                this.showToast(
                  "Message",
                  "An error occurred, please try again",
                  "danger",
                  "alert-circle-outline"
                );
              }
            );
          },
          (err) => {
            this.loading = false;
            this.showToast(
              "Message",
              "An error occurred, please try again",
              "danger",
              "alert-circle-outline"
            );
          }
        );
    } else {
      this.userService.createUser(this.addForm.value).subscribe(
        (data) => {
          this.apiS
            .updateRelationship(data["data"]["schoolId"], {
              students: [data["data"]["_id"]],
            })
            .subscribe(
              (res) => {
                if (!this.call) {
                  this.loading = false;
                  this.showToast(
                    "Message",
                    "User Created!",
                    "success",
                    "checkmark-outline"
                  );
                  this.router.navigate(["/", this.id, "users"]);
                } else {
                  this.loading = false;
                  this.afterCall(data["data"]["_id"]);
                }
              },
              (err) => {
                this.loading = false;
                this.showToast(
                  "Message",
                  "An error occurred, please try again",
                  "danger",
                  "alert-circle-outline"
                );
              }
            );
        },
        (err) => {
          this.showToast(
            "Message",
            "An error occurred, please try again",
            "danger",
            "alert-circle-outline"
          );
        }
      );
    }
  }

  afterCall(id) {
    this.create = true;
    this.showToast("Message", "User Created!", "success", "checkmark-outline");
    this.class = "fade-in";
    this.status.emit([id, "students"]);
    this.addForm.reset({ roles: [[]] });
    setTimeout(() => {
      this.class = "fadeOut";
      this.create = false;
    }, 5000);
  }

  onChange($event) {
    if ($event) {
      this.selectedStatus = this.dropdownsYes;
    } else {
      this.selectedStatus = this.dropdownsNo;
    }
    this.addForm.controls.systemStatus.setValue("");
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
