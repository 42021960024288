import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment } from "../../../environments/environment";
import { usersService } from "../../@core/api/base-api/users.api";
import { Location } from "@angular/common";
import { NbToastrService, NbIconConfig, NbDialogService } from "@nebular/theme";
import { WindowDeleteConfirmComponent } from "../../shared/window-delete-confirm/window-delete-confirm.component";
@Component({
  selector: "app-edit-rol",
  templateUrl: "./edit-rol.component.html",
  styleUrls: ["./edit-rol.component.scss"],
})
export class EditRolComponent implements OnInit {
  name: string;
  description: string;
  data: any = [];
  editForm: FormGroup;
  roleId = "";
  permissionsAdd = [];
  permissionsDel = [];
  constructor(
    private router: Router,
    private api: usersService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private location: Location,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService
  ) {}

  ngOnInit() {
    this.editForm = this.formBuilder.group({
      email: ["", Validators.required],
      name: ["", Validators.required],
    });

    this.roleId = this.route.snapshot.paramMap.get("id");
    if (!this.roleId) {
      this.router.navigate(["users"]);
      return;
    }
    this.editForm = this.formBuilder.group({
      permissions: ["", Validators.required],
    });
    this.api.getRol(this.roleId).subscribe((data) => {
      this.name = data["data"].name;
      this.description = data["data"].description;
    });

    this.api.getPermissionsByRol(this.roleId).subscribe((base: any) => {
      const disp = base["data"].map((a) => a.permission_name);
      this.api.getPermissions().subscribe((data) => {
        const array = data["data"];
        const findIdentifier = array.find(
          (key) => key.identifier === environment.auth0.audience
        );
        const scopes = findIdentifier.scopes;
        scopes.forEach((item) => {
          item.checked = disp.includes(item.value);
          this.data.push(item);
        });
      });
    });
  }

  selectAllCF(val) {
    let check: Boolean = false;
    this.data.forEach((item) => {
      if (item.description.includes("[CF]")) {
        if (item.checked) {
          check = false;
        } else {
          check = true;
        }
        item.checked = val;
        const position = this.data.findIndex(
          (x) => x.description === item["description"]
        );
        this.data[position].checked = val;
      }
    });
  }

  selectAllFC(val) {
    let check: Boolean = false;
    this.data.forEach((item) => {
      if (item.description.includes("[FC]")) {
        if (item.checked) {
          check = false;
        } else {
          check = true;
        }
        item.checked = val;
        const position = this.data.findIndex(
          (x) => x.description === item["description"]
        );
        this.data[position].checked = val;
      }
    });
  }

  selectAllFS(val) {
    let check: Boolean = false;
    this.data.forEach((item) => {
      if (item.description.includes("[FS]")) {
        if (item.checked) {
          check = false;
        } else {
          check = true;
        }
        item.checked = val;
        const position = this.data.findIndex(
          (x) => x.description === item["description"]
        );
        this.data[position].checked = val;
      }
    });
  }

  selectAllSF(val) {
    let check: Boolean = false;
    this.data.forEach((item) => {
      if (item.description.includes("[SF]")) {
        if (item.checked) {
          check = false;
        } else {
          check = true;
        }
        item.checked = val;
        const position = this.data.findIndex(
          (x) => x.description === item["description"]
        );
        this.data[position].checked = val;
      }
    });
  }

  selectAllFE(val) {
    let check: Boolean = false;
    this.data.forEach((item) => {
      if (item.description.includes("[FE]")) {
        if (item.checked) {
          check = false;
        } else {
          check = true;
        }
        item.checked = val;
        const position = this.data.findIndex(
          (x) => x.description === item["description"]
        );
        this.data[position].checked = val;
      }
    });
  }

  // onCheckChange(event) {
  //   const formArray: FormArray = this.editForm.get('permissions') as FormArray;

  //   /* Selected */
  //   if (event.target.checked) {
  //     // Add a new control in the arrayForm
  //     formArray.push(new FormControl(event.target.value));
  //   } else {
  //     /* unselected */
  //     // find the unselected element
  //     let i = 0;

  //     formArray.controls.forEach((ctrl: FormControl) => {
  //       if (ctrl.value === event.target.value) {
  //         // Remove the unselected element from the arrayForm
  //         formArray.removeAt(i);
  //         return;
  //       }

  //       i++;
  //     });
  //   }
  // }
  goBack() {
    this.location.back();
  }

  onCheck(perm) {
    let check: Boolean = false;
    if (this.data[perm].checked) {
      check = false;
    } else {
      check = true;
    }
    this.data[perm].checked = check;

    // this.api
    //   .addPermissions(this.roleId, { permissions: permissionsAdd })
    //   .subscribe(
    //     (res) => {
    //       this.showToast(
    //         "Message",
    //         "Updated Permits!",
    //         "success",
    //         "checkmark-outline"
    //       );
    //       //console.log(res);
    //     },
    //     (error) => {
    //       if (error["error"].data.lenght !== undefined) {
    //         this.showToast(
    //           "Message",
    //           error,
    //           "warning",
    //           "alert-triangle-outline"
    //         );
    //         // console.log(error);
    //       }
    //     }
    //   );

    // this.api
    //   .deletePermissions(this.roleId, { permissions: permissionsDel })
    //   .subscribe(
    //     (res) => {
    //       // this.showToast(
    //       //   "Message",
    //       //   "Updated Permits!",
    //       //   "success",
    //       //   "checkmark-outline"
    //       // );
    //       // console.log(res);
    //     },
    //     (error) => {
    //       if (error["error"].data.lenght !== undefined) {
    //         this.showToast(
    //           "Message",
    //           error,
    //           "warning",
    //           "alert-triangle-outline"
    //         );
    //         // console.log(error);
    //       }
    //     }
    //   );
  }

  save() {
    this.dialogService
      .open(WindowDeleteConfirmComponent, {
        context: {
          title: "Rol",
          name: this.name,
          edit: true,
        },
      })
      .onClose.subscribe((val) => {
        if (val) {
          this.data.forEach((item: any) => {
            if (item.checked) {
              this.permissionsAdd.push({
                resource_server_identifier: environment.auth0.audience,
                permission_name: item.value,
              });
            } else {
              this.permissionsDel.push({
                resource_server_identifier: environment.auth0.audience,
                permission_name: item.value,
              });
            }
          });
          this.api
            .addPermissions(this.roleId, { permissions: this.permissionsAdd })
            .subscribe(
              (res) => {
                this.showToast(
                  "Message",
                  "Updated Permits!",
                  "success",
                  "checkmark-outline"
                );
                console.log(res);
              },
              (error) => {
                if (error["error"].data.lenght !== undefined) {
                  this.showToast(
                    "Message",
                    error,
                    "warning",
                    "alert-triangle-outline"
                  );
                  // console.log(error);
                }
              }
            );
          this.api
            .deletePermissions(this.roleId, {
              permissions: this.permissionsDel,
            })
            .subscribe(
              (res) => {
                // this.showToast(
                //   "Message",
                //   "Updated Permits!",
                //   "success",
                //   "checkmark-outline"
                // );
              },
              (error) => {
                if (error["error"].data.lenght !== undefined) {
                  this.showToast(
                    "Message",
                    error,
                    "warning",
                    "alert-triangle-outline"
                  );
                  // console.log(error);
                }
              }
            );
        }
      });
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}

/*

read:campus-student 
read:programs-student
read:courses-student

*/
