import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { User } from "../../model/user.model";
import { environment } from "../../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class usersService {
  baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = `${environment.apiUser}`;
  }

  public getUsers() {
    return this.httpClient.get(`${this.baseUrl}/users/`);
  }

  public getUsersByAccount(schoolId = "", admin = "") {
    return this.httpClient.get(`${this.baseUrl}/users${admin}/${schoolId}/`);
  }

  getUserById(id: String, schoolId = "", admin = "") {
    return this.httpClient.get(
      `${this.baseUrl}/users${admin}${schoolId}/${id}/`
    );
  }

  getProfileById(id: string) {
    return this.httpClient.get(`${this.baseUrl}/users/${id}/profile`);
  }

  updateUser(user, id, schoolId = "", admin = "") {
    return this.httpClient.put(
      `${this.baseUrl}/users${admin}${schoolId}/${id}`,
      user
    );
  }

  updateProfile(user, id) {
    return this.httpClient.put(`${this.baseUrl}/users/${id}/profile`, user);
  }

  createUser(user: User, schoolId = "", admin = "") {
    return this.httpClient.post(
      `${this.baseUrl}/users${admin}${schoolId}/`,
      user
    );
  }

  getRoleUser(id: string) {
    return this.httpClient.get(`${this.baseUrl}/users/${id}/role`);
  }

  getRoles(admin = "") {
    return this.httpClient.get(`${this.baseUrl}/roles${admin}`).pipe(
      map((res) => {
        return res["data"];
      })
    );
  }
  createRol(rol) {
    return this.httpClient.post(`${this.baseUrl}/roles`, rol);
  }
  getRol(id: string) {
    return this.httpClient.get(`${this.baseUrl}/roles/id/${id}/`);
  }

  getPermissions() {
    return this.httpClient.get(`${this.baseUrl}/roles/permissions`);
  }
  getPermissionsByRol(id) {
    return this.httpClient.get(`${this.baseUrl}/roles/${id}/permissions`);
  }

  addPermissions(id, data) {
    return this.httpClient.post(
      `${this.baseUrl}/roles/${id}/addPermissions`,
      data
    );
  }
  deletePermissions(id, data) {
    return this.httpClient.request(
      "delete",
      `${this.baseUrl}/roles/${id}/deletePermissions`,
      { body: data }
    );
  }
  addRoleUser(id, data) {
    return this.httpClient.post(`${this.baseUrl}/users/${id}/roles`, data);
  }
  deleteRoles(id) {
    return this.httpClient.delete(`${this.baseUrl}/roles/${id}`);
  }

  signUp(user: User, schoolId) {
    return this.httpClient.post(`${this.baseUrl}/sign-up/${schoolId}`, user);
  }

  resetPassword(user: User, schoolId) {
    return this.httpClient.post(
      `${this.baseUrl}/reset-password/${schoolId}`,
      user
    );
  }

  getUserTitleNames(schoolId = "", admin = "") {
    return this.httpClient
      .get(`${this.baseUrl}/UserTitleNames${admin}${schoolId}/`)
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  getUserTitleName(id, schoolId = "", admin = "") {
    return this.httpClient
      .get(`${this.baseUrl}/UserTitleNames${admin}${schoolId}/${id}/`)
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  updatetUserTitleNames(id, roles) {
    return this.httpClient
      .put(`${this.baseUrl}/UserTitleNames/${id}`, roles)
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  createUserTitleNames(data) {
    return this.httpClient.post(`${this.baseUrl}/UserTitleNames/`, data).pipe(
      map((res) => {
        return res["data"];
      })
    );
  }

  deleteUserTitleNames(id) {
    return this.httpClient.delete(`${this.baseUrl}/UserTitleNames/${id}`);
  }

  public updateRelationshipUsers(id, data, admin = "", schoolId = "") {
    return this.httpClient.put(
      `${this.baseUrl}/users${admin}${schoolId}/relationship/${id}`,
      data
    );
  }
  public deleteRelationshiUsers(id, data, admin = "", schoolId = "") {
    return this.httpClient.request(
      "delete",
      `${this.baseUrl}/users${admin}${schoolId}/relationship/${id}`,
      { body: data }
    );
  }
}
