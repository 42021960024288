import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class DocumentsService {
  baseUrl: string;
  baseUrlDocuments: string;
  constructor(private httpClient: HttpClient) {
    this.baseUrl = `${environment.apiDocuments}`;
    this.baseUrlDocuments = `${environment.apiDocumentsStorage}`;
  }
  getListDocuments() {
    return this.httpClient.get(`${this.baseUrl}/documents/all`);
  }
  getListDocumentsByDrive(id, schoolId = "", admin = "") {
    return this.httpClient.get(
      `${this.baseUrl}/drive${admin}/name/${id}${schoolId}`
    );
  }
  updateNameDrive(id, data, schoolId = "", admin = "") {
    return this.httpClient.put(
      `${this.baseUrl}/drive${admin}${schoolId}/${id}`,
      data
    );
  }
  getDocumenbyId(id, schoolId = "", admin = "") {
    return this.httpClient.get(
      `${this.baseUrl}/documents${admin}/byId/${id}${schoolId}`
    );
  }
  getDocumentStudentsbyId(id) {
    return this.httpClient.get(`${this.baseUrl}/documents/student/${id}`);
  }
  getDocumentStaffbyId(id) {
    return this.httpClient.get(`${this.baseUrl}/documents/staff/${id}`);
  }
  getDocumentStudentbyId(id) {
    return this.httpClient.get(
      `${this.baseUrl}/documents/student/document/${id}`
    );
  }
  updateStatusDocument(data, id) {
    return this.httpClient.put(`${this.baseUrl}/documents/student/${id}`, data);
  }
  createDocument(document, schoolId = "", admin = "") {
    return this.httpClient.post(
      `${this.baseUrl}/documents${admin}/${schoolId}`,
      document
    );
  }
  createDrive(drive, schoolId = "", admin = "") {
    return this.httpClient.post(
      `${this.baseUrl}/drive${admin}/${schoolId}`,
      drive
    );
  }
  updateDocument(id, document, schoolId = "", admin = "") {
    return this.httpClient.put(
      `${this.baseUrl}/documents${admin}/${id}${schoolId}`,
      document
    );
  }
  deleteDocument(id, schoolId = "", admin = "") {
    return this.httpClient.delete(
      `${this.baseUrl}/documents${admin}${schoolId}/${id}`
    );
  }
  postDocument(data) {
    return this.httpClient.post(
      `${this.baseUrl}/documents/student/create`,
      data
    );
  }
  getFile(bucket, file) {
    return this.httpClient.get(
      `${this.baseUrlDocuments}/storage/${bucket}/${file}`
    );
  }
  createBucket(data) {
    return this.httpClient.post(`${this.baseUrlDocuments}/storage/`, data);
  }
  copyFile(data) {
    return this.httpClient.post(`${this.baseUrlDocuments}/storage/copy`, data);
  }
  moveFile(data) {
    return this.httpClient.post(`${this.baseUrlDocuments}/storage/move`, data);
  }
  uploadFile(data) {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("path", data.id);
    formData.append("bucket", data.bucket);
    return this.httpClient.post(
      `${this.baseUrlDocuments}/storage/upload`,
      formData
    );
  }
  listForms(schoolId = "", admin = "") {
    return this.httpClient.get(`${this.baseUrl}/forms${admin}/${schoolId}`);
  }
  listFormsUser(schoolId = "", admin = "") {
    return this.httpClient.get(`${this.baseUrl}/forms${admin}/${schoolId}`);
  }
  listDrive(schoolId = "", admin = "") {
    return this.httpClient.get(`${this.baseUrl}/drive${admin}/${schoolId}`);
  }
  getForm(id, schoolId = "", admin = "") {
    return this.httpClient.get(
      `${this.baseUrl}/forms${admin}/${schoolId}/${id}`
    );
  }
  getFormByStudent(idD, idU) {
    return this.httpClient.get(
      `${this.baseUrl}/documents/${idD}/student/${idU}`
    );
  }
  postForm(data, schoolId = "", admin = "") {
    return this.httpClient.post(
      `${this.baseUrl}/forms${admin}/${schoolId}`,
      data
    );
  }
  updateForm(id, data, schoolId = "", admin = "") {
    return this.httpClient.put(
      `${this.baseUrl}/forms${admin}/${schoolId}/${id}`,
      data
    );
  }
  updateFormbyUser(id, data) {
    return this.httpClient.put(`${this.baseUrl}/forms/student/${id}`, data);
  }
  getStateApp(schoolId = "", admin = "") {
    return this.httpClient.get(
      `${this.baseUrl}/documents${admin}${schoolId}/getState/`
    );
  }
  deleteForm(id, schoolId = "", admin = "") {
    return this.httpClient.delete(
      `${this.baseUrl}/forms${admin}/${schoolId}/${id}`
    );
  }
  deleteFile(bucket, file) {
    return this.httpClient.delete(
      `${this.baseUrlDocuments}/storage/${bucket}/${file}`
    );
  }
  deleteDriveStorage(bucket, drive) {
    return this.httpClient.delete(
      `${this.baseUrlDocuments}/storage/drive/${bucket}/${drive}`
    );
  }
  deleteDrive(id, schoolId = "", admin = "") {
    return this.httpClient.delete(
      `${this.baseUrl}/drive${admin}${schoolId}/${id}`
    );
  }

  deleteBucket(bucket) {
    return this.httpClient.delete(`${this.baseUrlDocuments}/storage/${bucket}`);
  }
  public updateRelationshipDrives(id, data, admin = "", schoolId = "") {
    return this.httpClient.put(
      `${this.baseUrl}/drive${admin}${schoolId}/relationship/${id}`,
      data
    );
  }
  public deleteRelationshipDrives(id, data, admin = "", schoolId = "") {
    return this.httpClient.request(
      "delete",
      `${this.baseUrl}/drive${admin}${schoolId}/relationship/${id}`,
      { body: data }
    );
  }
  createRequest(data, schoolId = "", admin = "") {
    return this.httpClient.post(
      `${this.baseUrl}/documentRequest${admin}${schoolId}`,
      data
    );
  }
  listRequest(schoolId = "", admin = "") {
    return this.httpClient.get(`${this.baseUrl}/documentRequest/`);
  }
  listRequestByUser(id, schoolId = "", admin = "") {
    return this.httpClient.get(`${this.baseUrl}/documentRequest/byUser/${id}/`);
  }
  getRequestById(id, schoolId = "", admin = "") {
    return this.httpClient.get(`${this.baseUrl}/documentRequest/${id}/`);
  }

  updateRequestById(id, data, schoolId = "", admin = "") {
    return this.httpClient.put(`${this.baseUrl}/documentRequest/${id}`, data);
  }
  deleteRequest(id) {
    return this.httpClient.delete(`${this.baseUrl}/documentRequest/${id}`);
  }

  test(data: File) {
    const formData = new FormData();
    formData.append("data", data);
    return this.httpClient.post(`${this.baseUrl}/documents/test/`, formData);
  }
}
