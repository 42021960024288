import { Component, OnInit, ViewChild } from "@angular/core";
import { User } from "../@core/model/user.model";
import { Router } from "@angular/router";
import { usersService } from "../@core/api/base-api/users.api";
import * as Cookie from "js-cookie";
import { NbDialogService, NbIconConfig, NbToastrService } from "@nebular/theme";
import { WindowDeleteConfirmComponent } from "../shared/window-delete-confirm/window-delete-confirm.component";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

@Component({
  selector: "app-roles",
  templateUrl: "./roles.component.html",
  styleUrls: ["./roles.component.scss"],
})
export class RolesComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  data: any = [];
  users: User[];
  admin: string;
  schoolId: string;
  id: string;
  loading: boolean;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ["name", "description", "options"];
  constructor(
    private api: usersService,
    private router: Router,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.schoolId = localStorage.getItem("urlId");

    /*if (this.schoolId == 'admin') {
      this.admin = "/admin";
    }*/
    setTimeout(() => {
      this.refresh();
    }, 1000);
  }

  refresh() {
    this.id = localStorage.getItem("urlId");

    this.api.getRoles(this.admin).subscribe((data) => {
      this.data = data;
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    });
  }

  addRol(): void {
    this.router.navigate([this.id, "add-rol"]);
  }

  deleteRol(rol, name) {
    this.dialogService
      .open(WindowDeleteConfirmComponent, {
        context: {
          title: "Rol",
          name: rol.name,
        },
      })
      .onClose.subscribe((val) => {
        if (val) {
          this.api.deleteRoles(rol.id).subscribe(
            (data) => {
              this.showToast(
                "Message",
                "Role Deleted!",
                "success",
                "checkmark-outline"
              );
              this.refresh();
            },
            (err) => {
              this.showToast(
                "Message",
                "An error occurred, please try again",
                "danger",
                "alert-circle-outline"
              );
            }
          );
        }
      });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
