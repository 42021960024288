import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { DocumentsService } from "../../@core/api/base-api/documents.api";
import { Router, ActivatedRoute } from "@angular/router";
import { usersService } from "../../@core/api/base-api/users.api";
import { SchoolService } from "../../@core/services/school.service";
import { Location } from "@angular/common";
import { SchoolsService } from "../../@core/api/base-api/schools.api";
import { NbToastrService, NbIconConfig } from "@nebular/theme";
@Component({
  selector: "app-edit-form-component",
  templateUrl: "./edit-document.component.html",
  styleUrls: ["./edit-document.component.scss"],
})
export class EditDocumentComponent implements OnInit {
  editForm: FormGroup;
  documentId = "";
  documentName: String;
  description: String;
  type: String;
  recipient: String;
  expiration: Boolean;
  approvers: Number;
  drive: string;
  approver1: string;
  approver2: string;
  approver3: string;
  formId: string;
  roles: any = [];
  forms: any = [];
  file: File;
  url: String;
  btn1: boolean;
  btn2: boolean;
  first: boolean;
  schoolId: string;
  data: {};
  object: {};
  private responseFile;
  school: string;
  admin: string;
  value: string;
  drives: any = [];
  bucket: string;
  state: boolean;
  isfirst: boolean;
  loading: boolean;
  constructor(
    private fb: FormBuilder,
    private api: DocumentsService,
    private route: ActivatedRoute,
    private router: Router,
    private rolesService: usersService,
    private schoolS: SchoolService,
    private _location: Location,
    private apiS: SchoolsService,
    private toastrService: NbToastrService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.loading = false;
    this.schoolId = localStorage.getItem("urlId");
    this.documentId = this.route.snapshot.paramMap.get("document");
    this.school = this.route.snapshot.paramMap.get("schoolId");
    if (!this.documentId) {
      this.router.navigate([this.schoolId, "documents"]);
      return;
    }
    this.btn1 = false;
    this.btn2 = false;
    if (this.schoolId == "admin") {
      this.admin = "/admin";
    }
    this.value = this.school ? `/${this.school}` : "";
    this.rolesService
      .getUserTitleNames(this.value, this.admin)
      .subscribe((data) => {
        this.roles = data;
      });
    // this.rolesService.getRoles().subscribe((data) => {
    //   this.roles = data;
    // });

    this.value = this.school ? `/${this.school}` : "";
    const val = this.school ? `${this.school}` : "";
    this.api.listForms(val, this.admin).subscribe((data) => {
      this.forms = data;
    });
    this.state = false;
    const value2 = this.school ? `/${this.school}` : "";
    this.api.getStateApp(value2, this.admin).subscribe((data: any) => {
      if (data.length > 0) {
        this.state = true;
      }
    });
    const valueS = this.admin === "/admin" ? this.school : this.schoolId;
    this.apiS.getTenant(valueS).subscribe((data) => {
      this.bucket = data["bucket"];
    });
    const value3 = this.school ? `${this.school}` : "";
    this.api.listDrive(value3, this.admin).subscribe((data) => {
      this.drives = data;
    });

    this.isfirst = false;
    this.api
      .getDocumenbyId(this.documentId, this.value, this.admin)
      .subscribe((data) => {
        this.documentName = data["documentName"];
        this.description = data["description"];
        this.type = data["type"];
        this.recipient = data["recipient"];
        this.expiration = data["expiration"];
        this.approvers = data["approvers"];
        this.url = data["url"];
        this.approver1 = data["approvers"][0];
        this.approver2 = data["approvers"][1];
        this.approver3 = data["approvers"][2];
        this.formId = data["formId"];
        this.first = data["first"];
        this.drive = data["drive"];
        if (this.first) {
          this.isfirst = true;
        }
      });
  }

  get NameInvalid() {
    return (
      this.editForm.get("documentName").invalid &&
      this.editForm.get("documentName").touched
    );
  }
  get descriptionInvalid() {
    return (
      this.editForm.get("description").invalid &&
      this.editForm.get("description").touched
    );
  }
  get typeInvalid() {
    return (
      this.editForm.get("type").invalid && this.editForm.get("type").touched
    );
  }
  get recipientInvalid() {
    return (
      this.editForm.get("recipient").invalid &&
      this.editForm.get("recipient").touched
    );
  }
  get expirationInvalid() {
    return (
      this.editForm.get("expiration").invalid &&
      this.editForm.get("expiration").touched
    );
  }

  get approvalsInvalid() {
    return (
      this.editForm.get("approvers").invalid &&
      this.editForm.get("approvers").touched
    );
  }

  get fileInvalid() {
    return this.type == "Template" && this.file != undefined ? false : true;
  }

  createForm() {
    this.editForm = this.fb.group({
      documentName: ["", [Validators.required, Validators.minLength(3)]],
      description: ["", [Validators.required, Validators.minLength(3)]],
      type: ["", [Validators.required]],
      recipient: ["", []],
      expiration: ["", [Validators.required]],
      approver1: [],
      approver2: [],
      approver3: [],
      formId: [],
      first: [false],
    });
  }

  onFileChanged(event: any) {
    if (event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
    }
  }

  save() {
    this.loading = true;
    if (this.editForm.invalid) {
      this.loading = false;
      return Object.values(this.editForm.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }
    Object.assign(this.editForm.value, {
      approvers: [
        this.editForm.value.approver1,
        this.editForm.value.approver2,
        this.editForm.value.approver3,
      ],
    });
    if (this.type == "Template" && this.file) {
      this.object = {
        id: this.drive,
        file: this.file,
        bucket: this.bucket,
      };
      this.api.uploadFile(this.object).subscribe(
        (response) => {
          this.responseFile = response;
          if (this.responseFile.status == "ok") {
            this.data = this.editForm.value;
            this.data = Object.assign(this.data, {
              file: this.drive + "/" + this.responseFile.file,
            });

            this.api
              .updateDocument(
                this.documentId,
                this.editForm.value,
                this.value,
                this.admin
              )
              .subscribe(
                (data) => {
                  this.loading = false;
                  this.showToast(
                    "Message",
                    "Document Updated!",
                    "success",
                    "checkmark-outline"
                  );
                  if (this.school) {
                    this._location.back();
                  } else {
                    this.router.navigate([this.schoolId, "documents"]);
                  }
                },
                (err) => {
                  this.loading = false;
                  this.showToast(
                    "Message",
                    "An error occurred, please try again",
                    "danger",
                    "alert-circle-outline"
                  );
                }
              );
          }
        },
        (err) => {
          this.showToast(
            "Message",
            "An error occurred, please try again",
            "danger",
            "alert-circle-outline"
          );
        }
      );
    } else {
      this.api
        .updateDocument(
          this.documentId,
          this.editForm.value,
          this.value,
          this.admin
        )
        .subscribe(
          (data) => {
            this.showToast(
              "Message",
              "Document Updated!",
              "success",
              "checkmark-outline"
            );
            if (this.school) {
              this._location.back();
            } else {
              this._location.back();
            }
          },
          (err) => {
            this.showToast(
              "Message",
              "An error occurred, please try again",
              "danger",
              "alert-circle-outline"
            );
          }
        );
    }
  }

  back() {
    this._location.back();
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
