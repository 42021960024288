import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Level } from "../../@core/model/school.model";
import { SchoolsService } from "../../@core/api/base-api/schools.api";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { DocumentsService } from "../../@core/api/base-api/documents.api";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { NbToastrService } from "@nebular/theme";

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: "app-edit-school",
  templateUrl: "./edit-school.component.html",
  styleUrls: ["./edit-school.component.scss"],
})
export class EditSchoolComponent implements OnInit {
  editSchool: FormGroup;
  Level = new Level();
  schoolId: string;
  school: string;
  logo: string;
  data1: any = [];
  dataO1: any = [];
  data2: any = [];
  dataO2: any = [];
  data3: any = [];
  dataO3: any = [];
  data4: any = [];
  dataO4: any = [];
  loading5: boolean;

  dataO5: any;
  state5 = false;
  state: boolean;
  state1: boolean;
  state2: boolean;
  state3: boolean;
  state4: boolean;
  driveS: string;
  driveS1: string;
  driveS2: string;
  driveS3: string;
  driveS4: string;
  drive1: string;
  loading: boolean;
  loading2: boolean;
  loading3: boolean;
  loading4: boolean;
  loading1: boolean;
  bucket: string;
  res1: any;
  first: boolean;
  selection = new SelectionModel<Element>(true, []);
  call: boolean;
  title1: string;
  title2: string;
  title3: string;
  title4: string;
  title5: string;
  tab: number;
  req: boolean;
  sandbox: boolean;
  create: boolean;
  class: string;
  file: File;
  fileName: string;
  columns: any;
  columnsPrograms: any;
  columnsCourses: any;
  columnsDrives: any;
  columnsStudents: any;
  admin: string;
  drives: any;
  programs: any;
  courses: any;
  students: any;
  cView: boolean;
  cDate: any = [];
  object: {};
  responseFile: any;
  path: string;
  formatPhone: string;
  schoolImg: string;
  viewLogo: boolean;
  imgInvalid: boolean;
  infoImgInvalid: string;
  public date1 = new Date();
  public date2 = new Date();
  driveSelected: string;
  constructor(
    private fb: FormBuilder,
    private api: SchoolsService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private apiD: DocumentsService,
    private documentService: DocumentsService,
    private toastrService: NbToastrService
  ) {}

  public nameDrive(val: any): void {
    if (!this.isEmpty(val)) {
      if (val.name1 != undefined) {
        this.tab = 1;
        this.drive(val.name1);
      } else if (val.name2 != undefined) {
        this.tab = 2;
        this.drive(val.name2);
      }
    } else {
      // this.refresh();
      // this.refresh2();
      // this.refresh3();
    }
  }

  // public addD(val: any): void {
  //   if (val == 1) {
  //     this.refresh();
  //   } else if (val == 2) {
  //     this.refresh1();
  //   } else if (val == 3) {
  //     this.refresh2();
  //   } else {
  //     this.refresh3();
  //   }
  // }
  public addP(val: any): void {
    if (val == 1) {
      // this.refresh();
    }
    //else if (val == 2) {
    //   this.refresh1();
    // } else if (val == 3) {
    //   this.refresh2();
    // } else if (val == 4) {
    //   this.refresh3();
    // }
  }

  public tableR(name: any): void {
    // this.refresh();
    // this.refresh1();
    // this.refresh2();
    // this.refresh3();
  }
  public refreshR(name: any): void {
    this.columnsDrives = ["select", "name", "options"];
    this.loadData();
    this.driveSelected = "";
  }

  ngOnInit() {
    this.cView = true;
    this.viewLogo = false;
    this.imgInvalid = false;
    this.title1 = "Drives";
    this.title2 = "Campus";
    this.title3 = "Courses";
    this.title4 = "Programs";
    this.title5 = "Students";
    this.createForm();
    this.call = true;
    this.sandbox = true;
    this.schoolId = this.route.snapshot.paramMap.get("idSchool");
    this.school = localStorage.getItem("urlId");
    if (!this.schoolId) {
      this.router.navigate(["schools"]);
      return;
    }

    if (this.school == "admin") {
      this.admin = "/admin";
    }
    this.loadData();
  }

  loadData() {
    this.viewLogo = false;
    this.schoolImg = "";
    this.fileName = "";
    this.imgInvalid = false;
    this.api.getSchool(this.schoolId).subscribe((data) => {
      this.drives = data["drives"];
      this.refresh(this.drives);

      this.refresh1(data["campus"]);

      this.courses = data["courses"];
      this.refresh2(this.courses);

      this.programs = data["programs"];
      this.refresh3(this.programs);

      this.students = data["students"];
      this.refresh4(this.students);

      this.cDate = data;
      this.formatPhone =
        "(" +
        this.cDate.accountOwnerPersonalPhone.toString().substring(0, 3) +
        ") " +
        this.cDate.accountOwnerPersonalPhone.toString().substring(3, 6) +
        "-" +
        this.cDate.accountOwnerPersonalPhone.toString().substring(6, 10);
      this.date1 = new Date(data["accountStartDateContract"]);
      this.editSchool.get("accountStartDateContract").setValue(this.date1);
      this.date2 = new Date(data["accountContractExpirationDate"]);
      this.editSchool.get("accountContractExpirationDate").setValue(this.date2);
      this.editSchool.get("autoRenew").setValue(data["autoRenew"]);
      this.editSchool
        .get("accountLevelType")
        .setValue(data["accountLevelType"]);
      this.editSchool
        .get("accountOwnerPersonalName")
        .setValue(data["accountOwnerPersonalName"]);
      this.editSchool
        .get("accountOwnerPersonalEmail")
        .setValue(data["accountOwnerPersonalEmail"]);
      this.editSchool
        .get("accountOwnerPersonalPhone")
        .setValue(data["accountOwnerPersonalPhone"]);
      this.editSchool
        .get("accountOwnerCompanyName")
        .setValue(data["accountOwnerCompanyName"]);
      this.editSchool.get("DBAName").setValue(data["DBAName"]);
      this.bucket = data["bucket"];
      this.path = data["logo"];
      if (data["logo"] != undefined) {
        this.apiD
          .getFile(this.bucket, decodeURIComponent(data["logo"]))
          .subscribe((data) => {
            this.logo = data["url"];
          });
      }
      this.school = data["urlId"];
      this.columns = [
        "select",
        "campusName",
        "bursarName",
        "campusPhone",
        "campusAdmissionEmail",
        "campusID",
        "options",
      ];
      this.columnsPrograms = [
        "select",
        "programTitle",
        "credentialIssued",
        "labHours",
        "directorEducationName",
        "options",
      ];
      this.columnsCourses = [
        "select",
        "courseName",
        "totalClockhours",
        "courseTypes",
        "totalCredits",
        "mainCourseInstructorName",
        "options",
      ];
      this.columnsDrives = ["select", "name", "options"];
      this.columnsStudents = [
        "select",
        "userName",
        "email",
        "systemStatus",
        "cellPhone",
        "options",
      ];
    });
  }

  createForm() {
    this.editSchool = this.fb.group({
      accountStartDateContract: ["", [Validators.required]],
      accountContractExpirationDate: ["", [Validators.required]],
      autoRenew: ["", [Validators.required]],
      accountLevelType: [""],
      accountOwnerPersonalName: ["", [Validators.required]],
      accountOwnerPersonalEmail: ["", [Validators.required, Validators.email]],
      accountOwnerPersonalPhone: [
        "",
        [Validators.required, Validators.pattern("^[0-9]{3}[0-9]{3}[0-9]{4}$")],
      ],
      accountOwnerCompanyName: ["", [Validators.required]],
      DBAName: ["", Validators.required],
    });
  }

  get accountStartDateContractInvalid() {
    return (
      this.editSchool.get("accountStartDateContract").invalid &&
      this.editSchool.get("accountStartDateContract").touched
    );
  }

  get accountContractExpirationDateInvalid() {
    return (
      this.editSchool.get("accountContractExpirationDate").invalid &&
      this.editSchool.get("accountContractExpirationDate").touched
    );
  }

  get autoRenewInvalid() {
    return (
      this.editSchool.get("autoRenew").invalid &&
      this.editSchool.get("autoRenew").touched
    );
  }

  get accountOwnerPersonalNameInvalid() {
    return (
      this.editSchool.get("accountOwnerPersonalName").invalid &&
      this.editSchool.get("accountOwnerPersonalName").touched
    );
  }

  get accountOwnerPersonalEmailInvalid() {
    return (
      this.editSchool.get("accountOwnerPersonalEmail").invalid &&
      this.editSchool.get("accountOwnerPersonalEmail").touched
    );
  }

  get accountOwnerPersonalPhoneInvalid() {
    return (
      this.editSchool.get("accountOwnerPersonalPhone").invalid &&
      this.editSchool.get("accountOwnerPersonalPhone").touched
    );
  }

  get accountOwnerCompanyNameInvalid() {
    return (
      this.editSchool.get("accountOwnerCompanyName").invalid &&
      this.editSchool.get("accountOwnerCompanyName").touched
    );
  }

  get DBANameInvalid() {
    return (
      this.editSchool.get("DBAName").invalid &&
      this.editSchool.get("DBAName").touched
    );
  }

  goBack() {
    this.location.back();
  }

  save() {
    if (this.editSchool.invalid) {
      return Object.values(this.editSchool.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }

    if (this.file) {
      this.object = { file: this.file, bucket: this.bucket };
      this.documentService.uploadFile(this.object).subscribe((response) => {
        this.responseFile = response;
        if (this.responseFile.status == "ok") {
          this.documentService
            .deleteFile(this.bucket, this.path)
            .subscribe((res) => {
              this.object = Object.assign(this.editSchool.value, {
                logo: this.responseFile.file,
              });
              this.api
                .updateSchool(this.schoolId, this.object)
                .subscribe((data) => {
                  this.create = true;
                  this.showToast("Message", "School Updated!", "success");
                  this.class = "fade-in";
                  this.cView = true;
                  this.loadData();
                  setTimeout(() => {
                    this.class = "fadeOut";
                    this.create = false;
                  }, 5000);
                });
            });
        }
      });
    } else {
      this.api
        .updateSchool(this.schoolId, this.editSchool.value)
        .subscribe((data) => {
          this.create = true;
          this.showToast("Message", "School Updated!", "success");
          this.class = "fade-in";
          this.cView = true;
          this.loadData();
          setTimeout(() => {
            this.class = "fadeOut";
            this.create = false;
          }, 5000);
        });
    }
  }

  refresh = function (data) {
    this.loading = true;
    this.dataO1 = data;
    this.data1 = new MatTableDataSource<PeriodicElement>(this.dataO1);
    this.state = false;
    this.loading = false;
  };
  refresh1 = function (data) {
    this.loading1 = true;
    this.dataO2 = data;
    this.data2 = new MatTableDataSource<PeriodicElement>(this.dataO2);
    this.state1 = false;
    this.loading1 = false;
  };

  refresh2 = function (data) {
    this.loading2 = true;
    this.dataO3 = data;
    this.data3 = new MatTableDataSource<PeriodicElement>(this.dataO3);
    this.state3 = false;
    this.loading3 = false;
  };

  refresh3 = function (data) {
    this.loading2 = true;
    this.dataO4 = data;
    this.data4 = new MatTableDataSource<PeriodicElement>(this.dataO3);
    this.state4 = false;
    this.loading4 = false;
  };

  refresh4 = function (data) {
    this.loading5 = true;
    this.dataO5 = data;
    this.data5 = new MatTableDataSource<PeriodicElement>(this.dataO3);
    this.state5 = false;
    this.loading5 = false;
  };

  drive(val) {
    this.columnsDrives = [
      "select",
      "name",
      "description",
      "expiration",
      "recipient",
      "options",
    ];
    this.driveSelected = val;
    this.apiD
      .getListDocumentsByDrive(val, `/${this.school}`, this.admin)
      .subscribe((data) => {
        if (this.tab == 1) {
          this.loading = true;
          this.dataO1 = data["documents"];

          this.state = true;
          this.driveS = data["name"];
          this.loading = false;
        } else if (this.tab == 2) {
          this.loading1 = true;
          this.dataO2 = data["documents"];

          this.state1 = true;
          this.driveS1 = data["name"];
          this.loading1 = false;
        }
      });
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  onFileChanged(event: any) {
    this.imgInvalid = false;
    this.infoImgInvalid = "";
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].size > 1048000) {
        this.imgInvalid = true;
        this.infoImgInvalid = "Invalid size file, only accept  < 1MB";
      } else if (
        event.target.files[0].type == "image/jpeg" ||
        event.target.files[0].type == "image/jpg" ||
        event.target.files[0].type == "image/png"
      ) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.schoolImg = reader.result as string;
          this.viewLogo = true;
          var img = new Image();
          img.onload = function (scope) {
            if (img.width > 550 || img.height > 300) {
              this.imgInvalid = true;
              this.file = "";
              this.fileName = "";
              this.schoolImg = "";
              this.infoImgInvalid =
                "Width: " + img.width + "px Height: " + img.height + "px";
            }
          }.bind(this);
          img.src = reader.result as string;
        };
        this.file = event.target.files[0];
        this.fileName = this.file.name;
      } else {
        this.imgInvalid = true;
        this.infoImgInvalid = "Invalid file extension, only accept  PNG, JPG";
      }
    }
  }

  goEdit() {
    this.cView = false;
  }

  goCancel() {
    this.cView = true;
    this.viewLogo = false;
    this.schoolImg = "";
    this.fileName = "";
    this.imgInvalid = false;
  }

  showToast(title, message, status) {
    this.toastrService.show(message, title, { status });
  }
}
