import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { DocumentsService } from "../../@core/api/base-api/documents.api";
import { ActivatedRoute } from "@angular/router";
import { SchoolService } from "../../@core/services/school.service";
import { Location } from "@angular/common";
import { SchoolsService } from "../../@core/api/base-api/schools.api";
import { AuthService } from "../../@auth/auth.service";
import { usersService } from "../../@core/api/base-api/users.api";
import { NbToastrService, NbIconConfig } from "@nebular/theme";

@Component({
  selector: "app-add-drive",
  templateUrl: "./add-drive.component.html",
  styleUrls: ["./add-drive.component.scss"],
})
export class AddDriveComponent implements OnInit {
  @Output() status: EventEmitter<any> = new EventEmitter<any>();
  @Input() hidden: boolean;
  @Input() schoolE: string;
  @Input() call: boolean;
  @Input() sandbox: boolean;
  @Input() drive: string;
  @Input() tab: string;
  @Input() typeO: string;
  @Input() idE: string;
  id: string;
  addDrive: FormGroup;
  schoolId: string;
  school: string;
  admin: string;
  create: boolean;
  class: string;
  checkedDefault: boolean;
  loading: boolean;
  createDriveDefault: boolean;
  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private api: DocumentsService,
    private apiS: SchoolsService,
    private _location: Location,
    private schoolS: SchoolService,
    private route: ActivatedRoute,
    private userService: usersService,
    private toastrService: NbToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.typeO && changes.typeO) {
      if (changes.typeO.currentValue != changes.typeO.previousValue) {
        this.typeO = changes.typeO.currentValue;
      }
    }
  }

  ngOnInit() {
    this.loading = false;
    this.checkedDefault = false;
    this.schoolId = localStorage.getItem("urlId");
    this.school = this.route.snapshot.paramMap.get("schoolId");
    this.createForm();
    if (this.schoolId == "admin") {
      this.admin = "/admin";
    }

    const value = this.admin === "/admin" ? this.school : this.schoolId;
    this.apiS.getTenant(value).subscribe((data) => {
      this.addDrive.controls["schoolId"].setValue(data["_id"]);
      this.addDrive.controls["ownerId"].setValue(data["_id"]);
    });

    this.auth.isAuthenticated$.subscribe((data) => {
      if (data) {
        this.auth.getUser$().subscribe((data) => {
          if (data != undefined) {
            this.id = data["sub"];
            this.userService.getUserById(this.id).subscribe((data) => {});
          }
        });
      }
    });
    this.auth.permissions$.subscribe((data) => {
      this.createDriveDefault = data.includes("create:drive-default");
    });
  }

  createForm() {
    this.addDrive = this.fb.group({
      name: ["", [Validators.required, Validators.minLength(3)]],
      schoolId: [""],
      ownerId: [""],
      private: [false],
      default: [false],
    });
  }

  get nameInvalid() {
    return (
      this.addDrive.get("name").invalid && this.addDrive.get("name").touched
    );
  }

  clickDefault(checked: boolean) {
    this.checkedDefault = checked;
  }

  save() {
    this.loading = true;
    if (this.addDrive.invalid) {
      this.loading = false;
      return Object.values(this.addDrive.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }
    if (this.checkedDefault) {
      const data = Object.assign(this.addDrive.value, {
        default: true,
      });
    }
    const value = this.school ? `${this.school}` : "";
    this.api.createDrive(this.addDrive.value, value, this.admin).subscribe(
      (data) => {
        const value = this.school ? `${this.school}` : this.schoolId;
        this.apiS
          .updateRelationship(value, { drives: [data["_id"]] })
          .subscribe(
            (res) => {
              this.loading = false;
              if (!this.call) {
                this.showToast(
                  "Message",
                  "Drive Created!",
                  "success",
                  "checkmark-outline"
                );
                this.back();
              } else {
                if (this.call) {
                  if (this.typeO === "campus") {
                    const value =
                      this.admin === "/admin" ? `/${this.school}` : "";
                    this.apiS
                      .updateRelationshipCampus(
                        this.idE,
                        {
                          drives: [data["_id"]],
                        },
                        this.admin,
                        value
                      )
                      .subscribe((data) => {});
                  } else if (this.typeO == "programs") {
                    const value =
                      this.admin === "/admin" ? `/${this.school}` : "";
                    this.apiS
                      .updateRelationshipPrograms(
                        this.idE,
                        {
                          drives: [data["_id"]],
                        },
                        this.admin,
                        value
                      )
                      .subscribe((data) => {});
                  } else if (this.typeO == "courses") {
                    const value =
                      this.admin === "/admin" ? `/${this.school}` : "";
                    this.apiS
                      .updateRelationshipCourses(
                        this.idE,
                        {
                          drives: [data["_id"]],
                        },
                        this.admin,
                        value
                      )
                      .subscribe((data) => {});
                  }
                }

                this.afterCall();
              }
            },
            (err) => {
              this.loading = false;
              this.showToast(
                "Message",
                "An error occurred, please try again",
                "danger",
                "alert-circle-outline"
              );
            }
          );
      },
      (err) => {
        this.loading = false;
        this.showToast(
          "Message",
          "An error occurred, please try again",
          "danger",
          "alert-circle-outline"
        );
      }
    );
  }

  afterCall() {
    this.create = true;
    this.showToast("Message", "Drive Created!", "success", "checkmark-outline");
    this.class = "fade-in";
    this.status.emit(this.tab);
    this.addDrive.get("name").setValue("");
    this.addDrive.get("default").setValue(false);
    setTimeout(() => {
      this.class = "fadeOut";
      this.create = false;
    }, 5000);
  }

  back() {
    this._location.back();
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
