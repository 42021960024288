import { Component, OnInit, ViewChild } from "@angular/core";
import { SchoolsService } from "../@core/api/base-api/schools.api";
import { AuthService } from "../@auth/auth.service";
import { SchoolService } from "../@core/services/school.service";
import { DocumentsService } from "../@core/api/base-api/documents.api";
import { WindowDeleteConfirmComponent } from "../shared/window-delete-confirm/window-delete-confirm.component";
import { NbDialogService, NbIconConfig, NbToastrService } from "@nebular/theme";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-schools",
  templateUrl: "./schools.component.html",
  styleUrls: ["./schools.component.scss"],
})
export class SchoolsComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  data: any = [];
  id: string;
  role: string;
  dateNow: Date;
  displayedColumns: string[] = [
    "accountOwnerPersonalName",
    "accountOwnerCompanyName",
    "accountContractExpirationDate",
    "campus",
    "programs",
    "courses",
    "users",
    "forms",
    "drives",
    "options",
  ];
  dataSource = new MatTableDataSource();
  loading: boolean;
  constructor(
    private api: SchoolsService,
    private schoolS: SchoolService,
    private auth: AuthService,
    private apiD: DocumentsService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.dateNow = new Date();
    this.auth.getUser$().subscribe((data) => {
      this.role = data["https://cor4edu.com/roles"][0];
    });
    this.id = localStorage.getItem("urlId");
    this.refresh();
  }

  delete(school) {
    this.dialogService
      .open(WindowDeleteConfirmComponent, {
        context: {
          title: "Rol",
          name: school.accountOwnerCompanyName,
        },
      })
      .onClose.subscribe((val) => {
        if (val) {
          this.api.deleteSchool(school._id).subscribe(
            (data) => {
              this.apiD.deleteBucket(school.bucket).subscribe((res) => {
                this.showToast(
                  "Message",
                  "School Deleted!",
                  "success",
                  "checkmark-outline"
                );
                this.refresh();
              });
            },
            (err) => {
              this.showToast(
                "Message",
                "An error occurred, please try again",
                "danger",
                "alert-circle-outline"
              );
            }
          );
        }
      });
  }

  refresh() {
    this.api.listSchools().subscribe((data) => {
      this.data = data;
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    });
  }

  compareDate(expirationDate) {
    const date2 = new Date(expirationDate);
    if (date2 < this.dateNow) {
      return true;
    } else {
      return false;
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
