import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { usersService } from "../../@core/api/base-api/users.api";
import { Location } from "@angular/common";
import { NbToastrService, NbIconConfig } from "@nebular/theme";

@Component({
  selector: "app-add-rol",
  templateUrl: "./add-rol.component.html",
  styleUrls: ["./add-rol.component.scss"],
})
export class AddRolComponent implements OnInit {
  addForm: FormGroup;
  loading: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: usersService,
    private location: Location,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.loading = false;
    this.addForm = this.formBuilder.group({
      name: ["", Validators.required],
      description: ["", Validators.required],
    });
  }

  onSubmit() {
    this.loading = true;
    this.userService.createRol(this.addForm.value).subscribe(
      (data) => {
        this.showToast(
          "Message",
          "Rol created!",
          "success",
          "checkmark-outline"
        );
        this.loading = false;
        this.goBack();
      },
      (error) => {
        if (error["error"].data.lenght !== undefined) {
          this.showToast("Message", error, "warning", "alert-triangle-outline");
        }
      }
    );
  }

  goBack() {
    this.location.back();
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
