import { Component, OnInit } from "@angular/core";
import { AuthService } from "../@auth/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { SchoolsService } from "../@core/api/base-api/schools.api";
import { DocumentsService } from "../@core/api/base-api/documents.api";
import * as Cookie from "js-cookie";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { usersService } from "../@core/api/base-api/users.api";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
})
export class SignUpComponent implements OnInit {
  schoolId: string;
  logo: string;
  name: string;
  sign: FormGroup;
  bucket: string;
  constructor(
    private auth: AuthService,
    private router: Router,
    private api: SchoolsService,
    private route: ActivatedRoute,
    private apiD: DocumentsService,
    private fb: FormBuilder,
    private userService: usersService
  ) {}

  ngOnInit() {
    this.createForm();
    this.sign.get("userTitleNames").setValue("rol_UEevEAZbUjI6yNlt");
    this.schoolId = this.route.snapshot.paramMap.get("id");
    this.auth.isAuthenticated$.subscribe((data) => {
      if (data) {
        this.router.navigate(["/"]);
        return;
      }
    });

    if(this.schoolId == "admin"){
      this.router.navigate(["/"]);
    }
    // Cookie.set("schoolId", this.schoolId);

    this.api.getTenant(this.schoolId).subscribe((data) => {
      this.name = data["accountOwnerCompanyName"];
      if (data["logo"]) {
        this.bucket = data["bucket"];
        this.name = data["accountOwnerCompanyName"];
        this.apiD.getFile(this.bucket, data["logo"]).subscribe((data) => {
          this.logo = data["url"];
        });
      }
    });
  }
  createForm() {
    this.sign = this.fb.group({
      firstName: ["", [Validators.required, Validators.minLength(3)]],
      lastName: ["", [Validators.required, Validators.minLength(3)]],
      cellPhone: [
        "",
        [Validators.required, Validators.pattern("^[0-9]{3}[0-9]{3}[0-9]{4}$")],
      ],
      email: ["", [Validators.required, Validators.email]],
      userTitleNames: ["", ""],
    });
  }

  get firstNameInvalid() {
    return (
      this.sign.get("firstName").invalid &&
      this.sign.get("firstName").touched
    );
  }

  get lastNameInvalid() {
    return (
      this.sign.get("lastName").invalid && this.sign.get("lastName").touched
    );
  }

  get emailInvalid() {
    return this.sign.get("email").invalid && this.sign.get("email").touched;
  }

  get cellPhoneInvalid() {
    return (
      this.sign.get("cellPhone").invalid &&
      this.sign.get("cellPhone").touched
    );
  }

  save() {
    if (this.sign.invalid) {
      return Object.values(this.sign.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }

    this.userService
      .signUp(this.sign.value, this.schoolId)
      .subscribe((data) => {
        this.router.navigate(["/", this.schoolId, 'login']);
      });
  }
}
