import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  SimpleChanges,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { SchoolsService } from "../../@core/api/base-api/schools.api";
import { DocumentsService } from "../../@core/api/base-api/documents.api";
import { AuthService } from "../../@auth/auth.service";
import { usersService } from "../../@core/api/base-api/users.api";
import { Location } from "@angular/common";
import moment from "moment";
import { NbToastrService, NbDialogService, NbIconConfig } from "@nebular/theme";

@Component({
  selector: "app-add-course",
  templateUrl: "./add-course.component.html",
  styleUrls: ["./add-course.component.scss"],
})
export class AddCourseComponent implements OnInit {
  @Output() status: EventEmitter<any> = new EventEmitter<any>();
  @Input() hidden: boolean;
  @Input() schoolE: string;
  @Input() school: string;
  @Input() call: boolean;
  @Input() tab: string;
  addCourse: FormGroup;
  form: boolean;
  id: string;
  role: string;
  accountNumber: number;
  students: any = [];
  schoolId: string;
  create: boolean;
  class: string;
  // school: string;
  admin: string;
  idSchool: string;
  firstDate: any;
  secondDate: any;
  diffInWeek: number;
  idCourse: string;
  addDrive: FormGroup;
  alert: boolean;
  message: string;
  cont: number;
  public dataDrive = [];
  selectedCourses: any = [];
  cloneDrive: any = [];
  names: string[] = [];
  checkedDefault: boolean;
  viewInfo: boolean;
  loading: boolean;

  @ViewChild("dialog", { static: true }) dialog: TemplateRef<any>;
  r: any;
  viewImport: boolean;

  constructor(
    private fb: FormBuilder,
    private api: SchoolsService,
    private apiD: DocumentsService,
    private auth: AuthService,
    private user: usersService,
    private route: ActivatedRoute,
    private location: Location,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.schoolE && changes.schoolE) {
      if (changes.schoolE.currentValue != changes.schoolE.previousValue) {
        this.auth.getUser$().subscribe((data) => {
          //this.school = changes.school.currentValue;
          this.id = data["sub"];
          this.role = data["https://cor4edu.com/roles"][0];
          if (this.schoolId != "admin") {
            this.getData();
          }
        });
      }
    }
  }

  ngOnInit() {
    this.loading = false;
    this.viewImport = false;
    this.viewInfo = false;
    this.checkedDefault = false;
    this.form = false;
    this.schoolId = this.route.snapshot.paramMap.get("schoolId");
    this.school = localStorage.getItem("urlId");
    if (this.school == "admin") {
      this.admin = "/admin";
    }
    this.createForm();
    this.form = true;
    if (!this.call) {
      this.auth.getUser$().subscribe((data) => {
        this.id = data["sub"];
        this.role = data["https://cor4edu.com/roles"][0];
        this.getData();
      });
    }
  }

  getData() {
    if (this.role == "cor-admin" || this.role == "ag-admin-us") {
      this.api
        .listCourses(`/${this.schoolId}`, this.admin)
        .subscribe((data) => {
          this.selectedCourses = data;
          if (this.selectedCourses.length > 0) this.viewImport = true;
        });
    } else {
      this.api.listCourses().subscribe((data) => {
        this.selectedCourses = data;
        if (this.selectedCourses.length > 0) this.viewImport = true;
      });
    }
    const valueU = this.admin === "/admin" ? this.schoolId : "";
    this.user.getUsersByAccount(valueU, this.admin).subscribe((data) => {
      this.students = data;
    });
    const value = this.admin === "/admin" ? this.schoolId : this.school;
    this.api.getTenant(value).subscribe((data) => {
      this.idSchool = data["_id"];
    });
  }

  createForm() {
    this.addDrive = this.fb.group({});
    this.addCourse = this.fb.group({
      courseName: ["", [Validators.required]],
      mainCourseInstructorName: ["", [Validators.required]],
      courseDays: [[], [Validators.required]],
      courseTime: ["", [Validators.required]],
      dayNight: ["", [Validators.required]],
      courseLengthWeeks: ["", [Validators.required]],
      totalHours: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      totalCredits: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      totalSemesterQuarterCredit: ["", [Validators.required]],
      classroomNumber: ["", [Validators.required]],
      buildingName: ["", [Validators.required]],
      secondaryInstructorGuestSpeakersNames: ["", [Validators.required]],
      specialLecturerName: ["", [Validators.required]],
      specialEvent: ["", [Validators.required]],
      fieldTrip: ["", [Validators.required]],
      prerequisites: ["", [Validators.required]],
      corequisites: ["", [Validators.required]],
      courseTypes: ["", [Validators.required]],
      instructorHoursAvailability: ["", [Validators.required]],
      academicAdvisorName: ["", [Validators.required]],
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
      accountNumber: [this.accountNumber],
      students: [],
    });
  }

  get courseNameInvalid() {
    return (
      this.addCourse.get("courseName").invalid &&
      this.addCourse.get("courseName").touched
    );
  }

  get mainCourseInstructorNameInvalid() {
    return (
      this.addCourse.get("mainCourseInstructorName").invalid &&
      this.addCourse.get("mainCourseInstructorName").touched
    );
  }

  get courseDaysInvalid() {
    return (
      this.addCourse.get("courseDays").invalid &&
      this.addCourse.get("courseDays").touched
    );
  }

  get courseTimeInvalid() {
    return (
      this.addCourse.get("courseTime").invalid &&
      this.addCourse.get("courseTime").touched
    );
  }

  get dayNightInvalid() {
    return (
      this.addCourse.get("dayNight").invalid &&
      this.addCourse.get("dayNight").touched
    );
  }

  get courseLengthWeeksInvalid() {
    return (
      this.addCourse.get("courseLengthWeeks").invalid &&
      this.addCourse.get("courseLengthWeeks").touched
    );
  }

  get totalHoursInvalid() {
    return (
      this.addCourse.get("totalHours").invalid &&
      this.addCourse.get("totalHours").touched
    );
  }

  get totalCreditsInvalid() {
    return (
      this.addCourse.get("totalCredits").invalid &&
      this.addCourse.get("totalCredits").touched
    );
  }

  get totalSemesterQuarterCreditInvalid() {
    return (
      this.addCourse.get("totalSemesterQuarterCredit").invalid &&
      this.addCourse.get("totalSemesterQuarterCredit").touched
    );
  }

  get classroomNumberInvalid() {
    return (
      this.addCourse.get("classroomNumber").invalid &&
      this.addCourse.get("classroomNumber").touched
    );
  }

  get buildingNameInvalid() {
    return (
      this.addCourse.get("buildingName").invalid &&
      this.addCourse.get("buildingName").touched
    );
  }

  get secondaryInstructorGuestSpeakersNamesInvalid() {
    return (
      this.addCourse.get("secondaryInstructorGuestSpeakersNames").invalid &&
      this.addCourse.get("secondaryInstructorGuestSpeakersNames").touched
    );
  }

  get specialLecturerNameInvalid() {
    return (
      this.addCourse.get("specialLecturerName").invalid &&
      this.addCourse.get("specialLecturerName").touched
    );
  }

  get specialEventInvalid() {
    return (
      this.addCourse.get("specialEvent").invalid &&
      this.addCourse.get("specialEvent").touched
    );
  }

  get fieldTripInvalid() {
    return (
      this.addCourse.get("fieldTrip").invalid &&
      this.addCourse.get("fieldTrip").touched
    );
  }

  get prerequisitesInvalid() {
    return (
      this.addCourse.get("prerequisites").invalid &&
      this.addCourse.get("prerequisites").touched
    );
  }

  get corequisitesInvalid() {
    return (
      this.addCourse.get("corequisites").invalid &&
      this.addCourse.get("corequisites").touched
    );
  }

  get courseTypesInvalid() {
    return (
      this.addCourse.get("courseTypes").invalid &&
      this.addCourse.get("courseTypes").touched
    );
  }

  get instructorHoursAvailabilityInvalid() {
    return (
      this.addCourse.get("instructorHoursAvailability").invalid &&
      this.addCourse.get("instructorHoursAvailability").touched
    );
  }

  get academicAdvisorNameInvalid() {
    return (
      this.addCourse.get("academicAdvisorName").invalid &&
      this.addCourse.get("academicAdvisorName").touched
    );
  }

  get startDateInvalid() {
    return (
      this.addCourse.get("startDate").invalid &&
      this.addCourse.get("startDate").touched
    );
  }

  get endDateInvalid() {
    return (
      this.addCourse.get("endDate").invalid &&
      this.addCourse.get("endDate").touched
    );
  }

  getInfo() {
    this.viewInfo = true;
  }

  save() {
    this.loading = true;
    if (this.addCourse.invalid) {
      this.loading = false;
      return Object.values(this.addCourse.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }

    if (this.role == "cor-admin" || this.role == "ag-admin-us") {
      const data = Object.assign(this.addCourse.value, {
        schoolId: this.idSchool,
      });
      const value = this.school === "admin" ? this.schoolId : this.school;

      this.firstDate = moment(data.startDate);
      this.secondDate = moment(data.endDate);
      this.diffInWeek = Math.abs(this.firstDate.diff(this.secondDate, "week"));

      if (this.diffInWeek > 0) {
        this.api.createCourse(data, value, this.admin).subscribe(
          (data) => {
            this.api
              .updateRelationship(value, { courses: [data["_id"]] })
              .subscribe(
                (res) => {
                  this.idCourse = data["_id"];

                  // add drive
                  this.dataDrive = [];
                  for (let i = 0; i < this.diffInWeek; i++) {
                    this.cont = i + 1;
                    this.dataDrive.push({
                      default: false,
                      name:
                        data["courseName"].replace(/\s/g, "").toLowerCase() +
                        "-Week" +
                        this.cont,
                      ownerId: data["schoolId"],
                      private: false,
                      schoolId: data["schoolId"],
                    });
                    this.apiD
                      .createDrive(this.dataDrive[i], value, this.admin)
                      .subscribe(
                        (dataD) => {
                          this.api
                            .updateRelationship(this.schoolId, {
                              drives: [dataD["_id"]],
                            })
                            .subscribe(
                              (res) => {
                                const value = this.schoolId;
                                this.api
                                  .updateRelationshipCourses(
                                    this.idCourse,
                                    { drives: [dataD["_id"]] },
                                    this.admin + "/",
                                    value
                                  )
                                  .subscribe(
                                    (data) => {},
                                    (err) => {
                                      this.loading = false;
                                      this.showToast(
                                        "Message",
                                        "An error occurred, please try again",
                                        "danger",
                                        "alert-circle-outline"
                                      );
                                    }
                                  );
                              },
                              (err) => {
                                this.loading = false;
                                this.showToast(
                                  "Message",
                                  "An error occurred, please try again",
                                  "danger",
                                  "alert-circle-outline"
                                );
                              }
                            );
                        },
                        (err) => {
                          this.loading = false;
                          this.showToast(
                            "Message",
                            "An error occurred, please try again",
                            "danger",
                            "alert-circle-outline"
                          );
                        }
                      );
                  }
                  if (this.cloneDrive.length > 0) {
                    this.cloneDrive.forEach((element) => {
                      this.dataDrive.push({
                        documents: element.documents,
                        default: true,
                        name: element.name,
                        ownerId: element.ownerId,
                        private: false,
                        schoolId: element.schoolId,
                      });
                      this.apiD
                        .createDrive(
                          this.dataDrive[this.cont],
                          value,
                          this.admin
                        )
                        .subscribe((dataE) => {
                          this.api
                            .updateRelationship(this.schoolId, {
                              drives: [dataE["_id"]],
                            })
                            .subscribe((res) => {
                              const value = this.schoolId;
                              this.api
                                .updateRelationshipCourses(
                                  this.idCourse,
                                  { drives: [dataE["_id"]] },
                                  this.admin + "/",
                                  value
                                )
                                .subscribe((data) => {});
                            });
                        });
                      this.cont = this.cont + 1;
                    });
                  }

                  if (!this.call) {
                    this.loading = false;
                    this.showToast(
                      "Message",
                      "Course Created!",
                      "success",
                      "checkmark-outline"
                    );
                    this.location.back();
                  } else {
                    this.loading = false;
                    this.afterCall(data["_id"]);
                  }
                },
                (err) => {
                  this.loading = false;
                  this.showToast(
                    "Message",
                    "An error occurred, please try again",
                    "danger",
                    "alert-circle-outline"
                  );
                }
              );
          },
          (err) => {
            this.loading = false;
            this.showToast(
              "Message",
              "An error occurred, please try again",
              "danger",
              "alert-circle-outline"
            );
          }
        );
      } else {
        this.loading = false;
        this.showToast(
          "Message",
          "Number weeks course < 1",
          "warning",
          "alert-triangle-outline"
        );
      }
    } else {
      const data = Object.assign(this.addCourse.value, {
        schoolId: this.idSchool,
      });

      this.firstDate = moment(data.startDate);
      this.secondDate = moment(data.endDate);
      this.diffInWeek = Math.abs(this.firstDate.diff(this.secondDate, "week"));
      //console.log(this.diffInWeek);

      /*this.api.createCourse(data).subscribe((data) => {
        this.api
          .updateRelationship(this.school, { courses: [data["_id"]] })
          .subscribe((res) => {
            if (!this.call) {
              this.location.back();
            } else {
              this.afterCall(data["_id"]);
            }
          });
      });*/

      if (this.diffInWeek > 0) {
        this.api.createCourse(data).subscribe((data) => {
          this.api
            .updateRelationship(this.school, { courses: [data["_id"]] })
            .subscribe((res) => {
              this.idCourse = data["_id"];

              // add drive
              this.dataDrive = [];
              for (let i = 0; i < this.diffInWeek; i++) {
                this.cont = i + 1;
                this.dataDrive.push({
                  default: false,
                  name:
                    data["courseName"].replace(/\s/g, "").toLowerCase() +
                    "-Week" +
                    this.cont,
                  ownerId: data["schoolId"],
                  private: false,
                  schoolId: data["schoolId"],
                });
                this.apiD
                  .createDrive(this.dataDrive[i], this.admin)
                  .subscribe((dataD) => {
                    this.api
                      .updateRelationship(this.schoolId, {
                        drives: [dataD["_id"]],
                      })
                      .subscribe((res) => {
                        const value = this.schoolId;
                        this.api
                          .updateRelationshipCourses(this.idCourse, {
                            drives: [dataD["_id"]],
                          })
                          .subscribe((data) => {});
                      });
                  });
              }
              if (this.cloneDrive.length > 0) {
                this.cloneDrive.forEach((element) => {
                  this.dataDrive.push({
                    documents: element.documents,
                    default: true,
                    name: element.name,
                    ownerId: element.ownerId,
                    private: false,
                    schoolId: element.schoolId,
                  });
                  this.apiD
                    .createDrive(this.dataDrive[this.cont], this.admin)
                    .subscribe((dataE) => {
                      this.api
                        .updateRelationship(this.schoolId, {
                          drives: [dataE["_id"]],
                        })
                        .subscribe((res) => {
                          const value = this.schoolId;
                          this.api
                            .updateRelationshipCourses(
                              this.idCourse,
                              { drives: [dataE["_id"]] },
                              this.admin + "/",
                              value
                            )
                            .subscribe((data) => {});
                        });
                    });
                  this.cont = this.cont + 1;
                });
              }
              if (!this.call) {
                this.loading = false;
                this.showToast(
                  "Message",
                  "Course Created!",
                  "success",
                  "checkmark-outline"
                );
                this.location.back();
              } else {
                this.loading = false;
                this.afterCall(data["_id"]);
              }
            });
        });
      } else {
        this.showToast(
          "Message",
          "Number weeks course < 1",
          "warning",
          "alert-triangle-outline"
        );
        this.loading = false;
      }
    }
  }

  afterCall(id) {
    this.create = true;
    this.showToast(
      "Message",
      "Course Created!",
      "success",
      "checkmark-outline"
    );
    this.class = "fade-in";
    this.status.emit([id, "courses"]);
    this.addCourse.reset({
      students: [[]],
      courseDays: [[], [Validators.required]],
    });

    setTimeout(() => {
      this.class = "fadeOut";
      this.create = false;
    }, 5000);
  }

  selectCourse() {
    this.r = this.dialogService.open(this.dialog, {
      context: { title: "Select Course ", context: "Select Course to clone: " },
    });
  }

  cloneCourse(course) {
    if (course != null && course != undefined) {
      this.r.close();
      if (this.role == "cora-dmin" || this.role == "ag-admin-us") {
        this.api
          .getCourse(course, `/${this.schoolId}`, this.admin)
          .subscribe((data) => {
            this.setData(data);
          });
      } else {
        this.api.getCourse(course).subscribe((data) => {
          this.setData(data);
        });
      }
    }
  }

  clickDefault(checked: boolean) {
    this.checkedDefault = checked;
  }

  setData(data) {
    this.addCourse.get("courseDays").setValue(data["courseDays"]);
    this.addCourse.get("courseName").setValue(data["courseName"]);
    this.addCourse
      .get("mainCourseInstructorName")
      .setValue(data["mainCourseInstructorName"]);
    this.addCourse.get("courseTime").setValue(data["courseTime"]);
    this.addCourse.get("dayNight").setValue(data["dayNight"]);
    this.addCourse.get("courseLengthWeeks").setValue(data["courseLengthWeeks"]);
    this.addCourse.get("totalHours").setValue(data["totalHours"]);
    this.addCourse.get("totalCredits").setValue(data["totalCredits"]);
    this.addCourse
      .get("totalSemesterQuarterCredit")
      .setValue(data["totalSemesterQuarterCredit"]);
    this.addCourse.get("classroomNumber").setValue(data["classroomNumber"]);
    this.addCourse.get("buildingName").setValue(data["buildingName"]);
    this.addCourse
      .get("secondaryInstructorGuestSpeakersNames")
      .setValue(data["secondaryInstructorGuestSpeakersNames"]);
    this.addCourse
      .get("specialLecturerName")
      .setValue(data["specialLecturerName"]);
    this.addCourse.get("specialEvent").setValue(data["specialEvent"]);
    this.addCourse.get("fieldTrip").setValue(data["fieldTrip"]);
    this.addCourse.get("prerequisites").setValue(data["prerequisites"]);
    this.addCourse.get("corequisites").setValue(data["corequisites"]);
    this.addCourse.get("courseTypes").setValue(data["courseTypes"]);
    this.addCourse
      .get("instructorHoursAvailability")
      .setValue(data["instructorHoursAvailability"]);
    this.addCourse
      .get("academicAdvisorName")
      .setValue(data["academicAdvisorName"]);

    if (this.checkedDefault) {
      data["drives"].forEach((element) => {
        if (element.default) {
          this.cloneDrive.push(element);
        }
      });
    }
  }

  goBack() {
    this.location.back();
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
