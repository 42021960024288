import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { SchoolsService } from "../../@core/api/base-api/schools.api";
import { AuthService } from "../../@auth/auth.service";
import { usersService } from "../../@core/api/base-api/users.api";
import { Location } from "@angular/common";
import { MatTableDataSource } from "@angular/material/table";
import { PeriodicElement } from "../../schools/edit-school/edit-school.component";
import { DocumentsService } from "../../@core/api/base-api/documents.api";
import { NbToastrService, NbIconConfig } from "@nebular/theme";

@Component({
  selector: "app-edit-course",
  templateUrl: "./edit-course.component.html",
  styleUrls: ["./edit-course.component.scss"],
})
export class EditCourseComponent implements OnInit, OnChanges {
  @Input() call: boolean;
  @Input() idE: string;
  @Input() schoolE: string;
  @Input() typeP: string;
  @Output() statusP: EventEmitter<any> = new EventEmitter<any>();
  editCourse: FormGroup;
  courseId: String;
  form: boolean;
  id: string;
  role: string;
  accountNumber: number;
  students: any = [];
  schoolId: string;
  loading: boolean;
  loading2: boolean;
  dataO1: any;
  data1: any;
  dataO2: any;
  data2: any;
  dataO3: any;
  data3: any;
  state: boolean;
  state1: boolean;
  state3: boolean;
  columnsStudents: any;
  columnsPrograms: any;
  columnsDrives: any;
  title1: string;
  title2: string;
  title3: string;
  bucket: string;
  driveS: string;
  tab: number;
  school: string;
  admin: string;
  users: any = [];
  cDate: any;
  printv: boolean;
  cView: boolean;
  drives: any = [];
  responseFile: any;
  path: string;
  schoolImg: string;
  viewLogo: boolean;
  imgInvalid: boolean;
  infoImgInvalid: string;
  file: File;
  fileName: string;
  object: {};
  logo: string;
  public date1 = new Date();
  public date2 = new Date();
  driveSelected: string;
  loadingD: boolean;
  permiStudent: boolean;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private api: SchoolsService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private user: usersService,
    private location: Location,
    private apiD: DocumentsService,
    private toastrService: NbToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.idE && changes.idE) {
      if (changes.idE.currentValue != changes.idE.previousValue) {
        this.auth.getUser$().subscribe((data) => {
          this.id = data["sub"];
          this.role = data["https://cor4edu.com/roles"][0];
          this.courseId = changes.idE.currentValue;
          this.schoolId = changes.schoolE.currentValue;
          this.getData();
        });
      }
    }
  }

  ngOnInit() {
    this.loadingD = true;
    this.cView = true;
    this.courseId = this.route.snapshot.paramMap.get("id");
    this.schoolId = this.route.snapshot.paramMap.get("schoolId");
    this.school = localStorage.getItem("urlId");
    if (!this.courseId && !this.call) {
      this.router.navigate(["programs"]);
      return;
    }
    this.form = false;

    if (this.school == "admin") {
      this.admin = "/admin";
    }
    this.createForm();
    this.form = true;
    if (!this.call) {
      this.auth.getUser$().subscribe((data) => {
        this.id = data["sub"];
        this.role = data["https://cor4edu.com/roles"][0];
        this.accountNumber = data["accountNumber"];

        if (this.typeP === "view") {
          this.editCourse.disable();
        }
        this.auth.permissions$.subscribe((res) => {
          this.permiStudent = res.includes("read:courses-student");
          this.getData();
        });
      });
    }
    this.title1 = "Programs";
    this.title2 = "Drives";
    this.title3 = "Students";
    this.columnsPrograms = [
      "select",
      "programTitle",
      "credentialIssued",
      "labHours",
      "programLength",
      "directorEducationName",
      "options",
    ];
    this.columnsStudents = [
      "select",
      "userName",
      "email",
      "systemStatus",
      "cellPhone",
      "options",
    ];
    this.columnsDrives = ["select", "name", "options"];
  }

  getData() {
    if (this.permiStudent) {
      this.api.getCourse(this.courseId).subscribe((data) => {
        const value =
          this.admin === "/admin" ? `${this.schoolId}` : this.school;
        this.api.getTenant(value).subscribe((resp) => {
          this.bucket = resp["bucket"];
          this.setData(data);
        });
      });
    } else {
      const value = this.admin === "/admin" ? `${this.schoolId}` : this.school;
      this.api.getTenant(value).subscribe((data) => {
        this.bucket = data["bucket"];
        const valueU = this.admin === "/admin" ? this.schoolId : "";
        this.user.getUsersByAccount(valueU, this.admin).subscribe((data) => {
          this.students = data;
        });
        if (this.role == "cora-dmin" || this.role == "ag-admin-us") {
          this.api
            .getCourse(this.courseId, `/${this.schoolId}`, this.admin)
            .subscribe((data) => {
              this.setData(data);
            });
        } else {
          this.api.getCourse(this.courseId).subscribe((data) => {
            this.setData(data);
          });
        }
      });
    }
  }

  onFileChanged(event: any) {
    this.imgInvalid = false;
    this.infoImgInvalid = "";
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].size > 1048000) {
        this.imgInvalid = true;
        this.infoImgInvalid = "Invalid size file, only accept  < 1MB";
      } else if (
        event.target.files[0].type == "image/jpeg" ||
        event.target.files[0].type == "image/jpg" ||
        event.target.files[0].type == "image/png"
      ) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.schoolImg = reader.result as string;
          this.viewLogo = true;
          var img = new Image();
          img.onload = function (scope) {
            if (img.width > 550 || img.height > 300) {
              this.imgInvalid = true;
              this.file = "";
              this.fileName = "";
              this.schoolImg = "";
              this.infoImgInvalid =
                "Width: " + img.width + "px Height: " + img.height + "px";
            }
          }.bind(this);
          img.src = reader.result as string;
        };
        this.file = event.target.files[0];
        this.fileName = this.file.name;
      } else {
        this.imgInvalid = true;
        this.infoImgInvalid = "Invalid file extension, only accept  PNG, JPG";
      }
    }
  }

  print() {
    window.print();
    this.printv = false;
    this.dismiss(false);
  }

  public nameDrive(val: any): void {
    if (!this.isEmpty(val)) {
      if (val.name1 != undefined) {
        this.tab = 1;
        this.drive(val.name1);
      } else if (val.name2 != undefined) {
        this.tab = 2;
        this.drive(val.name2);
      }
    }
  }

  public tableR(name: any): void {
    this.getData();
    // this.refresh1();
    // this.refresh2();
    // this.refresh3();
  }

  public refreshR(data: any): void {
    if (data != undefined) {
      this.columnsDrives = ["select", "name", "options"];
      const value = this.admin === "/admin" ? `/${this.schoolId}` : "";
      this.api
        .updateRelationshipCourses(
          this.courseId,
          { [data[1]]: [data[0]] },
          this.admin,
          value
        )
        .subscribe((data) => {
          this.getData();
        });
    }
  }

  refresh = function (data) {
    this.dataO1 = data;
    this.data1 = new MatTableDataSource<PeriodicElement>(this.dataO1);
    this.state = false;
    this.loading = false;
  };

  refresh1 = function (data) {
    this.loading2 = true;
    this.dataO2 = data;
    this.data2 = new MatTableDataSource<PeriodicElement>(this.dataO2);
    this.state1 = false;
    this.loading2 = false;
  };

  refresh2 = function (data) {
    this.loading3 = true;
    this.dataO3 = data;
    this.data3 = new MatTableDataSource<PeriodicElement>(this.dataO3);
    this.state3 = false;
    this.loading3 = false;
  };

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  goEdit() {
    this.cView = false;
  }

  goCancel() {
    this.cView = true;
  }

  drive(val) {
    this.columnsDrives = [
      "select",
      "name",
      "description",
      "expiration",
      "recipient",
      "options",
    ];
    this.driveSelected = val;
    const value = this.admin === "/admin" ? `/${this.schoolId}` : "";
    this.apiD
      .getListDocumentsByDrive(val, value, this.admin)
      .subscribe((data) => {
        this.loading2 = true;
        this.dataO2 = data["documents"];

        this.state = true;
        this.driveS = data["name"];
        this.loading2 = false;
      });
  }

  setData(data) {
    this.cDate = data;
    this.refresh(data["programs"]);
    this.drives = data["drives"];
    this.refresh1(this.drives);
    this.refresh2(data["students"]);
    this.editCourse.get("courseDays").setValue(data["courseDays"]);
    this.editCourse.get("courseName").setValue(data["courseName"]);
    this.editCourse
      .get("mainCourseInstructorName")
      .setValue(data["mainCourseInstructorName"]);
    this.editCourse.get("courseTime").setValue(data["courseTime"]);
    this.editCourse.get("dayNight").setValue(data["dayNight"]);
    this.editCourse
      .get("courseLengthWeeks")
      .setValue(data["courseLengthWeeks"]);
    this.editCourse.get("totalHours").setValue(data["totalHours"]);
    this.editCourse.get("totalCredits").setValue(data["totalCredits"]);
    this.editCourse
      .get("totalSemesterQuarterCredit")
      .setValue(data["totalSemesterQuarterCredit"]);
    this.editCourse.get("classroomNumber").setValue(data["classroomNumber"]);
    this.editCourse.get("buildingName").setValue(data["buildingName"]);
    this.editCourse
      .get("secondaryInstructorGuestSpeakersNames")
      .setValue(data["secondaryInstructorGuestSpeakersNames"]);
    this.editCourse
      .get("specialLecturerName")
      .setValue(data["specialLecturerName"]);
    this.editCourse.get("specialEvent").setValue(data["specialEvent"]);
    this.editCourse.get("fieldTrip").setValue(data["fieldTrip"]);
    this.editCourse.get("prerequisites").setValue(data["prerequisites"]);
    this.editCourse.get("corequisites").setValue(data["corequisites"]);
    this.editCourse.get("courseTypes").setValue(data["courseTypes"]);
    this.editCourse
      .get("instructorHoursAvailability")
      .setValue(data["instructorHoursAvailability"]);
    this.editCourse
      .get("academicAdvisorName")
      .setValue(data["academicAdvisorName"]);
    this.date1 = new Date(data["startDate"]);
    if (data["logo"] != undefined) {
      this.apiD
        .getFile(this.bucket, decodeURIComponent(data["logo"]))
        .subscribe((data) => {
          this.logo = data["url"];
        });
    }
    this.editCourse.get("startDate").setValue(this.date1);
    this.date2 = new Date(data["endDate"]);
    this.editCourse.get("endDate").setValue(this.date2);

    if (data.students) {
      data.students.forEach((e: any) => {
        this.users.push(e._id);
      });
    }
    this.editCourse.get("students").setValue(this.users ? this.users : []);
    if (this.typeP === "print") {
      this.printv = true;
    }
    this.loadingD = false;
  }

  createForm() {
    this.editCourse = this.fb.group({
      courseName: ["", [Validators.required]],
      mainCourseInstructorName: ["", [Validators.required]],
      courseDays: [[], [Validators.required]],
      courseTime: ["", [Validators.required]],
      dayNight: ["", [Validators.required]],
      courseLengthWeeks: ["", [Validators.required]],
      totalHours: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      totalCredits: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      totalSemesterQuarterCredit: ["", [Validators.required]],
      classroomNumber: ["", [Validators.required]],
      buildingName: ["", [Validators.required]],
      secondaryInstructorGuestSpeakersNames: ["", [Validators.required]],
      specialLecturerName: ["", [Validators.required]],
      specialEvent: ["", [Validators.required]],
      fieldTrip: ["", [Validators.required]],
      prerequisites: ["", [Validators.required]],
      corequisites: ["", [Validators.required]],
      courseTypes: ["", [Validators.required]],
      instructorHoursAvailability: ["", [Validators.required]],
      academicAdvisorName: ["", [Validators.required]],
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
      students: [],
    });
  }

  get courseNameInvalid() {
    return (
      this.editCourse.get("courseName").invalid &&
      this.editCourse.get("courseName").touched
    );
  }

  get mainCourseInstructorNameInvalid() {
    return (
      this.editCourse.get("mainCourseInstructorName").invalid &&
      this.editCourse.get("mainCourseInstructorName").touched
    );
  }

  get courseDaysInvalid() {
    return (
      this.editCourse.get("courseDays").invalid &&
      this.editCourse.get("courseDays").touched
    );
  }

  get courseTimeInvalid() {
    return (
      this.editCourse.get("courseTime").invalid &&
      this.editCourse.get("courseTime").touched
    );
  }

  get dayNightInvalid() {
    return (
      this.editCourse.get("dayNight").invalid &&
      this.editCourse.get("dayNight").touched
    );
  }

  get courseLengthWeeksInvalid() {
    return (
      this.editCourse.get("courseLengthWeeks").invalid &&
      this.editCourse.get("courseLengthWeeks").touched
    );
  }

  get totalHoursInvalid() {
    return (
      this.editCourse.get("totalHours").invalid &&
      this.editCourse.get("totalHours").touched
    );
  }

  get totalCreditsInvalid() {
    return (
      this.editCourse.get("totalCredits").invalid &&
      this.editCourse.get("totalCredits").touched
    );
  }

  get totalSemesterQuarterCreditInvalid() {
    return (
      this.editCourse.get("totalSemesterQuarterCredit").invalid &&
      this.editCourse.get("totalSemesterQuarterCredit").touched
    );
  }

  get classroomNumberInvalid() {
    return (
      this.editCourse.get("classroomNumber").invalid &&
      this.editCourse.get("classroomNumber").touched
    );
  }

  get buildingNameInvalid() {
    return (
      this.editCourse.get("buildingName").invalid &&
      this.editCourse.get("buildingName").touched
    );
  }

  get secondaryInstructorGuestSpeakersNamesInvalid() {
    return (
      this.editCourse.get("secondaryInstructorGuestSpeakersNames").invalid &&
      this.editCourse.get("secondaryInstructorGuestSpeakersNames").touched
    );
  }

  get specialLecturerNameInvalid() {
    return (
      this.editCourse.get("specialLecturerName").invalid &&
      this.editCourse.get("specialLecturerName").touched
    );
  }

  get specialEventInvalid() {
    return (
      this.editCourse.get("specialEvent").invalid &&
      this.editCourse.get("specialEvent").touched
    );
  }

  get fieldTripInvalid() {
    return (
      this.editCourse.get("fieldTrip").invalid &&
      this.editCourse.get("fieldTrip").touched
    );
  }

  get prerequisitesInvalid() {
    return (
      this.editCourse.get("prerequisites").invalid &&
      this.editCourse.get("prerequisites").touched
    );
  }

  get corequisitesInvalid() {
    return (
      this.editCourse.get("corequisites").invalid &&
      this.editCourse.get("corequisites").touched
    );
  }

  get courseTypesInvalid() {
    return (
      this.editCourse.get("courseTypes").invalid &&
      this.editCourse.get("courseTypes").touched
    );
  }

  get instructorHoursAvailabilityInvalid() {
    return (
      this.editCourse.get("instructorHoursAvailability").invalid &&
      this.editCourse.get("instructorHoursAvailability").touched
    );
  }

  get academicAdvisorNameInvalid() {
    return (
      this.editCourse.get("academicAdvisorName").invalid &&
      this.editCourse.get("academicAdvisorName").touched
    );
  }

  get startDateInvalid() {
    return (
      this.editCourse.get("startDate").invalid &&
      this.editCourse.get("startDate").touched
    );
  }

  get endDateInvalid() {
    return (
      this.editCourse.get("endDate").invalid &&
      this.editCourse.get("endDate").touched
    );
  }

  save() {
    this.loadingD = true;
    if (this.editCourse.invalid) {
      this.loadingD = false;
      return Object.values(this.editCourse.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }
    if (this.file) {
      this.object = { file: this.file, bucket: this.bucket };
      this.apiD.uploadFile(this.object).subscribe((response) => {
        this.responseFile = response;
        if (this.responseFile.status == "ok") {
          this.apiD.deleteFile(this.bucket, this.path).subscribe((res) => {
            this.object = Object.assign(this.editCourse.value, {
              logo: this.responseFile.file,
            });
            const value = this.admin === "/admin" ? `/${this.schoolId}` : "";
            this.api
              .updateCourse(this.courseId, this.object, value, this.admin)
              .subscribe((data) => {
                this.showToast(
                  "Message",
                  "Course Updated!",
                  "success",
                  "checkmark-outline"
                );
                this.cView = true;
                this.getData();
              });
          });
        }
      });
    } else {
      if (this.role == "cora-dmin" || this.role == "ag-admin-us") {
        this.api
          .updateCourse(
            this.courseId,
            this.editCourse.value,
            `/${this.schoolId}`,
            this.admin
          )
          .subscribe(
            (data) => {
              this.showToast(
                "Message",
                "Course Updated!",
                "success",
                "checkmark-outline"
              );
              if (!this.call) {
                this.loadingD = false;
                this.location.back();
              }
              this.loadingD = false;
              this.statusP.emit(true);
            },
            (err) => {
              this.loadingD = false;
              this.showToast(
                "Message",
                "An error occurred, please try again",
                "danger",
                "alert-circle-outline"
              );
            }
          );
      } else {
        this.api.updateCourse(this.courseId, this.editCourse.value).subscribe(
          (data) => {
            this.showToast(
              "Message",
              "Course Updated!",
              "success",
              "checkmark-outline"
            );
            if (!this.call) {
              this.loadingD = false;
              this.location.back();
            }
            this.loadingD = false;
            this.statusP.emit(true);
          },
          (err) => {
            this.loadingD = false;
            this.showToast(
              "Message",
              "An error occurred, please try again",
              "danger",
              "alert-circle-outline"
            );
          }
        );
      }
    }
  }

  goBack() {
    this.location.back();
  }

  dismiss(event: boolean) {
    this.statusP.emit(false);
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
