import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { DocumentsService } from "../../@core/api/base-api/documents.api";
import { Router, ActivatedRoute } from "@angular/router";
import { usersService } from "../../@core/api/base-api/users.api";
import { SchoolsService } from "../../@core/api/base-api/schools.api";
import { AuthService } from "../../@auth/auth.service";
import { SchoolService } from "../../@core/services/school.service";
import { Location } from "@angular/common";
import { NbToastrService, NbIconConfig } from "@nebular/theme";

@Component({
  selector: "app-add-form-component",
  templateUrl: "./add-document.component.html",
  styleUrls: ["./add-document.component.scss"],
})
export class AddDocumentComponent implements OnInit, OnChanges {
  @Output() status: EventEmitter<any> = new EventEmitter<any>();
  @Input() hidden: boolean;
  @Input() schoolE: string;
  @Input() call: boolean;
  @Input() sandbox: boolean;
  @Input() drive: string;
  @Input() tab: string;
  @Input() typeO: string;
  @Input() drives: any = [];
  @Input() driveSelected: string;
  addForm: FormGroup;
  public responseFile;
  selectedOption: string;
  printedOption: string;
  file: File;
  data: {};
  object: {};
  roles: any = [];
  btn1: boolean;
  btn2: boolean;
  forms: any = [];
  state: boolean;
  bucket: string;
  schoolId: string;
  school: string;
  role: any;
  value: string;
  type: boolean;
  create: boolean;
  class: string;
  sand: boolean;
  disabled: boolean;
  admin: string;
  idSchool: string;
  loading: boolean;
  constructor(
    private fb: FormBuilder,
    private api: DocumentsService,
    private router: Router,
    private auth: AuthService,
    private rolesService: usersService,
    private apiS: SchoolsService,
    private schoolS: SchoolService,
    private route: ActivatedRoute,
    private location: Location,
    private toastrService: NbToastrService
  ) {
    this.type = false;
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.type = true;
    if (this.drives && changes.drives) {
      this.auth.getUser$().subscribe((data) => {
        this.role = data["https://cor4edu.com/roles"][0];
        this.getTenant();
        this.drives = changes.drives.currentValue;
      });
      if (this.driveSelected && changes.driveSelected) {
        this.driveSelected = changes.driveSelected.currentValue;
      } else {
        this.driveSelected = "";
      }
    }
  }

  ngOnInit() {
    this.loading = false;
    this.schoolId = localStorage.getItem("urlId");
    this.school = this.route.snapshot.paramMap.get("schoolId");
    if (this.schoolId == "admin") {
      this.admin = "/admin";
    }
    this.value = this.school ? `/${this.school}` : "";
    this.rolesService
      .getUserTitleNames(this.value, this.admin)
      .subscribe((data) => {
        this.roles = data;
      });
    // this.rolesService.getRoles().subscribe((data) => {
    //   this.roles = data;
    // });
    if (!this.type) {
      this.auth.getUser$().subscribe((data) => {
        this.role = data["https://cor4edu.com/roles"][0];
        this.getTenant();
      });
    }

    this.sand = this.sandbox ? true : false;
    this.btn1 = false;
    this.btn2 = false;

    this.createForm();
    if (this.drive != "" && this.drive != undefined) {
      this.addForm.get("drive").setValue(this.drive);
      this.disabled = true;
    } else {
      this.disabled = false;
    }

    this.driveSelected =
      this.route.snapshot.paramMap.get("drive") === "null"
        ? ""
        : this.route.snapshot.paramMap.get("drive");
  }

  getTenant() {
    if (this.role == "cor-admin" || this.role == "ag-admin-us") {
      this.school = this.school ? this.school : this.schoolE;
      this.apiS.getTenant(this.school).subscribe((data) => {
        this.bucket = data["bucket"];
        this.idSchool = data["idAuth"];
        if (!this.call) {
          this.apiS.getSchool(this.idSchool).subscribe((data) => {
            this.drives = data["drives"];
          });
        }
      });
    } else {
      this.auth.getUser$().subscribe((data) => {
        this.apiS.getTenant(this.schoolId).subscribe((data) => {
          this.bucket = data["bucket"];
          this.idSchool = data["idAuth"];
          if (!this.call) {
            this.apiS.getSchool(this.idSchool).subscribe((data) => {
              this.drives = data["drives"];
            });
          }
        });
      });
    }
    const value = this.admin === "/admin" ? `${this.school}` : "";
    this.api.listFormsUser(value, this.admin).subscribe((data) => {
      this.forms = data;
    });
    this.state = false;
    const value2 = this.school ? `/${this.school}` : "";
    this.api.getStateApp(value2, this.admin).subscribe((data: any) => {
      if (data.length > 0) {
        this.state = true;
      }
    });

    this.addForm.get("drive").setValue(this.driveSelected);
  }

  get NameInvalid() {
    return (
      this.addForm.get("documentName").invalid &&
      this.addForm.get("documentName").touched
    );
  }
  get descriptionInvalid() {
    return (
      this.addForm.get("description").invalid &&
      this.addForm.get("description").touched
    );
  }
  get typeInvalid() {
    return this.addForm.get("type").invalid && this.addForm.get("type").touched;
  }

  get driveInvalid() {
    return (
      this.addForm.get("drive").invalid && this.addForm.get("drive").touched
    );
  }

  get fileInvalid() {
    return this.selectedOption == "Template" && this.file != undefined
      ? false
      : true;
  }

  get expirationInvalid() {
    return (
      this.addForm.get("expiration").invalid &&
      this.addForm.get("expiration").touched
    );
  }

  get recipientInvalid() {
    return (
      this.addForm.get("recipient").invalid &&
      this.addForm.get("recipient").touched
    );
  }

  get approvalsInvalid() {
    return (
      this.addForm.get("approvers").invalid &&
      this.addForm.get("approvers").touched
    );
  }

  createForm() {
    this.addForm = this.fb.group({
      documentName: ["", [Validators.required, Validators.minLength(3)]],
      description: ["", [Validators.required, Validators.minLength(3)]],
      type: ["", [Validators.required]],
      formId: [""],
      expiration: ["", [Validators.required]],
      recipient: ["", [Validators.required]],
      drive: ["", [Validators.required]],
      approver1: [],
      approver2: [],
      approver3: [],
      first: [false],
      sandbox: [this.sand],
    });
  }

  onFileChanged(event: any) {
    if (event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
    }
  }

  save() {
    this.loading = true;
    if (this.addForm.invalid) {
      this.loading = false;
      return Object.values(this.addForm.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }
    if (this.selectedOption == "Template" && this.fileInvalid) {
      this.loading = false;
      return false;
    }

    Object.assign(this.addForm.value, {
      approvers: [
        this.addForm.value.approver1,
        this.addForm.value.approver2,
        this.addForm.value.approver3,
      ],
    });

    const value = this.school ? `${this.school}` : "";
    if (this.selectedOption == "Template" && !this.fileInvalid) {
      this.object = {
        id: this.addForm.value.drive,
        file: this.file,
        bucket: this.bucket,
      };

      this.api.uploadFile(this.object).subscribe(
        (response) => {
          this.responseFile = response;
          if (this.responseFile.status == "ok") {
            this.data = this.addForm.value;
            this.data = Object.assign(this.data, {
              file: this.addForm.value.drive + "/" + this.responseFile.file,
            });

            this.api
              .createDocument(this.data, value, this.admin)
              .subscribe((data) => {
                this.api
                  .updateRelationshipDrives(
                    this.addForm.value.drive,
                    {
                      documents: [data["_id"]],
                    },
                    this.admin,
                    `/${value}`
                  )
                  .subscribe(
                    (res) => {
                      this.loading = false;
                      if (!this.call) {
                        this.showToast(
                          "Message",
                          "Document Saved!",
                          "success",
                          "checkmark-outline"
                        );
                        this.location.back();
                      } else {
                        this.afterCall();
                      }
                    },
                    (err) => {
                      this.loading = false;
                      this.showToast(
                        "Message",
                        "An error occurred, please try again",
                        "danger",
                        "alert-circle-outline"
                      );
                    }
                  );
              });
          }
        },
        (err) => {
          this.loading = false;
          this.showToast(
            "Message",
            "An error occurred, please try again",
            "danger",
            "alert-circle-outline"
          );
        }
      );
    } else {
      this.api.createDocument(this.addForm.value, value, this.admin).subscribe(
        (data) => {
          this.api
            .updateRelationshipDrives(
              this.addForm.value.drive,
              {
                documents: [data["_id"]],
              },
              this.admin,
              `/${value}`
            )
            .subscribe(
              (res) => {
                this.loading = false;
                if (!this.call) {
                  this.showToast(
                    "Message",
                    "Document Saved!",
                    "success",
                    "checkmark-outline"
                  );
                  this.location.back();
                } else {
                  this.afterCall();
                }
              },
              (err) => {
                this.loading = false;
                this.showToast(
                  "Message",
                  "An error occurred, please try again",
                  "danger",
                  "alert-circle-outline"
                );
              }
            );
        },
        (err) => {
          this.loading = false;
          this.showToast(
            "Message",
            "An error occurred, please try again",
            "danger",
            "alert-circle-outline"
          );
        }
      );

      /*this.api
        .createDocument(this.addForm.value, value, this.admin)
        .subscribe((data) => {
          if (!this.call) {
            this.location.back();
          } else {
            this.afterCall();
          }
        });*/
    }
  }

  afterCall() {
    this.create = true;
    this.showToast(
      "Message",
      "Document Created!",
      "success",
      "checkmark-outline"
    );
    this.class = "fade-in";
    this.status.emit(this.tab);
    this.createForm();
    setTimeout(() => {
      this.class = "fadeOut";
      this.create = false;
    }, 5000);
  }

  onChange(event) {
    this.selectedOption = event;
  }

  back() {
    this.location.back();
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
