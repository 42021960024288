import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ProfileComponent } from "./profile/profile.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { FrontendComponent } from "./frontend/frontend.component";
//import { AuthGuard } from './@auth/auth.guard';
import { UsersComponent } from "./users/users.component";
import { EditUserComponent } from "./users/edit-user/edit-user.component";
import { AddUserComponent } from "./users/add-user/add-user.component";
import { RolesComponent } from "./roles/roles.component";
import { AddRolComponent } from "./roles/add-rol/add-rol.component";
import { EditRolComponent } from "./roles/edit-rol/edit-rol.component";
import { PermissionsGuardService as PermissionsGuard } from "./@auth/permissions.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { InterceptorService } from "./@core/services/interceptor/interceptor.service";
import { DocumentsStudentsComponent } from "./documents-students/documents-students.component";
import { DocumentsComponent } from "./documents/documents.component";
import { AddDocumentComponent } from "./documents/add-document/add-document.component";
import { EditDocumentComponent } from "./documents/edit-document/edit-document.component";
import { ViewerComponent } from "./shared/viewer/viewer.component";
import { DocumentsStaffComponent } from "./documents-staff/documents-staff.component";
import { FormsComponent } from "./forms/forms.component";
import { AddFormComponent } from "./forms/add-form/add-form.component";
import { FormComponent } from "./form/form.component";
import { EditFormComponent } from "./forms/edit-form/edit-form.component";
import { SchoolsComponent } from "./schools/schools.component";
import { AddSchoolComponent } from "./schools/add-school/add-school.component";
import { EditSchoolComponent } from "./schools/edit-school/edit-school.component";
import { ProgramsComponent } from "./programs/programs.component";
import { AddProgramComponent } from "./programs/add-program/add-progam.component";
import { EditProgramComponent } from "./programs/edit-program/edit-program.component";
import { CampusComponent } from "./campus/campus.component";
import { AddCampusComponent } from "./campus/add-campus/add-campus.component";
import { EditCampusComponent } from "./campus/edit-campus/edit-campus.component";
import { CoursesComponent } from "./courses/courses.component";
import { AddCourseComponent } from "./courses/add-course/add-course.component";
import { EditCourseComponent } from "./courses/edit-course/edit-course.component";
import { LoginComponent } from "./login/login.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { AddDriveComponent } from "./documents/add-drive/add-drive.component";
import { UserTitleNamesComponent } from "./user-title-names/user-title-names.component";
import { AddUserTitleNameComponent } from "./user-title-names/add-user-title-name/add-user-title-name.component";
import { EditUserTitleNameComponent } from "./user-title-names/edit-user-title-name/edit-user-title-name.component";
import { SendingDocumentsComponent } from "./sending-documents/sending-documents.component";
import { RespondDocumentsComponent } from "./respond-documents/respond-documents.component";
import { RequestsSentComponent } from "./requests-sent/requests-sent.component";
import { RequestsReceivedComponent } from "./requests-received/requests-received.component";
import { EditSendingDocumentComponent } from "./edit-sending-document/edit-sending-document.component";

const routes: Routes = [
  {
    path: "",
    component: FrontendComponent,
    pathMatch: "full",
  },
  {
    path: ":id",
    component: DashboardComponent,
  },
  {
    path: ":id/login",
    component: LoginComponent,
  },
  {
    path: ":id/sign-up",
    component: SignUpComponent,
  },
  {
    path: ":id/reset-password",
    component: ResetPasswordComponent,
  },
  {
    path: ":id/profile",
    component: ProfileComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:profile",
    },
  },
  {
    path: ":id/users",
    component: UsersComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:users",
    },
  },
  {
    path: ":id/users/:schoolId",
    component: UsersComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:users",
    },
  },
  {
    path: ":id/edit-user/:userId",
    component: EditUserComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "update:users",
    },
  },
  {
    path: ":id/edit-user/:userId/:schoolId",
    component: EditUserComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "update:users",
    },
  },
  {
    path: ":id/add-user",
    component: AddUserComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "create:users",
    },
  },
  {
    path: ":id/add-user/:schoolId",
    component: AddUserComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "create:users",
    },
  },
  {
    path: ":id/roles",
    component: RolesComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "menu:roles",
    },
  },
  {
    path: ":id/add-rol",
    component: AddRolComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "create:roles",
    },
  },
  {
    path: ":id/edit-rol/:id",
    component: EditRolComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "update:roles",
    },
  },
  {
    path: ":id/documents-student",
    component: DocumentsStudentsComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:documents-students",
    },
  },
  {
    path: ":id/documents-student/:studentId",
    component: DocumentsStudentsComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:documents-students",
    },
  },
  {
    path: ":id/documents",
    component: DocumentsComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:documents",
    },
  },
  {
    path: ":id/documents-school/:schoolId",
    component: DocumentsComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:documents",
    },
  },
  {
    path: ":id/documents/:drive",
    component: DocumentsComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:documents",
    },
  },
  {
    path: ":id/documents/:schoolId/:drive",
    component: DocumentsComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:documents",
    },
  },
  {
    path: ":id/add-document/:schoolId",
    component: AddDocumentComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "create:documents",
    },
  },
  {
    path: ":id/add-document",
    component: AddDocumentComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "create:documents",
    },
  },
  {
    path: ":id/edit-document/:document",
    component: EditDocumentComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "update:documents",
    },
  },
  {
    path: ":id/edit-document/:document/:schoolId",
    component: EditDocumentComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "update:documents",
    },
  },
  {
    path: ":id/documents-staff",
    component: DocumentsStaffComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "upload:documents-staff",
    },
  },
  {
    path: ":id/documents-staff/:id",
    component: DocumentsStaffComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "upload:documents-staff",
    },
  },
  {
    path: ":id/forms",
    component: FormsComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:forms",
    },
  },
  {
    path: ":id/forms/:id",
    component: FormsComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:forms",
    },
  },
  {
    path: ":schoolId/form/:id",
    component: FormComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "upload:documents-student",
    },
  },
  {
    path: ":id/add-form",
    component: AddFormComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "create:forms",
    },
  },
  {
    path: ":id/add-form/:id",
    component: AddFormComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "create:forms",
    },
  },
  {
    path: ":id/edit-form/:id",
    component: EditFormComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "update:forms",
    },
  },
  {
    path: ":id/edit-form/:id/:schoolId",
    component: EditFormComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "update:forms",
    },
  },
  {
    path: ":id/viewer/:document",
    component: ViewerComponent,
    //canActivate: [PermissionsGuard]
    // data: {
    //   expectedPermission: "update:documents-students"
    // }
  },
  {
    path: ":id/viewer/:document/:schoolId",
    component: ViewerComponent,
    //canActivate: [PermissionsGuard]
    // data: {
    //   expectedPermission: "update:documents-students"
    // }
  },
  {
    path: ":id/schools",
    component: SchoolsComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:schools",
    },
  },
  {
    path: ":id/add-school",
    component: AddSchoolComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "create:schools",
    },
  },
  {
    path: ":id/edit-school/:idSchool/:schoolId",
    component: EditSchoolComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "update:schools",
    },
  },
  {
    path: ":id/campus",
    component: CampusComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:campus",
    },
  },
  {
    path: ":id/campus-student",
    component: CampusComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:campus-student",
    },
  },
  {
    path: ":id/campus/:schoolId",
    component: CampusComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:campus",
    },
  },
  {
    path: ":id/add-campus",
    component: AddCampusComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "create:campus",
    },
  },
  {
    path: ":id/add-campus/:schoolId",
    component: AddCampusComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "create:campus",
    },
  },
  {
    path: ":id/edit-campus/:id",
    component: EditCampusComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "update:campus",
    },
  },
  {
    path: ":id/view-campus/:id",
    component: EditCampusComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:campus-student",
    },
  },
  {
    path: ":id/edit-campus/:id/:schoolId",
    component: EditCampusComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "update:campus",
    },
  },
  {
    path: ":id/programs",
    component: ProgramsComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:programs",
    },
  },
  {
    path: ":id/programs-student",
    component: ProgramsComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:programs-student",
    },
  },
  {
    path: ":id/programs/:schoolId",
    component: ProgramsComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:programs",
    },
  },
  {
    path: ":id/add-program",
    component: AddProgramComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "create:programs",
    },
  },
  {
    path: ":id/add-program/:schoolId",
    component: AddProgramComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "create:programs",
    },
  },
  {
    path: ":id/edit-program/:id",
    component: EditProgramComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "update:programs",
    },
  },
  {
    path: ":id/view-program/:id",
    component: EditProgramComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:programs-student",
    },
  },
  {
    path: ":id/edit-program/:id/:schoolId",
    component: EditProgramComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "update:programs",
    },
  },
  {
    path: ":id/courses",
    component: CoursesComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:courses",
    },
  },
  {
    path: ":id/courses-student",
    component: CoursesComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:courses-student",
    },
  },
  {
    path: ":id/courses/:schoolId",
    component: CoursesComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:courses",
    },
  },
  {
    path: ":id/add-course",
    component: AddCourseComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "create:course",
    },
  },
  {
    path: ":id/add-course/:schoolId",
    component: AddCourseComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "create:course",
    },
  },
  {
    path: ":id/edit-course/:id",
    component: EditCourseComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "update:course",
    },
  },
  {
    path: ":id/view-course/:id",
    component: EditCourseComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:courses-student",
    },
  },
  {
    path: ":id/edit-course/:id/:schoolId",
    component: EditCourseComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "update:course",
    },
  },
  {
    path: ":id/add-drive",
    component: AddDriveComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:documents",
    },
  },
  {
    path: ":id/add-drive/:schoolId",
    component: AddDriveComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:documents",
    },
  },
  {
    path: ":id/user-title-names",
    component: UserTitleNamesComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:user-title-names",
    },
  },
  {
    path: ":schoolId/add-user-title-names",
    component: AddUserTitleNameComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "add:user-title-names",
    },
  },
  {
    path: ":schoolId/edit-user-title-name/:id",
    component: EditUserTitleNameComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "update:user-title-names",
    },
  },
  {
    path: ":id/sending-documents",
    component: SendingDocumentsComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "create:request",
    },
  },
  {
    path: ":id/sending-documents/:schoolId",
    component: SendingDocumentsComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "create:request",
    },
  },

  {
    path: ":id/edit-sending-documents",
    component: EditSendingDocumentComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:requests-sent",
    },
  },
  {
    path: ":id/edit-sending-documents/:idRequest",
    component: EditSendingDocumentComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:requests-sent",
    },
  },

  {
    path: ":id/respond-documents",
    component: RespondDocumentsComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:requests-received",
    },
  },
  {
    path: ":id/respond-documents/:idRequest",
    component: RespondDocumentsComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:requests-received",
    },
  },

  {
    path: ":id/requests-sent",
    component: RequestsSentComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:requests-sent",
    },
  },
  {
    path: ":id/requests-sent/:schoolId",
    component: RequestsSentComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:requests-sent",
    },
  },

  {
    path: ":id/requests-received",
    component: RequestsReceivedComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:requests-received",
    },
  },
  {
    path: ":id/requests-received/:schoolId",
    component: RequestsReceivedComponent,
    canActivate: [PermissionsGuard],
    data: {
      expectedPermission: "read:requests-received",
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
})
export class AppRoutingModule {}
