import { Component, OnInit, ViewChild } from "@angular/core";
import { DocumentsService } from "../@core/api/base-api/documents.api";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../@auth/auth.service";
import { SchoolsService } from "../@core/api/base-api/schools.api";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from "@angular/forms";
import { Location } from "@angular/common";
import { ReplaySubject, Subject } from "rxjs";
import { MatSelect } from "@angular/material/select";
import { take, takeUntil } from "rxjs/operators";
import { NbIconConfig, NbToastrService } from "@nebular/theme";

@Component({
  selector: "app-sending-documents",
  templateUrl: "./sending-documents.component.html",
  styleUrls: ["./sending-documents.component.scss"],
})
export class SendingDocumentsComponent implements OnInit {
  public filteredBanks: ReplaySubject<any> = new ReplaySubject<any>(1);
  @ViewChild("singleSelect", { static: true }) singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  // public userId: FormControl = new FormControl();
  public bankFilterCtrl: FormControl = new FormControl();
  addForm: FormGroup;
  school: string;
  schoolId: string;
  admin: string;
  drives: any = [];
  documents: any = [];
  components: any = [];
  sendingSchool: boolean;
  sendingCampus: boolean;
  sendingPrograms: boolean;
  sendingCourses: boolean;
  idSchool: string;
  documentSelect: boolean;
  optionSelected: number;
  receiveOption: boolean;
  approvalOption: boolean;
  instructionsOption: boolean;
  users: any = [];
  radioOption: number;
  sendingUserId: string;
  object: {};
  idAuth: string;
  bucket: string;
  selectedUser: any;
  loading: boolean;
  sAdmin: boolean;
  schools: any = [];
  componentSelected: string;
  schoolIdOperate: string;
  constructor(
    private api: DocumentsService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private apiS: SchoolsService,
    private formBuilder: FormBuilder,
    private location: Location,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.loading = false;
    this.school = this.route.snapshot.paramMap.get("schoolId");
    this.schoolId = localStorage.getItem("urlId");
    const value = this.school ? `${this.school}` : "";
    this.drives = false;

    this.components = false;
    if (this.schoolId == "admin") {
      this.admin = "/admin";
    }
    this.auth.getUser$().subscribe((data) => {
      const conditions = ["ag-admin-us", "cor-admin"];
      this.sAdmin = conditions.some((el) =>
        data["https://cor4edu.com/roles"].includes(el)
      );
      this.sendingUserId = data["sub"];
      if (!this.sAdmin) {
        this.apiS.getTenant(this.schoolId).subscribe((data) => {
          this.bucket = data["bucket"];
          this.idAuth = data["idAuth"];
        });
      } else {
        this.apiS.listSchools().subscribe((data) => {
          this.schools = data;
        });
      }
    });
    this.sendingSchool = this.auth.permissions.includes(
      "sending:documents-school"
    );
    this.sendingCampus = this.auth.permissions.includes(
      "sending:documents-campus"
    );
    this.sendingPrograms = this.auth.permissions.includes(
      "sending:documents-programs"
    );
    this.sendingCourses = this.auth.permissions.includes(
      "sending:documents-courses"
    );

    this.form();
  }

  form() {
    this.addForm = this.formBuilder.group({
      inputResponse: [false],
      documentId: ["", Validators.required],
      receive: [false],
      approval: [false],
      instructionsI: this.formBuilder.array([this.initLink()]),
      uploadDoc: [false],
      expirationDoc: [false],
      userEntersRenewDate: [false],
      respond: this.formBuilder.group({
        userId: [""],
      }),
      dateRequest: [""],
      component: [""],
    });
  }

  initLink() {
    return this.formBuilder.group({
      instructionsI: ["", Validators.required],
    });
  }
  addLink() {
    const control = <FormArray>this.addForm.controls["instructionsI"];
    control.push(this.initLink());
  }
  removeLink(i: number) {
    const control = <FormArray>this.addForm.controls["instructionsI"];
    control.removeAt(i);
  }
  reset() {
    this.documentSelect = false;
    this.drives = false;
    this.addForm.get("receive").setValue(false);
    this.addForm.get("approval").setValue(false);
    this.addForm.get("inputResponse").setValue(false);
    this.addForm.get("uploadDoc").setValue(false);
    this.addForm.get("expirationDoc").setValue(false);
    this.addForm.get("userEntersRenewDate").setValue(false);
    this.addForm.get("respond.userId").setValue("");
  }
  schoolSelect(data) {
    this.idAuth = data["idAuth"];
    this.schoolIdOperate = data["urlId"];
    this.reset();
    this.addForm.reset();
    this.bucket = data["bucket"];
  }
  component(data) {
    const value = this.admin === "/admin" ? this.school : "";
    this.reset();
    if (data === "school") {
      this.components = false;
      this.apiS.getSchool(this.idAuth).subscribe((res) => {
        this.drives = res["drives"];
        this.users = res["students"];
        this.filteredBanks.next(this.users.slice());

        // listen for search field value changes
        this.bankFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterBanks();
          });
      });
    } else if (data === "campus") {
      this.addForm.reset();
      this.drives = false;
      this.apiS.listCampus(value, this.admin).subscribe((data) => {
        this.components = data;
        this.users = data["students"];
      });
    }
  }

  protected setInitialValue() {
    this.filteredBanks
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (a, b) => a && b && a.id === b.id;
      });
  }

  protected filterBanks() {
    if (!this.users) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.users.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.users.filter(
        (bank) => bank.firstName.toLowerCase().indexOf(search) > -1
      )
    );
  }

  componentOperate(data) {
    this.drives = data["drives"];
  }

  to(val) {
    this.radioOption = val;
  }

  drive(id) {
    const value = this.admin === "/admin" ? `/${this.schoolIdOperate}` : "";
    this.api
      .getListDocumentsByDrive(encodeURIComponent(id), value, this.admin)
      .subscribe((res) => {
        this.documents = res["documents"];
      });
  }
  document(id) {
    this.documentSelect = id;
  }

  option(val) {
    this.optionSelected = val;
  }

  receive(val) {
    this.receiveOption = val;
  }

  approval(val) {
    this.approvalOption = val;
  }
  instructions(val) {
    this.instructionsOption = val;
  }
  user(user) {
    this.selectedUser = user;
  }
  save() {
    this.loading = true;
    this.object = {
      file: this.documentSelect["file"],
      bucket: this.bucket,
      newLocation: this.selectedUser.drives[0],
    };

    if (this.documentSelect["file"] != undefined) {
      this.api.copyFile(this.object).subscribe(
        (response) => {
          this.documentSelect = Object.assign(this.documentSelect, {
            file: this.selectedUser.drives[0] + "/" + response["data"],
            drive: this.selectedUser.drives[0],
          });
          delete this.documentSelect["_id"];
          const value =
            this.admin === "/admin" ? `${this.schoolIdOperate}` : "";
          this.api
            .createDocument(this.documentSelect, value, this.admin)
            .subscribe(
              (data) => {
                const value =
                  this.admin === "/admin" ? `/${this.schoolIdOperate}` : "";
                this.api
                  .updateRelationshipDrives(
                    this.selectedUser.drives[0],
                    {
                      documents: [data["_id"]],
                    },
                    this.admin,
                    value
                  )
                  .subscribe(
                    (res) => {
                      const newData = Object.assign(this.addForm.value, {
                        sendingUserId: this.sendingUserId,
                        dateRequest: new Date(),
                        documentId: data["_id"],
                        approval: this.approvalOption,
                        respond: { userId: this.selectedUser["_id"] },
                      });
                      this.api
                        .createRequest(newData, value, this.admin)
                        .subscribe(
                          (res) => {
                            this.loading = false;
                            this.showToast(
                              "Message",
                              "Request Sent!",
                              "success",
                              "checkmark-outline"
                            );
                            this.location.back();
                          },
                          (err) => {
                            this.loading = false;
                            this.showToast(
                              "Message",
                              "An error occurred, please try again",
                              "danger",
                              "alert-circle-outline"
                            );
                          }
                        );
                    },
                    (err) => {
                      this.loading = false;
                      this.showToast(
                        "Message",
                        "An error occurred, please try again",
                        "danger",
                        "alert-circle-outline"
                      );
                    }
                  );
              },
              (err) => {
                this.loading = false;
                this.showToast(
                  "Message",
                  "An error occurred, please try again",
                  "danger",
                  "alert-circle-outline"
                );
              }
            );
        },
        (err) => {
          this.loading = false;
          this.showToast(
            "Message",
            "An error occurred, please try again",
            "danger",
            "alert-circle-outline"
          );
        }
      );
    } else if (this.documentSelect["formId"] != undefined) {
      const value = this.admin === "/admin" ? `${this.schoolIdOperate}` : "";
      delete this.documentSelect["_id"];
      this.api.createDocument(this.documentSelect, value, this.admin).subscribe(
        (data) => {
          const value =
            this.admin === "/admin" ? `/${this.schoolIdOperate}` : "";
          this.api
            .updateRelationshipDrives(
              this.selectedUser.drives[0],
              {
                documents: [data["_id"]],
              },
              this.admin,
              value
            )
            .subscribe(
              (res) => {
                const newData = Object.assign(this.addForm.value, {
                  sendingUserId: this.sendingUserId,
                  dateRequest: new Date(),
                  documentId: data["_id"],
                  approval: this.approvalOption,
                  respond: { userId: this.selectedUser["_id"] },
                });
                this.api.createRequest(newData, value, this.admin).subscribe(
                  (res) => {
                    this.loading = false;
                    this.showToast(
                      "Message",
                      "Request Sent!",
                      "success",
                      "checkmark-outline"
                    );
                    this.location.back();
                  },
                  (err) => {
                    this.loading = false;
                    this.showToast(
                      "Message",
                      "An error occurred, please try again",
                      "danger",
                      "alert-circle-outline"
                    );
                  }
                );
              },
              (err) => {
                this.loading = false;
                this.showToast(
                  "Message",
                  "An error occurred, please try again",
                  "danger",
                  "alert-circle-outline"
                );
              }
            );
        },
        (err) => {
          this.loading = false;
          this.showToast(
            "Message",
            "An error occurred, please try again",
            "danger",
            "alert-circle-outline"
          );
        }
      );
    }
  }

  goBack() {
    this.location.back();
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
