import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { usersService } from "../../@core/api/base-api/users.api";
import { Location } from "@angular/common";
import { NbIconConfig, NbToastrService } from "@nebular/theme";

@Component({
  selector: "app-add-user-title-name",
  templateUrl: "./add-user-title-name.component.html",
  styleUrls: ["./add-user-title-name.component.scss"],
})
export class AddUserTitleNameComponent implements OnInit {
  addForm: FormGroup;
  roles: any;
  loading: boolean;
  constructor(
    private fb: FormBuilder,
    private apiU: usersService,
    private location: Location,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.loading = false;
    this.apiU.getRoles().subscribe((res) => {
      this.roles = res;
    });
    this.createForm();
  }

  createForm() {
    this.addForm = this.fb.group({
      name: ["", [Validators.required, Validators.minLength(3)]],
      roles: [[], [Validators.required]],
    });
  }

  goBack() {
    this.location.back();
  }

  get nameInvalid() {
    return this.addForm.get("name").invalid && this.addForm.get("name").touched;
  }

  get rolesInvalid() {
    return (
      this.addForm.get("roles").invalid && this.addForm.get("roles").touched
    );
  }

  save() {
    this.loading = true;
    if (this.addForm.invalid) {
      this.loading = false;
      return Object.values(this.addForm.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }

    this.apiU.createUserTitleNames(this.addForm.value).subscribe(
      (data) => {
        this.loading = false;
        this.showToast(
          "Message",
          "User Title Name Saved!",
          "success",
          "checkmark-outline"
        );
        this.location.back();
      },
      (err) => {
        this.loading = false;
        this.showToast(
          "Message",
          "An error occurred, please try again",
          "danger",
          "alert-circle-outline"
        );
      }
    );
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
