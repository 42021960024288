import { Component, OnInit, Input } from "@angular/core";
import { NbDialogRef, NbDialogService } from "@nebular/theme";

@Component({
  selector: "app-response-upload",
  templateUrl: "./response-upload.component.html",
  styleUrls: ["./response-upload.component.scss"],
})
export class ResponseUploadComponent implements OnInit {
  file: File;
  fileName: string;
  @Input() drives: any = [];
  @Input() data: any = [];
  idDrive: string;
  constructor(protected dialogRef: NbDialogRef<ResponseUploadComponent>) {}

  ngOnInit() {}

  onFileChanged(event: any) {
    if (event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
      this.fileName = this.file.name;
    }
  }

  dismiss(file) {
    this.dialogRef.close([this.file, this.idDrive, this.data]);
  }

  change(id) {
    this.idDrive = id;
  }
}
