import { SplashComponent } from './components/splash/splash.component';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbCardModule,
  NbSpinnerModule,
  NbCheckboxModule
} from '@nebular/theme';

import { NbEvaIconsModule } from '@nebular/eva-icons';

import { COREDU_THEME } from './styles/theme.coredu';

import {
  FooterComponent,
  HeaderComponent,
  NavBarComponent
} from './components';

import {
  MainLayoutComponent
} from './layouts/main-layout/main.layout';

const NB_MODULES = [
  NbCardModule,
  NbSpinnerModule,
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbCheckboxModule
];

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  MainLayoutComponent,
  NavBarComponent,
  SplashComponent
];

@NgModule({
  imports: [CommonModule, ...NB_MODULES],
  exports: [CommonModule, ...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'coredu',
          },
          [COREDU_THEME],
        ).providers,
      ],
    } as ModuleWithProviders;
  }
}
