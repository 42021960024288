import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  NbSidebarModule,
  NbMenuModule,
  NbCardModule,
  NbListModule,
  NbUserModule,
  NbWindowModule,
  NbIconModule,
  NbCheckboxModule,
  NbSelectModule,
  NbDatepickerModule,
  NbInputModule,
  NbButtonModule,
  NbToggleModule,
  NbDialogModule,
  NbSpinnerModule,
  NbAccordionModule,
  NbStepperModule,
  NbRadioModule,
  NbToastrModule,
  NbTooltipModule,
} from "@nebular/theme";
import { ProfileComponent } from "./profile/profile.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { FrontendComponent } from "./frontend/frontend.component";
import { AppMenu } from "./app-menu";
import { HttpClientModule } from "@angular/common/http";
import { UsersComponent } from "./users/users.component";
import { EditUserComponent } from "./users/edit-user/edit-user.component";
import { AddUserComponent } from "./users/add-user/add-user.component";
import { RolesComponent } from "./roles/roles.component";
import { AddRolComponent } from "./roles/add-rol/add-rol.component";
import { EditRolComponent } from "./roles/edit-rol/edit-rol.component";
import { PermissionsGuardService as PermissionsGuard } from "./@auth/permissions.service";
import { ThemeModule } from "./@theme/theme.module";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { NgxMaskModule } from "ngx-mask";
import { DocumentsStudentsComponent } from "./documents-students/documents-students.component";
import { DocumentsComponent } from "./documents/documents.component";
import { AddDocumentComponent } from "./documents/add-document/add-document.component";
import { EditDocumentComponent } from "./documents/edit-document/edit-document.component";
import { ViewerComponent } from "./shared/viewer/viewer.component";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { DocumentsStaffComponent } from "./documents-staff/documents-staff.component";
import { FormsComponent } from "./forms/forms.component";
import { AddFormComponent } from "./forms/add-form/add-form.component";
import { DndModule } from "ngx-drag-drop";
import { FormComponent } from "./form/form.component";
import { EditFormComponent } from "./forms/edit-form/edit-form.component";
import { SchoolsComponent } from "./schools/schools.component";
import { AddSchoolComponent } from "./schools/add-school/add-school.component";
import { EditSchoolComponent } from "./schools/edit-school/edit-school.component";
import { ProgramsComponent } from "./programs/programs.component";
import { AddProgramComponent } from "./programs/add-program/add-progam.component";
import { CampusComponent } from "./campus/campus.component";
import { AddCampusComponent } from "./campus/add-campus/add-campus.component";
import { EditCampusComponent } from "./campus/edit-campus/edit-campus.component";
import { EditProgramComponent } from "./programs/edit-program/edit-program.component";
import { CoursesComponent } from "./courses/courses.component";
import { AddCourseComponent } from "./courses/add-course/add-course.component";
import { EditCourseComponent } from "./courses/edit-course/edit-course.component";
import { LoginComponent } from "./login/login.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ShowcaseDialogComponent } from "./shared/showcase-dialog/showcase-dialog.component";
import { SchoolService } from "./@core/services/school.service";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { AddDriveComponent } from "./documents/add-drive/add-drive.component";
import { EditDriveComponent } from "./documents/edit-drive/edit-drive.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { WindowFormEditDocumentComponent } from "./schools/window-form-edit-document/window-form-edit-document";
import { TableDocumentsComponent } from "./shared/table-dynamic/table-dynamic.component";
import { viewDocumentComponent } from "./documents/view-document/view-document";
import { TableProgramsComponent } from "./shared/table-programs/table-programs.component";
import { WindowFormEditProgramComponent } from "./shared/table-programs/window-form-edit-program/window-form-edit-program";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { UserTitleNamesComponent } from "./user-title-names/user-title-names.component";
import { WindowDeleteConfirmComponent } from "./shared/window-delete-confirm/window-delete-confirm.component";
import { AddUserTitleNameComponent } from "./user-title-names/add-user-title-name/add-user-title-name.component";
import { EditUserTitleNameComponent } from "./user-title-names/edit-user-title-name/edit-user-title-name.component";
import { SendingDocumentsComponent } from "./sending-documents/sending-documents.component";
import { WindowCopyDocumentComponent } from "./shared/window-copy-document/window-copy-document.component";
import { RespondDocumentsComponent } from "./respond-documents/respond-documents.component";
import { ResponseUploadComponent } from "./respond-documents/response-upload/response-upload.component";
import { RequestsSentComponent } from "./requests-sent/requests-sent.component";
import { RequestsReceivedComponent } from "./requests-received/requests-received.component";
import { EditSendingDocumentComponent } from "./edit-sending-document/edit-sending-document.component";
import { ViewInstructionsComponent } from "./respond-documents/view-instructions/view-instructions.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MatSelectModule } from "@angular/material/select";
import { MatPaginatorModule } from "@angular/material/paginator";

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    DashboardComponent,
    FrontendComponent,
    UsersComponent,
    EditUserComponent,
    AddUserComponent,
    RolesComponent,
    AddRolComponent,
    EditRolComponent,
    DocumentsStudentsComponent,
    DocumentsComponent,
    AddDocumentComponent,
    EditDocumentComponent,
    ViewerComponent,
    DocumentsStaffComponent,
    FormsComponent,
    AddFormComponent,
    FormComponent,
    EditFormComponent,
    SchoolsComponent,
    AddSchoolComponent,
    EditSchoolComponent,
    ProgramsComponent,
    AddProgramComponent,
    EditProgramComponent,
    CampusComponent,
    AddCampusComponent,
    EditCampusComponent,
    CoursesComponent,
    AddCourseComponent,
    EditCourseComponent,
    LoginComponent,
    SignUpComponent,
    ResetPasswordComponent,
    ShowcaseDialogComponent,
    AddDriveComponent,
    EditDriveComponent,
    WindowFormEditDocumentComponent,
    WindowFormEditProgramComponent,
    TableDocumentsComponent,
    viewDocumentComponent,
    TableProgramsComponent,
    UserTitleNamesComponent,
    WindowDeleteConfirmComponent,
    AddUserTitleNameComponent,
    EditUserTitleNameComponent,
    SendingDocumentsComponent,
    WindowCopyDocumentComponent,
    RespondDocumentsComponent,
    ResponseUploadComponent,
    RequestsSentComponent,
    RequestsReceivedComponent,
    EditSendingDocumentComponent,
    ViewInstructionsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NbSelectModule,
    DndModule,
    NbToggleModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatCheckboxModule,
    NbListModule,
    NbCardModule,
    NbSpinnerModule,
    NbUserModule,
    NbEvaIconsModule,
    NbIconModule,
    NbCheckboxModule,
    NbInputModule,
    NbStepperModule,
    NbRadioModule,
    NbButtonModule,
    NgxDocViewerModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    NbToastrModule.forRoot(),
    ThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbWindowModule.forChild(),
    NbDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(),
    NbDialogModule.forChild(),
    NbAccordionModule,
    MatPaginatorModule,
    NbTooltipModule,
  ],
  entryComponents: [
    ShowcaseDialogComponent,
    WindowFormEditDocumentComponent,
    viewDocumentComponent,
    WindowFormEditProgramComponent,
    WindowDeleteConfirmComponent,
    WindowCopyDocumentComponent,
    ResponseUploadComponent,
    ViewInstructionsComponent,
  ],
  providers: [AppMenu, PermissionsGuard, SchoolService],
  bootstrap: [AppComponent],
})
export class AppModule {}
