import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { AuthService } from "./auth.service";
import { Observable, of, combineLatest } from "rxjs";
import { switchMap } from "rxjs/operators";
// import decode from 'jwt-decode';
@Injectable()
export class PermissionsGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> {
    const expectedPermission = route.data.expectedPermission;
    return combineLatest(
      this.auth.isAuthenticated$,
      this.auth.permissions$
    ).pipe(
      switchMap(([loggedIn, permissions]) => {
        return of(loggedIn && permissions.includes(expectedPermission));
      })
    );
  }
}
