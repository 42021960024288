import { Component, OnInit, Input } from "@angular/core";
import { NbDialogRef, NbDialogService } from "@nebular/theme";

@Component({
  selector: "app-view-instructions",
  templateUrl: "./view-instructions.component.html",
  styleUrls: ["./view-instructions.component.scss"],
})
export class ViewInstructionsComponent implements OnInit {
  @Input() instructions: any;
  constructor(protected dialogRef: NbDialogRef<ViewInstructionsComponent>) {}

  ngOnInit() {}

  dismiss(file) {
    this.dialogRef.close(false);
  }
}
