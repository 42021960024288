import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Level } from "../../@core/model/school.model";
import { SchoolsService } from "../../@core/api/base-api/schools.api";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../@auth/auth.service";
import { Location } from "@angular/common";
import { MatTableDataSource } from "@angular/material/table";
import { PeriodicElement } from "../../schools/edit-school/edit-school.component";
import { DocumentsService } from "../../@core/api/base-api/documents.api";
import { NbToastrService, NbIconConfig } from "@nebular/theme";

@Component({
  selector: "app-edit-program",
  templateUrl: "./edit-progam.component.html",
  styleUrls: ["./edit-program.component.scss"],
})
export class EditProgramComponent implements OnInit, OnChanges {
  @Input() call: boolean;
  @Input() idE: string;
  @Input() schoolE: string;
  @Input() typeP: string;
  @Output() statusP: EventEmitter<any> = new EventEmitter<any>();
  editProgram: FormGroup;
  Level = new Level();
  programId: string;
  form: boolean;
  id: string;
  role: string;
  courses: any = [];
  schoolId: string;
  accountNumber: number;
  loading: boolean;
  loading2: boolean;
  dataO1: any;
  data1: any;
  dataO2: any;
  data2: any;
  dataO3: any;
  data3: any;
  state: boolean;
  state1: boolean;
  state3: boolean;
  columns: any;
  columns2: any;
  columns3: any;
  columnsDrives: any;
  columnsCourses: any;
  columnsStudents: any;
  title1: string;
  title2: string;
  title3: string;
  title4: string;
  title5: string;
  bucket: string;
  driveS: string;
  tab: number;
  admin: string;
  school: string;
  roles: any = [];
  driveSelected: string;
  cDate: any;
  cView: boolean;
  printv: boolean;
  columnsPrograms: any;
  drives: any = [];
  loadingD: boolean;
  responseFile: any;
  path: string;
  schoolImg: string;
  viewLogo: boolean;
  imgInvalid: boolean;
  infoImgInvalid: string;
  file: File;
  fileName: string;
  object: {};
  logo: string;
  permiStudent: boolean;
  constructor(
    private fb: FormBuilder,
    private api: SchoolsService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private location: Location,
    private apiD: DocumentsService,
    private toastrService: NbToastrService
  ) {
    this.auth.getUser$().subscribe((data) => {
      this.id = data["sub"];
      this.role = data["https://cor4edu.com/roles"][0];
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.idE && changes.idE) {
      if (changes.idE.currentValue != changes.idE.previousValue) {
        this.auth.getUser$().subscribe((data) => {
          this.programId = changes.idE.currentValue;
          this.schoolId = changes.schoolE.currentValue;
          this.getData();
        });
      }
    }
  }

  ngOnInit() {
    this.loadingD = true;
    this.cView = true;
    this.schoolId = this.route.snapshot.paramMap.get("schoolId");
    this.programId = this.route.snapshot.paramMap.get("id");
    if (!this.programId && !this.call) {
      this.router.navigate(["programs"]);
      return;
    }
    this.school = localStorage.getItem("urlId");
    this.form = false;

    this.createForm();
    if (this.school == "admin") {
      this.admin = "/admin";
    }

    if (this.typeP === "view") {
      this.editProgram.disable();
    }
    this.form = true;
    if (!this.call) {
      const value = this.school === "admin" ? this.schoolId : this.school;
      this.api.getTenant(value).subscribe((data) => {
        if (data) {
          this.bucket = data["bucket"];
        }
      });
      this.auth.permissions$.subscribe((res) => {
        this.permiStudent = res.includes("read:programs-student");
        this.getData();
      });
    }
    this.columns = ["select", "campusName", "options"];
    this.columnsCourses = [
      "select",
      "courseName",
      "totalClockhours",
      "courseTypes",
      "totalCredits",
      "mainCourseInstructorName",
      "options",
    ];
    this.columnsStudents = [
      "select",
      "userName",
      "email",
      "systemStatus",
      "cellPhone",
      "options",
    ];
    this.columnsDrives = ["select", "name", "options"];
    this.title1 = "Campus";
    this.title2 = "Courses";
    this.title3 = "Drives";
    this.title4 = "Students";
  }

  getData() {
    if (this.permiStudent) {
      this.api.getProgram(this.programId, this.admin).subscribe((data) => {
        this.setData(data);
      });
    } else {
      if (this.role == "cor-admin" || this.role == "ag-admin-us") {
        this.api
          .listCourses(`/${this.schoolId}`, this.admin)
          .subscribe((data) => {
            this.courses = data;
          });

        this.api
          .getProgram(this.programId, `/${this.schoolId}`, this.admin)
          .subscribe((data) => {
            this.setData(data);
          });
      } else {
        this.api.listCourses().subscribe((data) => {
          this.courses = data;
        });
        this.api.getProgram(this.programId, this.admin).subscribe((data) => {
          this.setData(data);
        });
      }
    }
  }

  print() {
    window.print();
    this.printv = false;
    this.dismiss(false);
  }

  public nameDrive(val: any): void {
    if (!this.isEmpty(val)) {
      if (val.name1 != undefined) {
        this.tab = 1;
        this.drive(val.name1);
      } else if (val.name2 != undefined) {
        this.tab = 2;
        this.drive(val.name2);
      }
    }
  }

  public tableR(name: any): void {
    this.getData();
  }

  public refreshR(data: any): void {
    const value = this.admin === "/admin" ? `/${this.schoolId}` : "";
    this.columnsDrives = ["select", "name", "options"];
    this.api
      .updateRelationshipPrograms(
        this.programId,
        { [data[1]]: [data[0]] },
        this.admin,
        value
      )
      .subscribe((data) => {
        this.getData();
      });
  }

  refresh = function (data) {
    this.loading = true;
    this.dataO1 = data;
    this.data1 = new MatTableDataSource<PeriodicElement>(this.dataO1);
    this.state = false;
    this.loading = false;
  };

  refresh1 = function (data) {
    this.loading2 = true;
    this.dataO2 = data;
    this.data2 = new MatTableDataSource<PeriodicElement>(this.dataO2);
    this.state1 = false;
    this.loading2 = false;
  };

  refresh2 = function (data) {
    this.loading3 = true;
    this.dataO3 = data;
    this.data3 = new MatTableDataSource<PeriodicElement>(this.dataO2);
    this.state3 = false;
    this.loading3 = false;
  };

  refresh3 = function (data) {
    this.loading4 = true;
    this.dataO4 = data;
    this.data4 = new MatTableDataSource<PeriodicElement>(this.dataO2);
    this.state4 = false;
    this.loading4 = false;
  };

  setData(data) {
    this.cDate = data;
    this.refresh(data["campus"]);
    this.refresh1(data["courses"]);
    this.drives = data["drives"];
    this.refresh2(this.drives);
    this.refresh3(data["students"]);
    this.editProgram.get("credentialIssued").setValue(data["credentialIssued"]);
    this.editProgram.get("programTitle").setValue(data["programTitle"]);
    this.editProgram
      .get("directorEducationName")
      .setValue(data["directorEducationName"]);
    this.editProgram
      .get("advisoryBoardMandated")
      .setValue(data["advisoryBoardMandated"]);
    this.editProgram
      .get("clinicalExternshipHours")
      .setValue(data["clinicalExternshipHours"]);
    this.editProgram.get("lectureHours").setValue(data["lectureHours"]);
    this.editProgram.get("labHours").setValue(data["labHours"]);
    this.editProgram.get("programLength").setValue(data["programLength"]);
    this.editProgram
      .get("programDeliveryMethod")
      .setValue(data["programDeliveryMethod"]);
    this.editProgram
      .get("programLengthType")
      .setValue(data["programLengthType"]);
    this.editProgram
      .get("totalAmountWeeksRequiredtoFinishProgram")
      .setValue(data["totalAmountWeeksRequiredtoFinishProgram"]);
    this.editProgram
      .get("RequireGEDHSDiploma")
      .setValue(data["RequireGEDHSDiploma"]);
    this.editProgram
      .get("requireEntranceExam")
      .setValue(data["requireEntranceExam"]);
    this.editProgram
      .get("obtainLlicenseAfterGraduation")
      .setValue(data["obtainLlicenseAfterGraduation"]);
    if (data["logo"] != undefined) {
      this.apiD
        .getFile(this.bucket, decodeURIComponent(data["logo"]))
        .subscribe((data) => {
          this.logo = data["url"];
        });
    }

    if (data.courses) {
      data.courses.forEach((e: any) => {
        this.roles.push(e._id);
      });
    }
    this.editProgram.get("courses").setValue(this.roles ? this.roles : []);
    if (this.typeP === "print") {
      this.printv = true;
    }
    this.loadingD = false;
  }

  onFileChanged(event: any) {
    this.imgInvalid = false;
    this.infoImgInvalid = "";
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].size > 1048000) {
        this.imgInvalid = true;
        this.infoImgInvalid = "Invalid size file, only accept  < 1MB";
      } else if (
        event.target.files[0].type == "image/jpeg" ||
        event.target.files[0].type == "image/jpg" ||
        event.target.files[0].type == "image/png"
      ) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.schoolImg = reader.result as string;
          this.viewLogo = true;
          var img = new Image();
          img.onload = function (scope) {
            if (img.width > 550 || img.height > 300) {
              this.imgInvalid = true;
              this.file = "";
              this.fileName = "";
              this.schoolImg = "";
              this.infoImgInvalid =
                "Width: " + img.width + "px Height: " + img.height + "px";
            }
          }.bind(this);
          img.src = reader.result as string;
        };
        this.file = event.target.files[0];
        this.fileName = this.file.name;
      } else {
        this.imgInvalid = true;
        this.infoImgInvalid = "Invalid file extension, only accept  PNG, JPG";
      }
    }
  }

  createForm() {
    this.editProgram = this.fb.group({
      credentialIssued: ["", [Validators.required]],
      programTitle: ["", [Validators.required]],
      directorEducationName: ["", [Validators.required]],
      advisoryBoardMandated: ["", [Validators.required]],
      clinicalExternshipHours: ["", [Validators.required]],
      lectureHours: ["", [Validators.required]],
      labHours: ["", [Validators.required]],
      programLength: [
        "",
        [Validators.required, Validators.pattern("^[0-9]*$")],
      ],
      programDeliveryMethod: ["", [Validators.required]],
      programLengthType: ["", [Validators.required]],
      totalAmountWeeksRequiredtoFinishProgram: [
        "",
        [Validators.required, Validators.pattern("^[0-9]*$")],
      ],
      RequireGEDHSDiploma: ["", [Validators.required]],
      requireEntranceExam: ["", [Validators.required]],
      obtainLlicenseAfterGraduation: ["", [Validators.required]],
      courses: [[]],
    });
  }

  get credentialIssuedInvalid() {
    return (
      this.editProgram.get("credentialIssued").invalid &&
      this.editProgram.get("credentialIssued").touched
    );
  }

  get programTitleInvalid() {
    return (
      this.editProgram.get("programTitle").invalid &&
      this.editProgram.get("programTitle").touched
    );
  }

  get directorEducationNameInvalid() {
    return (
      this.editProgram.get("directorEducationName").invalid &&
      this.editProgram.get("directorEducationName").touched
    );
  }

  get lectureHoursInvalid() {
    return (
      this.editProgram.get("lectureHours").invalid &&
      this.editProgram.get("lectureHours").touched
    );
  }

  get advisoryBoardMandatedInvalid() {
    return (
      this.editProgram.get("advisoryBoardMandated").invalid &&
      this.editProgram.get("advisoryBoardMandated").touched
    );
  }

  get clinicalExternshipHoursInvalid() {
    return (
      this.editProgram.get("clinicalExternshipHours").invalid &&
      this.editProgram.get("clinicalExternshipHours").touched
    );
  }

  get labHoursInvalid() {
    return (
      this.editProgram.get("labHours").invalid &&
      this.editProgram.get("labHours").touched
    );
  }

  get programDeliveryMethodInvalid() {
    return (
      this.editProgram.get("programDeliveryMethod").invalid &&
      this.editProgram.get("programDeliveryMethod").touched
    );
  }

  get programLengthTypeInvalid() {
    return (
      this.editProgram.get("programLengthType").invalid &&
      this.editProgram.get("programLengthType").touched
    );
  }

  get RequireGEDHSDiplomaInvalid() {
    return (
      this.editProgram.get("RequireGEDHSDiploma").invalid &&
      this.editProgram.get("RequireGEDHSDiploma").touched
    );
  }

  get requireEntranceExamInvalid() {
    return (
      this.editProgram.get("requireEntranceExam").invalid &&
      this.editProgram.get("requireEntranceExam").touched
    );
  }

  get obtainLlicenseAfterGraduationInvalid() {
    return (
      this.editProgram.get("obtainLlicenseAfterGraduation").invalid &&
      this.editProgram.get("obtainLlicenseAfterGraduation").touched
    );
  }

  get totalAmountWeeksRequiredtoFinishProgramInvalid() {
    return (
      this.editProgram.get("totalAmountWeeksRequiredtoFinishProgram").invalid &&
      this.editProgram.get("totalAmountWeeksRequiredtoFinishProgram").touched
    );
  }

  save() {
    this.loadingD = true;
    if (this.editProgram.invalid) {
      this.loadingD = false;
      return Object.values(this.editProgram.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }

    if (this.file) {
      this.object = { file: this.file, bucket: this.bucket };
      this.apiD.uploadFile(this.object).subscribe((response) => {
        this.responseFile = response;
        if (this.responseFile.status == "ok") {
          this.apiD.deleteFile(this.bucket, this.path).subscribe((res) => {
            this.object = Object.assign(this.editProgram.value, {
              logo: this.responseFile.file,
            });
            const value = this.admin === "/admin" ? `/${this.schoolId}` : "";
            this.api
              .updateProgram(this.programId, this.object, value, this.admin)
              .subscribe((data) => {
                this.showToast(
                  "Message",
                  "Program Updated!",
                  "success",
                  "checkmark-outline"
                );
                this.cView = true;
                this.getData();
              });
          });
        }
      });
    } else {
      if (this.role == "cor-admin" || this.role == "ag-admin-us") {
        this.api
          .updateProgram(
            this.programId,
            this.editProgram.value,
            `/${this.schoolId}`,
            this.admin
          )
          .subscribe(
            (data) => {
              this.showToast(
                "Message",
                "Program Updated!",
                "success",
                "checkmark-outline"
              );
              if (!this.call) {
                this.loadingD = false;
                this.location.back();
              }
              this.loadingD = false;
              this.statusP.emit(true);
            },
            (err) => {
              this.loadingD = false;
              this.showToast(
                "Message",
                "An error occurred, please try again",
                "danger",
                "alert-circle-outline"
              );
            }
          );
      } else {
        this.api
          .updateProgram(this.programId, this.editProgram.value)
          .subscribe(
            (data) => {
              this.showToast(
                "Message",
                "Program Updated!",
                "success",
                "checkmark-outline"
              );
              if (!this.call) {
                this.loadingD = false;
                this.location.back();
              }
              this.loadingD = false;
              this.statusP.emit(true);
            },
            (err) => {
              this.loadingD = false;
              this.showToast(
                "Message",
                "An error occurred, please try again",
                "danger",
                "alert-circle-outline"
              );
            }
          );
      }
    }
  }

  goBack() {
    this.location.back();
  }

  goEdit() {
    this.cView = false;
  }

  goCancel() {
    this.cView = true;
  }

  dismiss(event: boolean) {
    this.statusP.emit(false);
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  drive(val) {
    this.columnsDrives = [
      "select",
      "name",
      "description",
      "expiration",
      "recipient",
      "options",
    ];
    this.driveSelected = val;
    const value = this.admin === "/admin" ? `/${this.schoolId}` : "";
    this.apiD
      .getListDocumentsByDrive(val, value, this.admin)
      .subscribe((data) => {
        this.loading2 = true;
        this.dataO3 = data["documents"];

        this.state = true;
        this.driveS = data["name"];
        this.loading2 = false;
      });
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
