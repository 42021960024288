import {
  Component,
  Input,
  Output,
  OnInit,
  SimpleChanges,
  ViewChild,
  EventEmitter,
} from "@angular/core";
import { User } from "../@core/model/user.model";
import { usersService } from "../@core/api/base-api/users.api";
import { AuthService } from "../@auth/auth.service";
import { ActivatedRoute } from "@angular/router";
import * as Cookie from "js-cookie";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { FormControl } from "@angular/forms";
import { SelectionModel } from "@angular/cdk/collections";
import { SchoolsService } from "../@core/api/base-api/schools.api";
import { NbToastrService, NbIconConfig } from "@nebular/theme";
import { Location } from "@angular/common";

export interface UserElement {
  id: string;
  idAuth: string;
  name: string;
  email: string;
  lastLoginAt: string;
  status: boolean;
}

const ELEMENT_USERS: UserElement[] = [];
@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
  @Output() addStudents: EventEmitter<any> = new EventEmitter<any>();
  @Input() hidden: boolean;
  @Input() dataO1;
  @Input() course: string;
  @Input() school: string;
  @Input() campus: string;
  @Input() program: string;
  data: any = [];
  users: User[];
  id: string;
  role: string;
  accountNumber: string;
  schoolId: string;
  displayedColumns2: string[];
  filterValxues = {
    status: "",
  };
  dataSource2 = new MatTableDataSource(ELEMENT_USERS);
  statusFilter = new FormControl("");
  admin: string;
  valor: any;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  selection = new SelectionModel<UserElement>(true, []);

  object: {};
  students: any = [];
  loading: boolean;

  constructor(
    private api: usersService,
    private apiS: SchoolsService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private toastrService: NbToastrService,
    private location: Location
  ) {}

  ngOnInit() {
    this.loading = true;
    ELEMENT_USERS.length = 0;

    this.schoolId = this.route.snapshot.paramMap.get("schoolId");
    this.id = localStorage.getItem("urlId");

    if (this.id == "admin") {
      this.admin = "/admin";
    }
    this.refresh();
  }

  refresh() {
    if (this.hidden) {
      this.displayedColumns2 = ["select", "name", "email", "status"];
    } else {
      this.displayedColumns2 = [
        "name",
        "email",
        "lastLoginAt",
        "status",
        "actions",
      ];
    }
    this.auth.getUser$().subscribe((data) => {
      this.role = data["https://cor4edu.com/roles"][0];
      if (this.role == "cor-admin" || this.role == "ag-admin-us") {
        this.api
          .getUsersByAccount(this.schoolId, this.admin)
          .subscribe((data) => {
            this.data = data;
            ELEMENT_USERS.length = 0;
            if (this.valor != undefined) {
              this.data.forEach(
                function (value) {
                  if (!this.searchArray(value)) {
                    ELEMENT_USERS.push({
                      id: value["_id"],
                      idAuth: value["idAuth"],
                      name: value["firstName"] + " " + value["lastName"],
                      email: value["email"],
                      lastLoginAt: value["lastLoginAt"],
                      status: value["systemAccess"],
                    });
                  }
                }.bind(this)
              );
            } else {
              this.data.forEach(function (value) {
                ELEMENT_USERS.push({
                  id: value["_id"],
                  idAuth: value["idAuth"],
                  name: value["firstName"] + " " + value["lastName"],
                  email: value["email"],
                  lastLoginAt: value["lastLoginAt"],
                  status: value["systemAccess"],
                });
              });
            }

            this.dataSource2.paginator = this.paginator;
            this.dataSource2.sort = this.sort;
            this.loading = false;
          });
      } else {
        this.api.getUsersByAccount().subscribe((data) => {
          this.data = data;
          ELEMENT_USERS.length = 0;
          // this.data.forEach(function (value) {
          //   ELEMENT_USERS.push({
          //     id: value["_id"],
          //     idAuth: value["idAuth"],
          //     name: value["firstName"] + " " + value["lastName"],
          //     email: value["email"],
          //     lastLoginAt: value["lastLoginAt"],
          //     status: value["systemAccess"],
          //   });
          // });
          if (this.valor != undefined) {
            this.data.forEach(
              function (value) {
                if (!this.searchArray(value)) {
                  ELEMENT_USERS.push({
                    id: value["_id"],
                    idAuth: value["idAuth"],
                    name: value["firstName"] + " " + value["lastName"],
                    email: value["email"],
                    lastLoginAt: value["lastLoginAt"],
                    status: value["systemAccess"],
                  });
                }
              }.bind(this)
            );
          } else {
            this.data.forEach(function (value) {
              ELEMENT_USERS.push({
                id: value["_id"],
                idAuth: value["idAuth"],
                name: value["firstName"] + " " + value["lastName"],
                email: value["email"],
                lastLoginAt: value["lastLoginAt"],
                status: value["systemAccess"],
              });
            });
          }
          this.dataSource2.paginator = this.paginator;
          this.dataSource2.sort = this.sort;
          this.loading = false;
        });
      }
    });
  }

  goBack() {
    this.location.back();
  }

  searchArray(value) {
    let fin = false;
    this.valor.forEach(function (valor) {
      if (valor._id === value._id) {
        fin = true;
        return fin;
      }
    });
    return fin;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.dataO1 && changes.dataO1) {
      if (changes.dataO1.currentValue != changes.dataO1.previousValue) {
        changes.dataO1.currentValue;
        this.valor = changes.dataO1.currentValue;
        this.refresh();
      }
    }

    /*if (changes.columns && changes.columns) {
      if (changes.columns.currentValue != undefined) {
        this.displayedColumns = this.columns;
      }
    }*/
  }

  addUsers() {
    this.selection.selected.forEach(
      function (valor) {
        this.students.push(valor.id);
      }.bind(this)
    );
    this.object = {
      students: this.students,
    };
    if (this.role == "cora-dmin" || this.role == "ag-admin-us") {
      if (this.course) {
        this.apiS
          .updateRelationshipCourses(
            this.course,
            this.object,
            this.admin,
            `/${this.schoolId}`
          )
          .subscribe(
            (data) => {
              this.showToast(
                "Message",
                "Student Added!",
                "success",
                "checkmark-outline"
              );
              this.addStudents.emit(true);
              this.selection = new SelectionModel<UserElement>(true, []);
              this.students.length = 0;
            },
            (err) => {
              this.showToast(
                "Message",
                "An error occurred, please try again",
                "danger",
                "alert-circle-outline"
              );
            }
          );
      } else if (this.campus) {
        this.apiS
          .updateRelationshipCampus(
            this.campus,
            this.object,
            this.admin,
            `/${this.schoolId}`
          )
          .subscribe(
            (data) => {
              this.showToast(
                "Message",
                "Student Added!",
                "success",
                "checkmark-outline"
              );
              this.addStudents.emit(true);
              this.selection = new SelectionModel<UserElement>(true, []);
              this.students.length = 0;
            },
            (err) => {
              this.showToast(
                "Message",
                "An error occurred, please try again",
                "danger",
                "alert-circle-outline"
              );
            }
          );
      } else if (this.program) {
        this.apiS
          .updateRelationshipPrograms(
            this.program,
            this.object,
            this.admin,
            `/${this.schoolId}`
          )
          .subscribe(
            (data) => {
              this.showToast(
                "Message",
                "Student Added!",
                "success",
                "checkmark-outline"
              );
              this.addStudents.emit(true);
              this.selection = new SelectionModel<UserElement>(true, []);
              this.students.length = 0;
            },
            (err) => {
              this.showToast(
                "Message",
                "An error occurred, please try again",
                "danger",
                "alert-circle-outline"
              );
            }
          );
      } else if (this.school) {
        this.apiS.updateRelationship(this.school, this.object).subscribe(
          (data) => {
            this.showToast(
              "Message",
              "Student Added!",
              "success",
              "checkmark-outline"
            );
            this.addStudents.emit(true);
            this.selection = new SelectionModel<UserElement>(true, []);
            this.students.length = 0;
          },
          (err) => {
            this.showToast(
              "Message",
              "An error occurred, please try again",
              "danger",
              "alert-circle-outline"
            );
          }
        );
      }
    } else {
      if (this.course) {
        this.apiS.updateRelationshipCourses(this.course, this.object).subscribe(
          (data) => {
            // this.api.updateRelationshipUsers(this.)
            this.showToast(
              "Message",
              "Student Added!",
              "success",
              "checkmark-outline"
            );
            this.checkComponents("course", this.course, this.students);
            this.addStudents.emit(true);
            this.selection = new SelectionModel<UserElement>(true, []);
            this.students.length = 0;
          },
          (err) => {
            this.showToast(
              "Message",
              "An error occurred, please try again",
              "danger",
              "alert-circle-outline"
            );
          }
        );
      } else if (this.campus) {
        this.apiS.updateRelationshipCampus(this.campus, this.object).subscribe(
          (data) => {
            this.showToast(
              "Message",
              "Student Added!",
              "success",
              "checkmark-outline"
            );
            this.addStudents.emit(true);
            this.selection = new SelectionModel<UserElement>(true, []);
            this.students.length = 0;
          },
          (err) => {
            this.showToast(
              "Message",
              "An error occurred, please try again",
              "danger",
              "alert-circle-outline"
            );
          }
        );
      } else if (this.program) {
        this.apiS
          .updateRelationshipPrograms(this.program, this.object)
          .subscribe(
            (data) => {
              this.showToast(
                "Message",
                "Student Added!",
                "success",
                "checkmark-outline"
              );
              this.addStudents.emit(true);
              this.selection = new SelectionModel<UserElement>(true, []);
              this.students.length = 0;
            },
            (err) => {
              this.showToast(
                "Message",
                "An error occurred, please try again",
                "danger",
                "alert-circle-outline"
              );
            }
          );
      } else if (this.school) {
        this.apiS.updateRelationship(this.school, this.object).subscribe(
          (data) => {
            this.showToast(
              "Message",
              "Student Added!",
              "success",
              "checkmark-outline"
            );
            this.addStudents.emit(true);
            this.selection = new SelectionModel<UserElement>(true, []);
            this.students.length = 0;
          },
          (err) => {
            this.showToast(
              "Message",
              "An error occurred, please try again",
              "danger",
              "alert-circle-outline"
            );
          }
        );
      }
    }
  }

  checkComponents(type, id, data) {
    console.log(data);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource2.filter = filterValue;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource2.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource2.data.forEach((row) => this.selection.select(row));
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
