import { Component, OnInit } from "@angular/core";
import { DocumentsService } from "../@core/api/base-api/documents.api";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../@auth/auth.service";
import { SchoolsService } from "../@core/api/base-api/schools.api";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { viewDocumentComponent } from "../documents/view-document/view-document";
import { NbDialogService, NbIconConfig, NbToastrService } from "@nebular/theme";
import { Location } from "@angular/common";

@Component({
  selector: "app-edit-sending-documents",
  templateUrl: "./edit-sending-document.component.html",
  styleUrls: ["./edit-sending-document.component.scss"],
})
export class EditSendingDocumentComponent implements OnInit {
  addForm: FormGroup;
  school: string;
  schoolId: string;
  admin: string;
  drives: any = [];
  documents: any = [];
  components: any = [];
  sendingSchool: boolean;
  sendingCampus: boolean;
  sendingPrograms: boolean;
  sendingCourses: boolean;
  idSchool: string;
  documentSelect: boolean;
  optionSelected: number;
  receiveOption: boolean;
  approvalOption: boolean;
  instructionsOption: boolean;
  users: any = [];
  radioOption: number;
  sendingUserId: string;
  requestId: string;
  data: any = [];
  bucket: string;
  formValues: object;
  constructor(
    private api: DocumentsService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private apiS: SchoolsService,
    private formBuilder: FormBuilder,
    private dialogService: NbDialogService,
    private location: Location,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.school = this.route.snapshot.paramMap.get("schoolId");
    this.schoolId = localStorage.getItem("urlId");
    this.requestId = this.route.snapshot.paramMap.get("idRequest");
    this.apiS.getTenant(this.schoolId).subscribe((res) => {
      this.bucket = res["bucket"];
      this.api.getRequestById(this.requestId).subscribe((data) => {
        this.data = data;
        this.formValues = data["respond"]["formValues"];
        this.addForm.get("status").setValue(this.data["respond"]["status"]);
        this.addForm.get("message").setValue(this.data["respond"]["message"]);
      });
    });

    this.auth.getUser$().subscribe((data) => {
      this.sendingUserId = data["sub"];
    });
    this.sendingSchool = this.auth.permissions.includes(
      "sending:documents-school"
    );
    this.sendingCampus = this.auth.permissions.includes(
      "sending:documents-campus"
    );
    this.sendingPrograms = this.auth.permissions.includes(
      "sending:documents-programs"
    );
    this.sendingCourses = this.auth.permissions.includes(
      "sending:documents-courses"
    );

    if (this.schoolId == "admin") {
      this.admin = "/admin";
    }
    const value = this.school ? `${this.school}` : "";
    this.drives = false;

    this.components = false;
    this.form();
  }

  initLink() {
    return this.formBuilder.group({
      linkAddress: ["", Validators.required],
    });
  }
  addLink() {
    const control = <FormArray>this.addForm.controls["instructionsI"];
    control.push(this.initLink());
  }
  removeLink(i: number) {
    const control = <FormArray>this.addForm.controls["instructionsI"];
    control.removeAt(i);
  }

  open(file, document) {
    this.dialogService.open(viewDocumentComponent, {
      context: {
        title: document,
        bucket: this.bucket,
        file,
      },
    });
  }

  componentOperate(data) {
    this.drives = data["drives"];
  }

  to(val) {
    this.radioOption = val;
  }

  dataR(data) {
    this.formValues = data;
    this.save();
  }

  drive(id) {
    const value = this.school ? `/${this.school}` : "";
    this.api
      .getListDocumentsByDrive(encodeURIComponent(id), value, this.admin)
      .subscribe((res) => {
        this.documents = res["documents"];
      });
  }
  document(id) {
    this.documentSelect = id;
  }

  option(val) {
    this.optionSelected = val;
  }

  receive(val) {
    this.receiveOption = val;
  }

  approval(val) {
    this.approvalOption = val;
  }
  instructions(val) {
    this.instructionsOption = val;
  }

  form() {
    this.addForm = this.formBuilder.group({
      status: [false],
      message: [""],
    });
  }

  save() {
    const newData = Object.assign(this.data.respond, {
      status: this.addForm.value.status,
      message: this.addForm.value.message,
      formValues: this.formValues,
    });

    this.api
      .updateRequestById(this.requestId, {
        respond: newData,
      })
      .subscribe((data) => {
        this.showToast(
          "Message",
          "Updated Request!",
          "success",
          "checkmark-outline"
        );
      });
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }

  goBack() {
    this.location.back();
  }
}
