import { Component, OnInit } from "@angular/core";
import { NbDialogService, NbIconConfig, NbToastrService } from "@nebular/theme";
import { ResponseUploadComponent } from "./response-upload/response-upload.component";
import { DocumentsService } from "../@core/api/base-api/documents.api";
import { ActivatedRoute } from "@angular/router";
import { SchoolsService } from "../@core/api/base-api/schools.api";
import printJS from "print-js";
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { usersService } from "../@core/api/base-api/users.api";
import { AuthService } from "../@auth/auth.service";
import { ViewInstructionsComponent } from "./view-instructions/view-instructions.component";
import { Location } from "@angular/common";

@Component({
  selector: "app-respond-documents",
  templateUrl: "./respond-documents.component.html",
  styleUrls: ["./respond-documents.component.scss"],
})
export class RespondDocumentsComponent implements OnInit {
  idRequest: string;
  extension: string;
  fileV: string;
  schoolId: string;
  bucket: string;
  data: any = [];
  file: string;
  fileR: File;
  fileName: string;
  respondForm: FormGroup;
  responseFile: any;
  object: {};
  drives: any = [];
  drive: string;
  check: boolean;
  document: any;
  formValues: object;
  loading: boolean;
  constructor(
    private dialogService: NbDialogService,
    private apiD: DocumentsService,
    private apiS: SchoolsService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private apiU: usersService,
    private auth: AuthService,
    private location: Location,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.check = false;
    this.schoolId = localStorage.getItem("urlId");
    this.idRequest = this.route.snapshot.paramMap.get("idRequest");
    this.apiS.getTenant(this.schoolId).subscribe((data) => {
      this.bucket = data["bucket"];
      this.getData();
    });
    this.form();
  }
  getData() {
    this.loading = true;
    this.apiD.getRequestById(this.idRequest).subscribe((res) => {
      this.formValues = res["respond"]["formValues"];
      this.data = res;
      this.document = res["documentId"];
      this.check = res["respond"]["reviewed"];
      this.respondForm.get("reviewed").setValue(this.check);
      this.file = res["documentId"]["file"];
      this.loading = false;
      if (res["documentId"]["file"]) {
        this.apiD
          .getFile(
            encodeURIComponent(this.bucket),
            encodeURIComponent(res["documentId"]["file"])
          )
          .subscribe((data1) => {
            this.extension = res["documentId"]["file"].split(".").pop();
            this.fileV = data1["url"];
            this.auth.getUser$().subscribe((res1) => {
              this.apiU.getUserById(res1["sub"]).subscribe((res) => {
                this.drives = res["drives"];
              });
            });
          });
      }
    });
  }
  isInvalid() {
    return (
      this.data.respond.status === "Pending sends back to sender" ||
      this.data.respond.status === "Rejected"
    );
  }
  form() {
    this.respondForm = this.formBuilder.group({
      reviewed: [
        false,
        this.conditionalValidator(
          () => this.data.receive === true,
          Validators.required
        ),
      ],
      dateExpired: [
        "",
        this.conditionalValidator(
          () => this.data.expirationDoc === true,
          Validators.required
        ),
      ],
      file: [
        "",
        this.conditionalValidator(
          () => this.data.uploadDoc === true,
          Validators.required
        ),
      ],
    });
  }
  conditionalValidator(
    condition: () => boolean,
    validator: ValidatorFn
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!condition()) {
        return null;
      }
      return validator(control);
    };
  }
  review() {}
  download() {
    this.apiD
      .getFile(encodeURIComponent(this.bucket), encodeURIComponent(this.file))
      .subscribe((data1) => {
        window.open(data1["url"], "_blank");
      });
  }

  get reviewedInvalid() {
    return (
      this.respondForm.get("reviewed").invalid &&
      this.respondForm.get("reviewed").touched
    );
  }

  get fileInvalid() {
    return (
      this.respondForm.get("file").invalid &&
      this.respondForm.get("file").touched
    );
  }

  print() {
    if (this.file != "") {
      this.apiD
        .getFile(encodeURIComponent(this.bucket), encodeURIComponent(this.file))
        .subscribe((data1) => {
          const extension = this.file.split(".").pop();
          if (extension == "png" || extension == "jpg" || extension == "jpeg") {
            printJS(decodeURI(data1["url"]), "image");
          } else {
            printJS(decodeURI(data1["url"]));
          }
        });
    } else {
      // this.openWindowForm("print", data);
    }
  }
  viewI(data) {
    this.dialogService.open(ViewInstructionsComponent, {
      context: {
        instructions: data["instructionsI"],
      },
      hasScroll: true,
    });
  }
  upload() {
    this.dialogService
      .open(ResponseUploadComponent, {
        context: {
          drives: this.drives,
          data: this.data,
        },
        hasScroll: true,
      })
      .onClose.subscribe((res) => {
        if (res) {
          this.respondForm.get("file").setValue(res[0]);
          this.fileR = res[0];
          this.fileName = res[0].name;
          this.drive = res[1];
          this.data = res[2];
        }
      });
  }

  dataR(data) {
    this.formValues = data;
    this.save();
  }

  save() {
    if (this.respondForm.invalid) {
      return Object.values(this.respondForm.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }

    this.object = {
      id: this.drive,
      file: this.fileR,
      bucket: this.bucket,
    };
    if (this.fileR) {
      this.apiD.uploadFile(this.object).subscribe((response) => {
        this.responseFile = response;
        if (this.responseFile.status == "ok") {
          const data = Object.assign(this.document, {
            file: `${this.drive}/${this.responseFile.file}`,
          });
          delete data._id;
          this.apiD.createDocument(data).subscribe((data) => {
            this.apiD
              .updateRelationshipDrives(this.drive, {
                documents: [data["_id"]],
              })
              .subscribe((res) => {
                Object.assign(this.data.respond, {
                  reviewed: this.respondForm.value.reviewed,
                  documentId: data["_id"],
                  submit: true,
                  dateExpired: this.respondForm.value.dateExpired,
                  status:
                    "Pending - Admin (staff waiting for director approval) (Admin Inbox)",
                });
                Object.assign(this.data, {
                  notification: false,
                });
                delete this.data._id;
                delete this.data.documentId;
                this.apiD
                  .updateRequestById(this.idRequest, this.data)
                  .subscribe((res) => {
                    this.showToast(
                      "Message",
                      "Updated Request!",
                      "success",
                      "checkmark-outline"
                    );
                    this.location.back();
                  });
              });
          });
        } else {
          console.log("error");
        }
      });
    } else {
      Object.assign(this.data.respond, {
        reviewed: this.respondForm.value.reviewed,
        formValues: this.formValues,
        submit: true,
      });
      Object.assign(this.data, {
        notification: false,
      });
      delete this.data._id;
      delete this.data.documentId;
      this.apiD
        .updateRequestById(this.idRequest, this.data)
        .subscribe((res) => {
          this.showToast(
            "Message",
            "Updated Request!",
            "success",
            "checkmark-outline"
          );
          this.getData();
        });
    }
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }

  goBack() {
    this.location.back();
  }
}
