import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { DocumentsService } from "../../@core/api/base-api/documents.api";
import { ActivatedRoute } from "@angular/router";
import { SchoolService } from "../../@core/services/school.service";
import { Location } from "@angular/common";
import { SchoolsService } from "../../@core/api/base-api/schools.api";
import { AuthService } from "../../@auth/auth.service";
import { usersService } from "../../@core/api/base-api/users.api";
import { NbDialogRef, NbIconConfig, NbToastrService } from "@nebular/theme";

@Component({
  selector: "app-edit-drive",
  templateUrl: "./edit-drive.component.html",
  styleUrls: ["./edit-drive.component.scss"],
})
export class EditDriveComponent implements OnInit {
  @Output() status: EventEmitter<any> = new EventEmitter<any>();
  @Input() hidden: boolean;
  @Input() schoolE: string;
  @Input() call: boolean;
  @Input() sandbox: boolean;
  @Input() drive: string;
  @Input() tab: string;
  @Input() typeO: string;
  @Input() idE: string;
  @Input() name: string;
  id: string;
  editDrive: FormGroup;
  schoolId: string;
  school: string;
  admin: string;
  create: boolean;
  class: string;
  checkedDefault: boolean;
  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private api: DocumentsService,
    private apiS: SchoolsService,
    private _location: Location,
    private schoolS: SchoolService,
    private route: ActivatedRoute,
    private userService: usersService,
    private toastrService: NbToastrService,
    protected dialogRef: NbDialogRef<EditDriveComponent>
  ) {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.idE && changes.idE) {
      if (changes.idE.currentValue != changes.idE.previousValue) {
        this.idE = changes.idE.currentValue;
        this.editDrive.get("name").setValue(changes.name.currentValue);
      }
    }
  }

  createForm() {
    this.editDrive = this.fb.group({
      name: ["", [Validators.required, Validators.minLength(3)]],
    });
  }

  ngOnInit() {
    this.checkedDefault = false;
    this.schoolId = localStorage.getItem("urlId");
    this.school = this.route.snapshot.paramMap.get("schoolId");

    if (this.schoolId == "admin") {
      this.admin = "/admin";
    }

    this.auth.isAuthenticated$.subscribe((data) => {
      if (data) {
        this.auth.getUser$().subscribe((data) => {
          if (data != undefined) {
            this.id = data["sub"];
            this.userService.getUserById(this.id).subscribe((data) => {});
          }
        });
      }
    });
  }

  get nameInvalid() {
    return (
      this.editDrive.get("name").invalid && this.editDrive.get("name").touched
    );
  }

  clickDefault(checked: boolean) {
    this.checkedDefault = checked;
  }

  save() {
    if (this.editDrive.invalid) {
      return Object.values(this.editDrive.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }
    const value = this.schoolE ? `/${this.schoolE}` : "";
    this.api
      .updateNameDrive(this.idE, this.editDrive.value, value, this.admin)
      .subscribe((res) => {
        this.afterCall();
      });
  }

  dismiss() {
    this.dialogRef.close();
  }

  afterCall() {
    this.showToast(
      "Message",
      "Updated Drive Name!",
      "success",
      "checkmark-outline"
    );
    this.dialogRef.close(true);
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }

  back() {
    this._location.back();
  }
}
