import { AuthService } from "./../../../@auth/auth.service";
import { Component } from "@angular/core";

@Component({
  selector: "app-splash",
  styleUrls: ["./splash.component.scss"],
  templateUrl: "./splash.component.html",
})
export class SplashComponent {
  public loading = true;

  constructor(private auth: AuthService) {
    this.auth.auth0Client$.subscribe((result) => {
      this.remove();
    });
  }

  public remove() {
    this.loading = false;
  }
}
