import { Injectable } from "@angular/core";

@Injectable()
export class AppMenu {
  adminMenu = [
    {
      title: "Dashboard",
      icon: "home-outline",
      link: "admin/",
      home: true,
      children: undefined,
    },
    {
      title: "Roles and Permissions",
      icon: "shield-outline",
      link: "admin/roles",
      data: "read:roles",
      children: undefined,
    },
    {
      title: "Schools",
      icon: "smartphone-outline",
      link: "admin/schools",
      data: "menu:school",
      children: [
        {
          title: "Schools",
          link: "admin/schools",
          data: "menu:school",
        },
        {
          title: "Create School",
          link: "admin/add-school",
          data: "create:schools",
        },
      ],
    },
  ];

  dashboardMenu = [
    {
      title: "Dashboard",
      icon: "home-outline",
      link: "/",
      home: true,
      children: undefined,
    },
    {
      title: "Users",
      icon: "person-outline",
      link: "/users",
      data: "read:users",
      children: [
        {
          title: "Users",
          link: "/users",
          data: "read:users",
        },
        {
          title: "Create User",
          link: "/add-user",
          data: "create:users",
        },
      ],
    },
    {
      title: "Drives and Documents",
      icon: "archive-outline",
      link: "/documents",
      data: "read:documents",
      children: undefined,
    },
    {
      title: "User Document Status",
      icon: "archive-outline",
      link: "/documents-student",
      data: "read:documents-students",
      children: undefined,
    },
    {
      title: "Forms",
      icon: "file-text-outline",
      link: "/forms",
      data: "read:forms",
      children: [
        {
          title: "Forms",
          link: "/forms",
          data: "read:forms",
        },
        {
          title: "Create Form",
          link: "/add-form",
          data: "create:forms",
        },
      ],
    },
    {
      title: "Schools",
      icon: "smartphone-outline",
      link: "/schools",
      data: "menu:school",
      children: [
        {
          title: "Schools",
          link: "/schools",
          data: "read:schools",
        },
        {
          title: "Create School",
          link: "/add-school",
          data: "create:schools",
        },
      ],
    },
    {
      title: "Campus",
      icon: "bookmark-outline",
      link: "/campus",
      data: "read:campus",
      children: [
        {
          title: "Campus",
          link: "/campus",
          data: "read:campus",
        },
        {
          title: "Create Campus",
          link: "/add-campus",
          data: "create:campus",
        },
      ],
    },
    {
      title: "Campus",
      icon: "bookmark-outline",
      link: "/campus-student",
      data: "read:campus-student",
      children: undefined,
    },
    {
      title: "Programs",
      icon: "award-outline",
      link: "/programs-student",
      data: "read:programs-student",
      children: undefined,
    },
    {
      title: "Courses",
      icon: "book-outline",
      link: "/courses-student",
      data: "read:courses-student",
      children: undefined,
    },
    {
      title: "Programs",
      icon: "award-outline",
      link: "/programs",
      data: "read:programs",
      children: [
        {
          title: "Programs",
          link: "/programs",
          data: "read:programs",
        },
        {
          title: "Create Program",
          link: "/add-program",
          data: "create:programs",
        },
      ],
    },
    {
      title: "Courses",
      icon: "book-outline",
      link: "/courses",
      data: "read:courses",
      children: [
        {
          title: "Courses",
          link: "/courses",
          data: "read:courses",
        },
        {
          title: "Create Course",
          link: "/add-course",
          data: "create:course",
        },
      ],
    },
    {
      title: "User Titles Names",
      icon: "shield-outline",
      link: "/user-title-names",
      data: "read:user-title-names",
      children: undefined,
    },
  ];
}
