import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { usersService } from "../@core/api/base-api/users.api";
import { AuthService } from "../@auth/auth.service";
import { DocumentsService } from "../@core/api/base-api/documents.api";
import { SchoolsService } from "../@core/api/base-api/schools.api";
import { Level } from "../@core/model/school.model";
import * as Cookie from "js-cookie";
import { SchoolService } from "../@core/services/school.service";
import { MatTableDataSource } from "@angular/material/table";
import { PeriodicElement } from "../schools/edit-school/edit-school.component";
import { NbToastrService } from "@nebular/theme";

@Component({
  selector: "app-edit-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  Level = new Level();
  roles: any = [];
  selectedOption: string;
  printedOption: string;
  profileForm: FormGroup;
  editSchool: FormGroup;
  userId: string;
  firstName: string;
  secondName: string;
  lastName: string;
  secondLastName: string;
  userTitleNames: string;
  systemAccess: boolean;
  dateOfBirth: string;
  state: string;
  city: string;
  zipCode: number;
  homePhone: number;
  workPhone: number;
  cellPhone: number;
  email: string;
  secondaryEmail: string;
  emergencyContactName: string;
  emergencyContactPhone: number;
  countryOfCitizenship: string;
  usVeteran: boolean;
  programSelection: string;
  expectedGraduationDate: string;
  programStartDate: string;
  cohortName: string;
  hiredDate: string;
  id: string;
  picture: string;
  file: File;
  data: {};
  private responseFile;
  role: string;
  logo: string;
  fileName: string;
  object: {};
  img: string;
  schoolId: string;
  bucket: string;
  create: boolean;
  class: string;
  show: boolean;
  val: string;
  loading: boolean;
  loading2: boolean;
  dataO1: any;
  data1: any;
  dataO2: any;
  data2: any;
  dataO3: any;
  data3: any;
  state1: boolean;
  state3: boolean;
  columns: any;
  columns2: any;
  columns3: any;
  title1: string;
  title2: string;
  title3: string;
  driveS1: string;
  driveS3: string;
  tab: number;
  permiUpdate: boolean;
  public date1 = new Date();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: usersService,
    private documentService: DocumentsService,
    private auth: AuthService,
    private api: SchoolsService,
    private schoolS: SchoolService,
    private toastrService: NbToastrService
  ) {
    this.validateForm();
    this.createForm();
    this.auth.isAuthenticated$.subscribe((data) => {
      if (!data) {
        this.schoolId = localStorage.getItem("urlId");
        this.router.navigate([`/school/${this.schoolId}`]);
        return;
      }
    });
  }

  ngOnInit() {
    this.schoolId = localStorage.getItem("urlId");
    this.auth.permissions$.subscribe((data) => {
      this.permiUpdate = data.includes("update:profile");
      if (!this.permiUpdate) {
        this.profileForm.disable();
      }
    });

    this.auth.getUser$().subscribe((data) => {
      this.id = data["sub"];
      this.role = data["https://cor4edu.com/roles"][0];
      if (this.role == "campus") {
        this.api.getSchool(this.id).subscribe((data) => {
          this.editSchool
            .get("accountStartDateContract")
            .setValue(data["accountStartDateContract"]);
          this.editSchool
            .get("accountContractExpirationDate")
            .setValue(data["accountContractExpirationDate"]);
          this.editSchool
            .get("accountLevelType")
            .setValue(data["accountLevelType"]);
          this.editSchool
            .get("accountOwnerPersonalName")
            .setValue(data["accountOwnerPersonalName"]);
          this.editSchool
            .get("accountOwnerPersonalEmail")
            .setValue(data["accountOwnerPersonalEmail"]);
          this.editSchool
            .get("accountOwnerPersonalPhone")
            .setValue(data["accountOwnerPersonalPhone"]);
          this.editSchool
            .get("accountOwnerCompanyName")
            .setValue(data["accountOwnerCompanyName"]);
          this.editSchool.get("DBAName").setValue(data["DBAName"]);
          this.editSchool.get("urlId").setValue(data["urlId"]);
          this.picture = data["logo"];
          this.bucket = data["bucket"];
          this.documentService
            .getFile(this.bucket, decodeURIComponent(data["logo"]))
            .subscribe((data) => {
              this.img = data["url"];
            });
        });
      } else {
        this.api.getTenant(this.schoolId).subscribe((data) => {
          this.bucket = data["bucket"];
          this.userService.getProfileById(this.id).subscribe((data) => {
            this.profileForm.get("firstName").setValue(data["firstName"]);
            this.profileForm.get("secondName").setValue(data["secondName"]);
            this.profileForm.get("lastName").setValue(data["lastName"]);
            this.profileForm
              .get("secondLastName")
              .setValue(data["secondLastName"]);
            if (data["dateOfBirth"] != "") {
              this.date1 = new Date(data["dateOfBirth"]);
              this.profileForm.get("dateOfBirth").setValue(this.date1);
            }
            this.profileForm.get("address.city").setValue(data["address"].city);
            this.profileForm
              .get("address.state")
              .setValue(data["address"].state);
            this.profileForm
              .get("address.zipCode")
              .setValue(data["address"].zipCode);
            this.profileForm.get("homePhone").setValue(data["homePhone"]);
            this.profileForm.get("cellPhone").setValue(data["cellPhone"]);
            this.profileForm.get("workPhone").setValue(data["workPhone"]);
            this.profileForm.get("email").setValue(data["email"]);
            this.profileForm
              .get("secondaryEmail")
              .setValue(data["secondaryEmail"]);
            this.profileForm
              .get("emergencyContactName")
              .setValue(data["emergencyContactName"]);
            this.profileForm
              .get("emergencyContactPhone")
              .setValue(data["emergencyContactPhone"]);
            this.profileForm
              .get("countryOfCitizenship")
              .setValue(data["countryOfCitizenship"]);
            this.profileForm.get("usVeteran").setValue(data["usVeteran"]);
            this.documentService
              .getFile(
                encodeURIComponent(`${this.bucket}`),
                encodeURIComponent(`${this.id}/${data["picture"]}`)
              )
              .subscribe((data) => {
                this.picture = data["url"];
              });
          });
        });
      }
    });
  }

  public nameDrive(val: any): void {
    if (!this.isEmpty(val)) {
      if (val.name1 != undefined) {
        this.tab = 1;
        this.drive(val.name1);
      } else if (val.name2 != undefined) {
        this.tab = 2;
        this.drive(val.name2);
      }
    }
  }

  public tableR(name: any): void {
    this.refresh();
    // this.refresh1();
    // this.refresh2();
    // this.refresh3();
  }

  public refreshR(name: any): void {
    this.refresh();
    this.refresh1();
    // this.refresh1();
    // this.refresh4();
  }

  public addP(val: any): void {
    if (val == 1) {
      this.refresh();
    }
    //else if (val == 2) {
    //   this.refresh1();
    // } else if (val == 3) {
    //   this.refresh2();
    // } else if (val == 4) {
    //   this.refresh3();
    // }
  }

  refresh = function () {
    if (!this.call) {
      this.loading = true;
      this.api.listPrograms(this.schoolId).subscribe((res) => {
        this.dataO1 = res;
        this.data1 = new MatTableDataSource<PeriodicElement>(this.dataO1);
        this.state = false;
        this.loading = false;
      });
    }
  };

  refresh1 = function () {
    this.columns2 = [
      "select",
      "courseName",
      "courseId",
      "WeeksInLength",
      "programId",
    ];
    this.loading2 = true;
    this.api.listCourses(this.schoolId).subscribe((res) => {
      this.dataO2 = res;
      this.data2 = new MatTableDataSource<PeriodicElement>(this.dataO2);
      this.state1 = false;
      this.loading2 = false;
    });
  };

  refresh2 = function () {
    this.columns3 = [
      "select",
      "name",
      "submittedBy",
      "dateSubmitted",
      "renewalRequired",
      "renewBy",
      "approval",
    ];
    this.loading3 = true;
    this.apiD
      .getListDocumentsByDrive("Nursing", `/${this.schoolId}`)
      .subscribe((res) => {
        this.dataO3 = res;
        this.data3 = new MatTableDataSource<PeriodicElement>(this.dataO2);
        this.state3 = false;
        this.loading3 = false;
      });
  };

  get firstNameInvalid() {
    return (
      this.profileForm.get("firstName").invalid &&
      this.profileForm.get("firstName").touched
    );
  }

  /*get secondNameInvalid() {
    return (
      this.profileForm.get("secondName").invalid &&
      this.profileForm.get("secondName").touched
    );
  }*/
  get lastNameInvalid() {
    return (
      this.profileForm.get("lastName").invalid &&
      this.profileForm.get("lastName").touched
    );
  }
  /*get secondLastNameInvalid() {
    return (
      this.profileForm.get("secondLastName").invalid &&
      this.profileForm.get("secondLastName").touched
    );
  }*/
  get emailInvalid() {
    return (
      this.profileForm.get("email").invalid &&
      this.profileForm.get("email").touched
    );
  }

  /*get secondaryEmailInvalid() {
    return (
      this.profileForm.get("secondaryEmail").invalid &&
      this.profileForm.get("secondaryEmail").touched
    );
  }

  get stateInvalid() {
    return (
      this.profileForm.get("address.state").invalid &&
      this.profileForm.get("address.state").touched
    );
  }

  get cityInvalid() {
    return (
      this.profileForm.get("address.city").invalid &&
      this.profileForm.get("address.city").touched
    );
  }

  get zipCodeInvalid() {
    return (
      this.profileForm.get("address.zipCode").invalid &&
      this.profileForm.get("address.zipCode").touched
    );
  }

  get homePhoneInvalid() {
    return (
      this.profileForm.get("homePhone").invalid &&
      this.profileForm.get("homePhone").touched
    );
  }

  get cellPhoneInvalid() {
    return (
      this.profileForm.get("cellPhone").invalid &&
      this.profileForm.get("cellPhone").touched
    );
  }

  get workPhoneInvalid() {
    return (
      this.profileForm.get("workPhone").invalid &&
      this.profileForm.get("workPhone").touched
    );
  }

  get dateOfBirthInvalid() {
    return (
      this.profileForm.get("dateOfBirth").invalid &&
      this.profileForm.get("dateOfBirth").touched
    );
  }

  get emergencyContactInvalid() {
    return (
      this.profileForm.get("emergencyContactName").invalid &&
      this.profileForm.get("emergencyContactName").touched
    );
  }

  get emergencyContactPhoneInvalid() {
    return (
      this.profileForm.get("emergencyContactPhone").invalid &&
      this.profileForm.get("emergencyContactPhone").touched
    );
  }

  get usVeteranInvalid() {
    return (
      this.profileForm.get("usVeteran").invalid &&
      this.profileForm.get("usVeteran").touched
    );
  }

  get countryOfCitizenshipInvalid() {
    return (
      this.profileForm.get("countryOfCitizenship").invalid &&
      this.profileForm.get("countryOfCitizenship").touched
    );
  }

  get accountStartDateContractInvalid() {
    return (
      this.editSchool.get("accountStartDateContract").invalid &&
      this.editSchool.get("accountStartDateContract").touched
    );
  }

  get accountContractExpirationDateInvalid() {
    return (
      this.editSchool.get("accountContractExpirationDate").invalid &&
      this.editSchool.get("accountContractExpirationDate").touched
    );
  }

  get accountOwnerPersonalNameInvalid() {
    return (
      this.editSchool.get("accountOwnerPersonalName").invalid &&
      this.editSchool.get("accountOwnerPersonalName").touched
    );
  }

  get accountOwnerPersonalEmailInvalid() {
    return (
      this.editSchool.get("accountOwnerPersonalEmail").invalid &&
      this.editSchool.get("accountOwnerPersonalEmail").touched
    );
  }

  get accountOwnerPersonalPhoneInvalid() {
    return (
      this.editSchool.get("accountOwnerPersonalPhone").invalid &&
      this.editSchool.get("accountOwnerPersonalPhone").touched
    );
  }

  get accountOwnerCompanyNameInvalid() {
    return (
      this.editSchool.get("accountOwnerCompanyName").invalid &&
      this.editSchool.get("accountOwnerCompanyName").touched
    );
  }

  get DBANameInvalid() {
    return (
      this.editSchool.get("DBAName").invalid &&
      this.editSchool.get("DBAName").touched
    );
  }

  get URLInvalid() {
    return (
      this.editSchool.get("urlId").invalid &&
      this.editSchool.get("urlId").touched
    );
  }*/

  createForm() {
    this.editSchool = this.fb.group({
      accountStartDateContract: ["", [Validators.required]],
      accountContractExpirationDate: ["", [Validators.required]],
      accountLevelType: [""],
      accountOwnerPersonalName: ["", [Validators.required]],
      accountOwnerPersonalEmail: ["", [Validators.required, Validators.email]],
      accountOwnerPersonalPhone: [
        "",
        [Validators.required, Validators.pattern("^[0-9]{3}[0-9]{3}[0-9]{4}$")],
      ],
      accountOwnerCompanyName: ["", [Validators.required]],
      DBAName: ["", Validators.required],
      urlId: ["", Validators.required],
    });
  }

  validateForm() {
    this.profileForm = this.fb.group({
      firstName: ["", [Validators.required, Validators.minLength(3)]],
      secondName: ["", []],
      lastName: ["", [Validators.required, Validators.minLength(3)]],
      secondLastName: ["", []],
      dateOfBirth: ["", []],
      homePhone: ["", []],
      cellPhone: ["", []],
      workPhone: ["", []],
      email: ["", [Validators.required, Validators.email]],
      secondaryEmail: ["", []],
      emergencyContactName: ["", []],
      emergencyContactPhone: ["", []],
      countryOfCitizenship: ["", []],
      usVeteran: ["", []],
      address: this.fb.group({
        state: ["", []],
        city: ["", []],
        zipCode: ["", []],
      }),
    });
  }

  onFileChanged(event: any) {
    if (event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
      this.fileName = this.file.name;
    }
  }

  save() {
    if (this.file) {
      if (this.role != "campus") {
        this.val = this.id;
      } else {
        this.val = "";
      }
      this.object = { id: this.val, file: this.file, bucket: this.bucket };
      this.documentService.uploadFile(this.object).subscribe((response) => {
        this.responseFile = response;
        if (this.responseFile.status == "ok") {
          if (this.role == "campus") {
            this.data = this.editSchool.value;
            this.data = Object.assign(this.data, {
              logo: this.responseFile.file,
            });
            this.api.updateSchool(this.id, this.data).subscribe((data) => {
              this.afterUpdate();
              // this.auth.logout();
            });
          } else {
            this.data = this.profileForm.value;
            this.data = Object.assign(this.data, {
              picture3: this.file,
            });
            if (this.profileForm.invalid) {
              return Object.values(this.profileForm.controls).forEach(
                (control) => {
                  if (control instanceof FormGroup) {
                    Object.values(control.controls).forEach((control) =>
                      control.markAsTouched()
                    );
                  } else {
                    control.markAsTouched();
                  }
                }
              );
            }
            this.data = this.profileForm.value;
            this.data = Object.assign(this.data, {
              picture: this.responseFile.file,
            });
            this.userService
              .updateProfile(this.data, this.id)
              .subscribe((data) => {
                this.showToast("Message", "Profile updated!", "success");
                this.afterUpdate();
                // this.auth.logout();
              });
          }
        }
      });
    } else {
      if (this.role == "campus") {
        this.api
          .updateSchool(this.id, this.editSchool.value)
          .subscribe((data) => {
            this.afterUpdate();
            // this.auth.logout();
          });
      } else {
        if (this.profileForm.invalid) {
          return Object.values(this.profileForm.controls).forEach((control) => {
            if (control instanceof FormGroup) {
              Object.values(control.controls).forEach((control) =>
                control.markAsTouched()
              );
            } else {
              control.markAsTouched();
            }
          });
        }
        this.userService
          .updateProfile(this.profileForm.value, this.id)
          .subscribe((data) => {
            this.showToast("Message", "Profile updated!", "success");
            this.afterUpdate();
            // this.auth.logout();
          });
      }
    }
  }

  dismiss(event: boolean) {
    // this.statusP.emit(false);
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  drive(val) {
    this.columns2 = [
      "select",
      "name",
      "submittedBy",
      "dateSubmitted",
      "renewalRequired",
      "renewBy",
      "campusID",
    ];
    this.documentService
      .getListDocumentsByDrive(val, `/${this.schoolId}`)
      .subscribe((data) => {
        this.loading2 = true;
        this.dataO2 = data;

        this.state1 = true;
        this.driveS1 = val;
        this.loading2 = false;
      });
  }

  afterUpdate() {
    this.show = true;
    this.create = true;
    this.class = "fade-in";

    setTimeout(() => {
      this.class = "fadeOut";
      this.show = false;
    }, 5000);
  }

  showToast(title, message, status) {
    this.toastrService.show(message, title, { status });
  }
}
