import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { usersService } from "../../@core/api/base-api/users.api";
import { DocumentsService } from "../../@core/api/base-api/documents.api";
import { AuthService } from "../../@auth/auth.service";
import * as Cookie from "js-cookie";
import { NbToastrService } from "@nebular/theme";
import { SchoolsService } from "../../@core/api/base-api/schools.api";

@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.scss"],
})
export class EditUserComponent implements OnInit, OnChanges {
  @Input() call: boolean;
  @Input() idE: string;
  @Input() schoolE: string;
  @Input() typeP: string;
  @Output() statusP: EventEmitter<any> = new EventEmitter<any>();
  roles: any = [];
  titlesName: any = [];
  selectedOption: string;
  printedOption: string;
  editForm: FormGroup;
  userId: string;
  firstName: string;
  secondName: string;
  lastName: string;
  secondLastName: string;
  userTitleNames: string;
  userTitleNames2: string;
  systemAccess: boolean;
  dateOfBirth: string;
  state: string;
  city: string;
  zipCode: number;
  homePhone: number;
  workPhone: number;
  cellPhone: number;
  email: string;
  secondaryEmail: string;
  emergencyContactName: string;
  emergencyContactPhone: number;
  countryOfCitizenship: string;
  usVeteran: boolean;
  programSelection: string;
  expectedGraduationDate: string;
  programStartDate: string;
  cohortName: string;
  hiredDate: string;
  role: string;
  schoolId: string;
  id: string;
  selectedStatus: any = [];
  admin: string;
  printv: boolean;
  rolesSelected: [];
  loading: boolean;
  file: File;
  fileName: string;
  data: {};
  object: {};
  bucket: string;
  picture: string;
  responseFile: any;
  public date1 = new Date();
  public date2 = new Date();
  public date3 = new Date();
  public date4 = new Date();
  public dropdownsYes = [
    { key: "", value: "System Status" },
    { key: "Active", value: "Active" },
    { key: "Pre-Enrolled", value: "Pre-Enrolled" },
    { key: "Graduate", value: "Graduate" },
    { key: "Completer", value: "Completer" },
  ];
  public dropdownsNo = [
    { key: "", value: "System Status" },
    { key: "Withdrawn", value: "Withdrawn" },
    { key: "ADMIN Withdrawn", value: "ADMIN Withdrawn" },
    { key: "Financial Issue", value: "Financial Issue" },
    { key: "Leave of Absense", value: "Leave of Absense" },
    { key: "ADMIN Faculty-Leave", value: "ADMIN Faculty-Leave" },
    { key: "Archive", value: "Archive" },
    { key: "Delete", value: "Delete" },
  ];
  cView: boolean;
  cData: any = [];
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private userService: usersService,
    private auth: AuthService,
    private toastrService: NbToastrService,
    private documentService: DocumentsService,
    private apiS: SchoolsService
  ) {
    this.validateForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.idE && changes.idE) {
      if (changes.idE.currentValue != changes.idE.previousValue) {
        this.auth.getUser$().subscribe((data) => {
          this.userId = changes.idE.currentValue;
          this.schoolId = changes.schoolE.currentValue;
          this.getUser();
        });
      }
    }
  }

  ngOnInit() {
    this.loading = false;
    this.cView = true;
    this.id = localStorage.getItem("urlId");
    this.schoolId = this.route.snapshot.paramMap.get("schoolId");
    this.userId = this.route.snapshot.paramMap.get("userId");
    this.userService.getRoles().subscribe((data) => {
      this.roles = data;
    });

    if (this.id == "admin") {
      this.admin = "/admin";
    }
    const value =
      this.admin === "/admin"
        ? this.id
        : this.route.snapshot.paramMap.get("id");
    this.apiS.getTenant(value).subscribe((data) => {
      this.bucket = data["bucket"];
    });

    if (!this.userId && !this.call) {
      this.router.navigate(["users"]);
      return;
    }
    if (!this.call) {
      this.userId = this.route.snapshot.paramMap.get("userId");
      this.getUser();
    }
    if (this.typeP === "view") {
      this.editForm.disable();
    }
  }

  getUser() {
    this.auth.getUser$().subscribe((data) => {
      this.role = data["https://cor4edu.com/roles"][0];
      if (this.role == "cor-admin" || this.role == "ag-admin-us") {
        this.userService
          .getUserById(this.userId, `/${this.schoolId}`, this.admin)
          .subscribe((data) => {
            this.userService
              .getUserTitleNames(`/${this.schoolId}`, this.admin)
              .subscribe((res) => {
                this.titlesName = res;
                this.setData(data);
              });
          });
      } else {
        this.userService.getUserById(this.userId).subscribe((data) => {
          this.userService.getUserTitleNames().subscribe((res) => {
            this.titlesName = res;
            this.setData(data);
          });
        });
      }
    });
  }

  print() {
    window.print();
    this.printv = false;
    this.dismiss(false);
  }

  setData(data) {
    this.cData = data;
    this.editForm.get("firstName").setValue(data["firstName"]);
    this.editForm.get("secondName").setValue(data["secondName"]);
    this.editForm.get("lastName").setValue(data["lastName"]);
    this.editForm.get("secondLastName").setValue(data["secondLastName"]);
    this.editForm.get("userTitleName").setValue(data["userTitleName"]);
    if (this.role == "cor-admin" || this.role == "ag-admin-us") {
      this.userService
        .getUserTitleName(
          data["userTitleName"],
          `/${this.schoolId}`,
          this.admin
        )
        .subscribe((res) => {
          this.editForm
            .get("roles")
            .setValue(data["roles"] ? data["roles"] : []);
          Object.assign(this.cData, { userTitleName: res["name"] });
        });
    } else {
      this.userService
        .getUserTitleName(data["userTitleName"])
        .subscribe((res) => {
          this.editForm
            .get("roles")
            .setValue(data["roles"] ? data["roles"] : []);
          Object.assign(this.cData, { userTitleName: res["name"] });
        });
    }

    this.editForm.get("systemAccess").setValue(data["systemAccess"]);
    if (data["systemAccess"]) {
      this.selectedStatus = this.dropdownsYes;
    } else {
      this.selectedStatus = this.dropdownsNo;
    }
    this.editForm.get("systemStatus").setValue(data["systemStatus"]);
    this.date1 = new Date(data["dateOfBirth"]);
    this.editForm.get("dateOfBirth").setValue(this.date1);
    this.editForm.get("address.city").setValue(data["address"].city);
    this.editForm.get("address.state").setValue(data["address"].state);
    this.editForm.get("address.zipCode").setValue(data["address"].zipCode);
    this.editForm.get("homePhone").setValue(data["homePhone"]);
    this.editForm.get("cellPhone").setValue(data["cellPhone"]);
    this.editForm.get("workPhone").setValue(data["workPhone"]);
    this.editForm.get("email").setValue(data["email"]);
    this.editForm.get("secondaryEmail").setValue(data["secondaryEmail"]);
    this.editForm
      .get("emergencyContactName")
      .setValue(data["emergencyContactName"]);
    this.editForm
      .get("emergencyContactPhone")
      .setValue(data["emergencyContactPhone"]);
    this.editForm
      .get("countryOfCitizenship")
      .setValue(data["countryOfCitizenship"]);
    this.editForm.get("usVeteran").setValue(data["usVeteran"]);
    this.editForm.get("programSelection").setValue(data["programSelection"]);
    this.date2 = new Date(data["expectedGraduationDate"]);
    this.editForm.get("expectedGraduationDate").setValue(this.date2);
    this.date3 = new Date(data["programStartDate"]);
    this.editForm.get("programStartDate").setValue(this.date3);
    this.editForm.get("cohortName").setValue(data["cohortName"]);
    this.date4 = new Date(data["hiredDate"]);
    this.editForm.get("hiredDate").setValue(this.date4);
    if (this.typeP === "print") {
      this.printv = true;
    }
    this.documentService
      .getFile(
        encodeURIComponent(`${this.bucket}`),
        encodeURIComponent(`${this.userId}/${data["picture"]}`)
      )
      .subscribe((data) => {
        this.picture = data["url"];
      });
  }
  get firstNameInvalid() {
    return (
      this.editForm.get("firstName").invalid &&
      this.editForm.get("firstName").touched
    );
  }

  get secondNameInvalid() {
    return (
      this.editForm.get("secondName").invalid &&
      this.editForm.get("secondName").touched
    );
  }
  get lastNameInvalid() {
    return (
      this.editForm.get("lastName").invalid &&
      this.editForm.get("lastName").touched
    );
  }
  get secondLastNameInvalid() {
    return (
      this.editForm.get("secondLastName").invalid &&
      this.editForm.get("secondLastName").touched
    );
  }
  get emailInvalid() {
    return (
      this.editForm.get("email").invalid && this.editForm.get("email").touched
    );
  }

  get secondaryEmailInvalid() {
    return (
      this.editForm.get("secondaryEmail").invalid &&
      this.editForm.get("secondaryEmail").touched
    );
  }

  get stateInvalid() {
    return (
      this.editForm.get("address.state").invalid &&
      this.editForm.get("address.state").touched
    );
  }

  get cityInvalid() {
    return (
      this.editForm.get("address.city").invalid &&
      this.editForm.get("address.city").touched
    );
  }

  get zipCodeInvalid() {
    return (
      this.editForm.get("address.zipCode").invalid &&
      this.editForm.get("address.zipCode").touched
    );
  }

  get homePhoneInvalid() {
    return (
      this.editForm.get("homePhone").invalid &&
      this.editForm.get("homePhone").touched
    );
  }

  get cellPhoneInvalid() {
    return (
      this.editForm.get("cellPhone").invalid &&
      this.editForm.get("cellPhone").touched
    );
  }

  get workPhoneInvalid() {
    return (
      this.editForm.get("workPhone").invalid &&
      this.editForm.get("workPhone").touched
    );
  }

  get dateOfBirthInvalid() {
    return (
      this.editForm.get("dateOfBirth").invalid &&
      this.editForm.get("dateOfBirth").touched
    );
  }

  // get expectedGraduationDateInvalid(){
  //   return this.editForm.get('expectedGraduationDate').invalid && this.editForm.get('expectedGraduationDate').touched
  // }

  // get programStartDateInvalid(){
  //   return this.editForm.get('programStartDate').invalid && this.editForm.get('programStartDate').touched
  // }

  // get hiredDateInvalid(){
  //   return this.editForm.get('hiredDate').invalid && this.addForm.get('hiredDate').touched
  // }

  get emergencyContactInvalid() {
    return (
      this.editForm.get("emergencyContactName").invalid &&
      this.editForm.get("emergencyContactName").touched
    );
  }

  get emergencyContactPhoneInvalid() {
    return (
      this.editForm.get("emergencyContactPhone").invalid &&
      this.editForm.get("emergencyContactPhone").touched
    );
  }
  get rolesInvalid() {
    return (
      this.editForm.get("roles").invalid && this.editForm.get("roles").touched
    );
  }

  get userTitleNameInvalid() {
    return (
      this.editForm.get("userTitleName").invalid &&
      this.editForm.get("userTitleName").touched
    );
  }

  get systemAccessInvalid() {
    return (
      this.editForm.get("systemAccess").invalid &&
      this.editForm.get("systemAccess").touched
    );
  }

  get systemStatusInvalid() {
    return (
      this.editForm.get("systemStatus").invalid &&
      this.editForm.get("systemStatus").touched
    );
  }

  get usVeteranInvalid() {
    return (
      this.editForm.get("usVeteran").invalid &&
      this.editForm.get("usVeteran").touched
    );
  }

  get countryOfCitizenshipInvalid() {
    return (
      this.editForm.get("countryOfCitizenship").invalid &&
      this.editForm.get("countryOfCitizenship").touched
    );
  }

  validateForm() {
    this.editForm = this.fb.group({
      firstName: ["", [Validators.required, Validators.minLength(3)]],
      secondName: ["", [Validators.required, Validators.minLength(3)]],
      lastName: ["", [Validators.required, Validators.minLength(3)]],
      secondLastName: ["", [Validators.required, Validators.minLength(3)]],
      roles: [[], [Validators.required]],
      userTitleName: ["", [Validators.required]],
      systemAccess: ["", [Validators.required]],
      systemStatus: ["", [Validators.required]],
      dateOfBirth: ["", [Validators.required]],
      homePhone: ["", [Validators.pattern("^[0-9]{3}[0-9]{3}[0-9]{4}$")]],
      cellPhone: [
        "",
        [Validators.required, Validators.pattern("^[0-9]{3}[0-9]{3}[0-9]{4}$")],
      ],
      workPhone: ["", [Validators.pattern("^[0-9]{3}[0-9]{3}[0-9]{4}$")]],
      email: ["", [Validators.required, Validators.email]],
      secondaryEmail: ["", [Validators.email]],
      emergencyContactName: [
        "",
        [Validators.required, Validators.minLength(3)],
      ],
      emergencyContactPhone: [
        "",
        [Validators.required, Validators.pattern("^[0-9]{3}[0-9]{3}[0-9]{4}$")],
      ],
      countryOfCitizenship: [
        "",
        [Validators.required, Validators.minLength(3)],
      ],
      usVeteran: ["", [Validators.required]],
      programSelection: ["", []],
      expectedGraduationDate: ["", []],
      programStartDate: ["", []],
      cohortName: ["", []],
      hiredDate: ["", []],
      address: this.fb.group({
        state: ["", [Validators.required]],
        city: ["", [Validators.required]],
        zipCode: ["", [Validators.required]],
      }),
    });
  }
  dismiss(event: boolean) {
    this.statusP.emit(false);
  }

  save() {
    this.loading = true;
    if (this.file) {
      this.object = { id: this.userId, file: this.file, bucket: this.bucket };
      this.documentService.uploadFile(this.object).subscribe((response) => {
        this.responseFile = response;
        if (response["status"] == "ok") {
          Object.assign(this.editForm.value, {
            picture: response["file"],
          });
          if (this.role == "cor-admin" || this.role == "ag-admin-us") {
            this.userService
              .updateUser(
                this.editForm.value,
                this.userId,
                `/${this.schoolId}`,
                this.admin
              )
              .pipe(first())
              .subscribe(
                (data) => {
                  this.loading = false;
                  this.showToast(
                    "Message",
                    "User Information Saved!",
                    "success"
                  );
                  if (!this.call) {
                    this.router.navigate([
                      "/",
                      this.id,
                      "users",
                      this.schoolId,
                    ]);
                  }
                  this.statusP.emit(true);
                },
                (error) => {
                  alert(error);
                }
              );
          } else {
            this.userService
              .updateUser(this.editForm.value, this.userId)
              .pipe(first())
              .subscribe(
                (data) => {
                  this.loading = false;
                  this.showToast(
                    "Message",
                    "User Information Saved!",
                    "success"
                  );
                  if (!this.call) {
                    this.router.navigate(["/", this.id, "users"]);
                  }
                  this.statusP.emit(true);
                },
                (error) => {
                  alert(error);
                }
              );
          }
        }
      });
    } else {
      if (this.role == "cor-admin" || this.role == "ag-admin-us") {
        this.userService
          .updateUser(
            this.editForm.value,
            this.userId,
            `/${this.schoolId}`,
            this.admin
          )
          .pipe(first())
          .subscribe(
            (data) => {
              this.loading = false;
              this.showToast("Message", "User Information Saved!", "success");
              if (!this.call) {
                this.router.navigate(["/", this.id, "users", this.schoolId]);
              }
              this.statusP.emit(true);
            },
            (error) => {
              alert(error);
            }
          );
      } else {
        this.userService
          .updateUser(this.editForm.value, this.userId)
          .pipe(first())
          .subscribe(
            (data) => {
              this.loading = false;
              this.showToast("Message", "User Information Saved!", "success");
              if (!this.call) {
                this.router.navigate(["/", this.id, "users"]);
              }
              this.statusP.emit(true);
            },
            (error) => {
              alert(error);
            }
          );
      }
    }
  }

  onFileChanged(event: any) {
    if (event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
      this.fileName = this.file.name;
    }
  }

  changedTitleName(id) {
    if (this.role == "cor-admin" || this.role == "ag-admin-us") {
      this.userService
        .getUserTitleName(id, `/${this.schoolId}`, this.admin)
        .subscribe((data) => {
          this.rolesSelected = data["roles"];
        });
    } else {
      this.userService.getUserTitleName(id).subscribe((data) => {
        this.rolesSelected = data["roles"];
      });
    }
  }

  onChange($event) {
    if ($event) {
      this.selectedStatus = this.dropdownsYes;
    } else {
      this.selectedStatus = this.dropdownsNo;
    }
    this.editForm.controls.systemStatus.setValue("");
  }

  goEdit() {
    this.cView = false;
  }

  showToast(title, message, status) {
    this.toastrService.show(message, title, { status });
  }
}
