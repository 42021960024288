import { Component, OnInit, ViewChild } from "@angular/core";
import { DocumentsService } from "../@core/api/base-api/documents.api";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../@auth/auth.service";
import { Location } from "@angular/common";
import { NbDialogService, NbToastrService, NbIconConfig } from "@nebular/theme";
import { WindowDeleteConfirmComponent } from "../shared/window-delete-confirm/window-delete-confirm.component";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-forms",
  templateUrl: "./forms.component.html",
  styleUrls: ["./forms.component.scss"],
})
export class FormsComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  data: any = [];
  drive: string;
  school: string;
  schoolId: string;
  admin: string;
  role: any;
  edit: boolean;
  del: boolean;
  add: boolean;
  loading: boolean;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ["name", "options"];

  constructor(
    private api: DocumentsService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private location: Location,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.drive = this.route.snapshot.paramMap.get("drive");
    this.school = this.route.snapshot.paramMap.get("id");
    this.schoolId = localStorage.getItem("urlId");

    this.add = this.auth.permissions.includes("create:forms");
    this.edit = this.auth.permissions.includes("update:forms");
    this.del = this.auth.permissions.includes("delete:forms");

    if (this.schoolId == "admin") {
      this.admin = "/admin";
    }

    this.auth.getUser$().subscribe((data) => {
      this.role = data["https://cor4edu.com/roles"][0];
    });
    this.refresh();
  }

  delete(form) {
    this.dialogService
      .open(WindowDeleteConfirmComponent, {
        context: {
          title: "form",
          name: form.name,
        },
      })
      .onClose.subscribe((val) => {
        if (val) {
          if (this.role == "cor-admin" || this.role == "ag-admin-us") {
            this.api.deleteForm(form._id, this.school, this.admin).subscribe(
              (data) => {
                this.showToast(
                  "Message",
                  "Form Deleted!",
                  "success",
                  "checkmark-outline"
                );
                this.refresh();
              },
              (err) => {
                this.showToast(
                  "Message",
                  "An error occurred, please try again",
                  "danger",
                  "alert-circle-outline"
                );
              }
            );
          } else {
            this.api.deleteForm(form._id).subscribe(
              (data) => {
                this.showToast(
                  "Message",
                  "Form Updated!",
                  "success",
                  "checkmark-outline"
                );
                this.refresh();
              },
              (err) => {
                this.showToast(
                  "Message",
                  "An error occurred, please try again",
                  "danger",
                  "alert-circle-outline"
                );
              }
            );
          }
        }
      });
  }

  refresh() {
    const value = this.admin === "/admin" ? this.school : "";
    this.api.listForms(value, this.admin).subscribe((data) => {
      this.data = data;
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  goBack() {
    this.location.back();
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
