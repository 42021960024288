import { Component, OnInit } from "@angular/core";
import { AuthService } from "../@auth/auth.service";
import { SchoolService } from "../@core/services/school.service";
import { usersService } from "../@core/api/base-api/users.api";
import { ActivatedRoute, Router } from "@angular/router";
import * as Cookie from "js-cookie";

@Component({
  selector: "app-frontend",
  templateUrl: "./frontend.component.html",
  styleUrls: ["./frontend.component.scss"],
})
export class FrontendComponent implements OnInit {
  schoolId: string;
  userId: string;
  id: string;
  reload: boolean;
  reload2: boolean;
  loading: boolean;
  role: string;
  constructor(
    public auth: AuthService,
    private route: ActivatedRoute,
    private schoolService: SchoolService,
    private router: Router,
    private userService: usersService
  ) {}
  ngOnInit() {
    // this.schoolId = this.route.snapshot.paramMap.get("id");
    this.id = localStorage.getItem("urlId");
    if (this.id == undefined) this.id = "admin";
    this.loading = true;
    this.auth.isAuthenticated$.subscribe((data) => {
      if (data) {
        this.loading = false;
        setTimeout(() => {
          this.router.navigate([this.id]);
        }, 3000);
      } else {
        setTimeout(() => {
          this.router.navigate([this.id, "login"]);
        }, 3000);
      }
    });
    // this.getUser();
    this.reload = false;
    this.reload2 = false;
  }

  val() {
    return this.schoolService.getValue();
  }

  getUser() {
    this.auth.getUser$().subscribe((data) => {
      if (data) {
        this.role = data["https://cor4edu.com/roles"][0];
        if (
          this.role == "cor-admin" ||
          this.role == "ag-admin-us" ||
          this.role == "campus"
        ) {
          this.userService
            .getUserById(this.userId, `/${this.schoolId}`)
            .subscribe((data) => {});
        } else {
          this.userService.getUserById(this.userId).subscribe((data) => {});
        }
      }
    });
  }
}
