import { Injectable } from "@angular/core";
import * as Cookie from "js-cookie";

@Injectable({
  providedIn: "root",
})
export class SchoolService {
  private myValue;
  role: string;

  constructor() {
    this.myValue = localStorage.getItem('urlId');
  }

  setValue(val) {
    this.myValue = localStorage.getItem('urlId');
  }

  getValue() {
    return this.myValue;
  }
}
