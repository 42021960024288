import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { DndDropEvent, DropEffect } from "ngx-drag-drop";
import { field, value } from "../../@core/model/form-model";
// import { ActivatedRoute } from "@angular/router";
import Swal from "sweetalert2";
import { DocumentsService } from "../../@core/api/base-api/documents.api";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../@auth/auth.service";
import { Location } from "@angular/common";
import { NbToastrService, NbIconConfig } from "@nebular/theme";

@Component({
  selector: "app-edit-form",
  templateUrl: "./edit-form.component.html",
  styleUrls: ["./edit-form.component.scss"],
})
export class EditFormComponent implements OnInit {
  @Input() call: boolean;
  @Input() idE: string;
  @Input() schoolE: string;
  @Input() typeP: string;
  @Output() statusP: EventEmitter<any> = new EventEmitter<any>();
  object: {};
  formId: String;
  form: {};
  list: [];
  loading: boolean;
  schoolId: string;
  value: value = {
    label: "",
    value: "",
  };
  success = false;

  fieldModels: Array<field> = [
    {
      type: "text",
      icon: "fa-font",
      label: "Text",
      description: "Enter your name",
      placeholder: "Enter your name",
      className: "form-control",
      subtype: "text",
      regex: "",
      handle: true,
    },
    {
      type: "email",
      icon: "fa-envelope",
      required: true,
      label: "Email",
      description: "Enter your email",
      placeholder: "Enter your email",
      className: "form-control",
      subtype: "text",
      regex: "^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_.-]+).([a-zA-Z]{2,5})$",
      errorText: "Please enter a valid email",
      handle: true,
    },
    {
      type: "phone",
      icon: "fa-phone",
      label: "Phone",
      description: "Enter your phone",
      placeholder: "Enter your phone",
      className: "form-control",
      subtype: "text",
      regex: "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$",
      errorText: "Please enter a valid phone number",
      handle: true,
    },
    {
      type: "number",
      label: "Number",
      icon: "fa-html5",
      description: "Age",
      placeholder: "Enter your age",
      className: "form-control",
      min: 12,
      max: 90,
    },
    {
      type: "date",
      icon: "fa-calendar",
      label: "Date",
      placeholder: "Date",
      className: "form-control",
    },
    {
      type: "datetime-local",
      icon: "fa-calendar",
      label: "DateTime",
      placeholder: "Date Time",
      className: "form-control",
    },
    {
      type: "textarea",
      icon: "fa-text-width",
      label: "Textarea",
    },
    {
      type: "checkbox",
      required: true,
      label: "Checkbox",
      icon: "fa-list",
      description: "Checkbox",
      inline: true,
      values: [
        {
          label: "Option 1",
          value: "option-1",
        },
        {
          label: "Option 2",
          value: "option-2",
        },
      ],
    },
    {
      type: "radio",
      icon: "fa-list-ul",
      label: "Radio",
      description: "Radio boxes",
      values: [
        {
          label: "Option 1",
          value: "option-1",
        },
        {
          label: "Option 2",
          value: "option-2",
        },
      ],
    },
    {
      type: "autocomplete",
      icon: "fa-bars",
      label: "Select",
      description: "Select",
      placeholder: "Select",
      className: "form-control",
      values: [
        {
          label: "Option 1",
          value: "option-1",
        },
        {
          label: "Option 2",
          value: "option-2",
        },
        {
          label: "Option 3",
          value: "option-3",
        },
      ],
    },
    {
      type: "button",
      icon: "fa-paper-plane",
      subtype: "submit",
      label: "Submit",
    },
  ];

  modelFields: Array<field> = [];
  // data = Object.assign(this.data);
  model: any = {
    name: String,
    attributes: this.modelFields,
  };

  report = false;
  reports: any = [];
  admin: string;
  school: string;

  constructor(
    private api: DocumentsService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private _location: Location,
    private toastrService: NbToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.idE && changes.idE) {
      if (changes.idE.currentValue != changes.idE.previousValue) {
        this.auth.getUser$().subscribe((data) => {
          this.formId = changes.idE.currentValue;
          this.schoolId = changes.schoolE.currentValue;
          this.getForms();
        });
      }
    }
  }

  ngOnInit() {
    this.loading = false;
    this.schoolId = localStorage.getItem("urlId");
    this.school = this.route.snapshot.paramMap.get("schoolId");

    if (this.schoolId == "admin") {
      this.admin = "/admin";
    }
    if (!this.call) {
      this.formId = this.route.snapshot.paramMap.get("id");
    }
    if (!this.formId && !this.call) {
      this.router.navigate(["/"]);
      return;
    }
    if (!this.call) {
      this.getForms();
    }
  }

  getForms() {
    const value = this.admin === "/admin" ? this.school : "";
    this.api.getForm(this.formId, value, this.admin).subscribe((data) => {
      this.model.attributes = data["form"];
      this.model.name = data["name"];
      this.list = data["form"];
    });
  }

  onDragStart(event: DragEvent) {
    // console.log("drag started", JSON.stringify(event, null, 2));
  }

  onDragEnd(event: DragEvent) {
    // console.log("drag ended", JSON.stringify(event, null, 2));
  }

  onDraggableCopied(event: DragEvent) {
    // console.log("draggable copied", JSON.stringify(event, null, 2));
  }

  onDraggableLinked(event: DragEvent) {
    // console.log("draggable linked", JSON.stringify(event, null, 2));
  }

  onDragged(item: any, list: any[], effect: DropEffect) {
    if (effect === "move") {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }

  onDragCanceled(event: DragEvent) {
    // console.log("drag cancelled", JSON.stringify(event, null, 2));
  }

  onDragover(event: DragEvent) {
    // console.log("dragover", JSON.stringify(event, null, 2));
  }

  onDrop(event: DndDropEvent, list?: any[]) {
    if (list && (event.dropEffect === "copy" || event.dropEffect === "move")) {
      if (event.dropEffect === "copy")
        event.data.name = event.data.type + "-" + new Date().getTime();
      let index = event.index;
      if (typeof index === "undefined") {
        index = list.length;
      }
      list.splice(index, 0, event.data);
    }
  }

  addValue(values) {
    values.push(this.value);
    this.value = { label: "", value: "" };
  }

  removeField(i) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to remove this field?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.model.attributes.splice(i, 1);
      }
    });
  }

  show() {}

  updateForm() {
    let input = new FormData();
    input.append("id", this.model._id);
    input.append("name", this.model.name);
    input.append("description", this.model.description);
    input.append("bannerImage", this.model.theme.bannerImage);
    input.append("bgColor", this.model.theme.bgColor);
    input.append("textColor", this.model.theme.textColor);
    input.append("attributes", JSON.stringify(this.model.attributes));
  }

  toggleValue(item) {
    item.selected = !item.selected;
  }

  save() {
    this.loading = true;
    this.object = { name: this.model.name, form: this.model.attributes };
    const value = this.admin === "/admin" ? this.school : "";
    this.api.updateForm(this.formId, this.object, value, this.admin).subscribe(
      (data) => {
        this.loading = false;
        this.showToast(
          "Message",
          "Form Updated!",
          "success",
          "checkmark-outline"
        );
        if (!this.call) {
          this.back();
          // this.router.navigate(["forms"]);
        }
        this.statusP.emit(true);
      },
      (err) => {
        this.loading = false;
        this.showToast(
          "Message",
          "An error occurred, please try again",
          "danger",
          "alert-circle-outline"
        );
      }
    );
  }

  submit() {
    let valid = true;
    let validationArray = JSON.parse(JSON.stringify(this.model.attributes));
    validationArray.reverse().forEach((field) => {
      // console.log(field.label + "=>" + field.required + "=>" + field.value);
      if (field.required && !field.value && field.type != "checkbox") {
        Swal.fire("Error", "Please enter " + field.label, "error");
        valid = false;
        return false;
      }
      if (field.required && field.regex) {
        let regex = new RegExp(field.regex);
        if (regex.test(field.value) == false) {
          Swal.fire("Error", field.errorText, "error");
          valid = false;
          return false;
        }
      }
      if (field.required && field.type == "checkbox") {
        if (field.values.filter((r) => r.selected).length == 0) {
          Swal.fire("Error", "Please enterrr " + field.label, "error");
          valid = false;
          return false;
        }
      }
    });
    if (!valid) {
      return false;
    }
    // console.log("Save", this.model);
    let input = new FormData();
    input.append("formId", this.model._id);
    input.append("attributes", JSON.stringify(this.model.attributes));
    // this.us.postDataApi('/user/formFill',input).subscribe(r=>{
    //   console.log(r);
    //   Swal('Success','You have contact sucessfully','success');
    //   this.success = true;
    // },error=>{
    //   Swal('Error',error.message,'error');
    // });
  }

  back() {
    this._location.back();
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
