import { Component, OnInit, ViewChild } from "@angular/core";
import { DocumentsService } from "../@core/api/base-api/documents.api";
import { AuthService } from "../@auth/auth.service";
import { usersService } from "../@core/api/base-api/users.api";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";

@Component({
  selector: "app-requests-received",
  templateUrl: "./requests-received.component.html",
  styleUrls: ["./requests-received.component.scss"],
})
export class RequestsReceivedComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource();
  school: string;
  data: any = [];
  loading: boolean;
  displayedColumns: string[] = [
    "userData.firstName",
    "dateRequest",
    "respond.status",
    "view",
  ];
  constructor(
    private api: DocumentsService,
    private auth: AuthService,
    private apiU: usersService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.school = localStorage.getItem("urlId");
    this.auth.getUser$().subscribe((res) => {
      this.apiU.getProfileById(res["sub"]).subscribe((res) => {
        this.api.listRequestByUser(res["_id"]).subscribe((data) => {
          this.data = data;
          this.dataSource = new MatTableDataSource(this.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (
            data,
            sortHeaderId: string
          ) => {
            return this.getPropertyByPath(data, sortHeaderId);
          };
          this.loading = false;
          this.dataSource.filterPredicate = (data, filter: string) => {
            const accumulator = (currentTerm, key) => {
              return this.nestedFilterCheck(currentTerm, data, key);
            };
            const dataStr = Object.keys(data)
              .reduce(accumulator, "")
              .toLowerCase();
            // Transform the filter by converting it to lowercase and removing whitespace.
            const transformedFilter = filter.trim().toLowerCase();
            return dataStr.indexOf(transformedFilter) !== -1;
          };
        });
      });
    });
  }

  getPropertyByPath(obj: Object, pathString: string) {
    return pathString.split(".").reduce((o, i) => o[i], obj);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === "object") {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }
}
