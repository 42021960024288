import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Level } from "../../@core/model/school.model";
import { SchoolsService } from "../../@core/api/base-api/schools.api";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../../@auth/auth.service";
import { Location } from "@angular/common";
import { NbToastrService, NbIconConfig } from "@nebular/theme";

@Component({
  selector: "app-add-program",
  templateUrl: "./add-program.component.html",
  styleUrls: ["./add-program.component.scss"],
})
export class AddProgramComponent implements OnInit, OnChanges {
  @Output() status: EventEmitter<any> = new EventEmitter<any>();
  @Input() hidden: boolean;
  @Input() schoolE: string;
  @Input() school: string;
  @Input() call: boolean;
  @Input() tab: string;
  addProgram: FormGroup;
  Level = new Level();
  form: boolean;
  id: string;
  create: boolean;
  class: string;
  role: string;
  schoolId: string;
  courses: any = [];
  admin: string;
  accountNumber: number;
  idSchool: string;
  loading: boolean;
  constructor(
    private fb: FormBuilder,
    private api: SchoolsService,
    private auth: AuthService,
    private location: Location,
    private route: ActivatedRoute,
    private toastrService: NbToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.schoolE && changes.schoolE) {
      if (changes.schoolE.currentValue != changes.schoolE.previousValue) {
        this.auth.getUser$().subscribe((data) => {
          this.school = changes.school.currentValue;
          this.schoolId = changes.schoolE.currentValue;
          if (this.schoolId != "admin") {
            this.id = data["sub"];
            this.role = data["https://cor4edu.com/roles"][0];
            this.getCourses();
          }
        });
      }
    }
  }

  ngOnInit() {
    this.loading = false;
    this.form = false;
    this.schoolId = this.route.snapshot.paramMap.get("schoolId");
    this.school = localStorage.getItem("urlId");
    if (this.school == "admin") {
      this.admin = "/admin";
    }
    this.createForm();
    this.form = true;
    if (!this.call) {
      this.auth.getUser$().subscribe((data) => {
        this.id = data["sub"];
        this.role = data["https://cor4edu.com/roles"][0];

        this.getCourses();
      });
    }
  }

  getCourses() {
    if (this.role == "cor-admin" || this.role == "ag-admin-us") {
      this.api
        .listCourses(`/${this.schoolId}`, this.admin)
        .subscribe((data) => {
          this.courses = data;
        });
    } else {
      this.api.listCourses().subscribe((data) => {
        this.courses = data;
      });
    }
    const value = this.admin === "/admin" ? this.schoolId : this.school;
    this.api.getTenant(value).subscribe((data) => {
      this.idSchool = data["_id"];
    });
  }

  createForm() {
    this.addProgram = this.fb.group({
      credentialIssued: ["", [Validators.required]],
      programTitle: ["", [Validators.required]],
      directorEducationName: ["", [Validators.required]],
      advisoryBoardMandated: ["", [Validators.required]],
      clinicalExternshipHours: ["", [Validators.required]],
      lectureHours: ["", [Validators.required]],
      labHours: ["", [Validators.required]],
      programLength: [
        "",
        [Validators.required, Validators.pattern("^[0-9]*$")],
      ],
      programDeliveryMethod: ["", [Validators.required]],
      programLengthType: ["", [Validators.required]],
      totalAmountWeeksRequiredtoFinishProgram: [
        "",
        [Validators.required, Validators.pattern("^[0-9]*$")],
      ],
      RequireGEDHSDiploma: ["", [Validators.required]],
      requireEntranceExam: ["", [Validators.required]],
      obtainLlicenseAfterGraduation: ["", [Validators.required]],
      accountNumber: [this.accountNumber],
      courses: [[]],
    });
  }

  get credentialIssuedInvalid() {
    return (
      this.addProgram.get("credentialIssued").invalid &&
      this.addProgram.get("credentialIssued").touched
    );
  }

  get programTitleInvalid() {
    return (
      this.addProgram.get("programTitle").invalid &&
      this.addProgram.get("programTitle").touched
    );
  }

  get directorEducationNameInvalid() {
    return (
      this.addProgram.get("directorEducationName").invalid &&
      this.addProgram.get("directorEducationName").touched
    );
  }

  get lectureHoursInvalid() {
    return (
      this.addProgram.get("lectureHours").invalid &&
      this.addProgram.get("lectureHours").touched
    );
  }

  get advisoryBoardMandatedInvalid() {
    return (
      this.addProgram.get("advisoryBoardMandated").invalid &&
      this.addProgram.get("advisoryBoardMandated").touched
    );
  }

  get clinicalExternshipHoursInvalid() {
    return (
      this.addProgram.get("clinicalExternshipHours").invalid &&
      this.addProgram.get("clinicalExternshipHours").touched
    );
  }

  get labHoursInvalid() {
    return (
      this.addProgram.get("labHours").invalid &&
      this.addProgram.get("labHours").touched
    );
  }

  get programLengthInvalid() {
    return (
      this.addProgram.get("programLength").invalid &&
      this.addProgram.get("programLength").touched
    );
  }

  get programDeliveryMethodInvalid() {
    return (
      this.addProgram.get("programDeliveryMethod").invalid &&
      this.addProgram.get("programDeliveryMethod").touched
    );
  }

  get programLengthTypeInvalid() {
    return (
      this.addProgram.get("programLengthType").invalid &&
      this.addProgram.get("programLengthType").touched
    );
  }

  get RequireGEDHSDiplomaInvalid() {
    return (
      this.addProgram.get("RequireGEDHSDiploma").invalid &&
      this.addProgram.get("RequireGEDHSDiploma").touched
    );
  }

  get requireEntranceExamInvalid() {
    return (
      this.addProgram.get("requireEntranceExam").invalid &&
      this.addProgram.get("requireEntranceExam").touched
    );
  }

  get obtainLlicenseAfterGraduationInvalid() {
    return (
      this.addProgram.get("obtainLlicenseAfterGraduation").invalid &&
      this.addProgram.get("obtainLlicenseAfterGraduation").touched
    );
  }

  get totalAmountWeeksRequiredtoFinishProgramInvalid() {
    return (
      this.addProgram.get("totalAmountWeeksRequiredtoFinishProgram").invalid &&
      this.addProgram.get("totalAmountWeeksRequiredtoFinishProgram").touched
    );
  }

  save() {
    this.loading = true;
    if (this.addProgram.invalid) {
      this.loading = false;
      return Object.values(this.addProgram.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }

    if (this.role == "cor-admin" || this.role == "ag-admin-us") {
      const data = Object.assign(this.addProgram.value, {
        schoolId: this.idSchool,
      });
      this.api.createProgram(data, this.schoolId, this.admin).subscribe(
        (res) => {
          this.api
            .updateRelationship(this.schoolId, { programs: [res["_id"]] })
            .subscribe((data) => {
              if (!this.call) {
                this.showToast(
                  "Message",
                  "Program Created!",
                  "success",
                  "checkmark-outline"
                );
                this.loading = false;
                this.location.back();
              } else {
                this.loading = false;
                this.afterCall(res["_id"]);
              }
            });
        },
        (err) => {
          this.loading = false;
          this.showToast(
            "Message",
            "An error occurred, please try again",
            "danger",
            "alert-circle-outline"
          );
        }
      );
    } else {
      const data = Object.assign(this.addProgram.value, {
        schoolId: this.idSchool,
      });
      this.api.createProgram(data).subscribe(
        (res) => {
          this.api
            .updateRelationship(this.schoolId, { programs: [res["_id"]] })
            .subscribe(
              (data) => {
                if (!this.call) {
                  this.showToast(
                    "Message",
                    "Program Created!",
                    "success",
                    "checkmark-outline"
                  );
                  this.loading = false;
                  this.location.back();
                } else {
                  this.loading = false;
                  this.afterCall(res["_id"]);
                }
              },
              (err) => {
                this.loading = false;
                this.showToast(
                  "Message",
                  "An error occurred, please try again",
                  "danger",
                  "alert-circle-outline"
                );
              }
            );
        },
        (err) => {
          this.showToast(
            "Message",
            "An error occurred, please try again",
            "danger",
            "alert-circle-outline"
          );
        }
      );
    }
  }

  afterCall(id) {
    this.create = true;
    this.showToast(
      "Message",
      "Program Created!",
      "success",
      "checkmark-outline"
    );
    this.class = "fade-in";
    this.status.emit([id, "programs"]);
    this.addProgram.reset({ courses: [[]] });
    setTimeout(() => {
      this.class = "fadeOut";
      this.create = false;
    }, 5000);
  }

  goBack() {
    this.location.back();
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
