import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { map } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class SchoolsService {
  baseUrl: string;
  constructor(private httpClient: HttpClient) {
    this.baseUrl = `${environment.apiSchools}`;
  }
  public createSchool(data) {
    return this.httpClient.post(`${this.baseUrl}/schools/`, data);
  }
  public listSchools() {
    return this.httpClient.get(`${this.baseUrl}/schools/`);
  }
  public getSchool(id) {
    return this.httpClient.get(`${this.baseUrl}/schools/${id}/`);
  }
  public deleteSchool(id) {
    return this.httpClient.delete(`${this.baseUrl}/schools/${id}`);
  }
  public updateSchool(id, data) {
    return this.httpClient.put(`${this.baseUrl}/schools/${id}`, data);
  }
  public listCampus(schoolId = "", admin = "") {
    return this.httpClient.get(`${this.baseUrl}/campus${admin}/${schoolId}/`);
  }
  public listCampusAdmin(id) {
    return this.httpClient.get(`${this.baseUrl}/campus/${id}`);
  }
  public listCampusStudent(id) {
    return this.httpClient.get(`${this.baseUrl}/campus/all/student/${id}/`);
  }
  public createCampus(data, schoolId = "", admin = "") {
    return this.httpClient
      .post(`${this.baseUrl}/campus${admin}/${schoolId}`, data)
      .pipe(map((res) => res["data"]));
  }
  public deleteCampus(id, schoolId = "", admin = "") {
    return this.httpClient.delete(
      `${this.baseUrl}/campus${admin}/${id}${schoolId}`
    );
  }
  public getCampus(id, schoolId = "", admin = "") {
    return this.httpClient.get(
      `${this.baseUrl}/campus${admin}${schoolId}/id/${id}/`
    );
  }
  public updateCampus(id, data, schoolId = "", admin = "") {
    return this.httpClient.put(
      `${this.baseUrl}/campus${admin}/${id}${schoolId}`,
      data
    );
  }
  public createProgram(data, schoolId = "", admin = "") {
    return this.httpClient
      .post(`${this.baseUrl}/programs${admin}/${schoolId}`, data)
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }
  public listPrograms(schoolId = "", admin = "") {
    return this.httpClient.get(`${this.baseUrl}/programs${admin}/${schoolId}/`);
  }
  public listProgramsStudent(id) {
    return this.httpClient.get(`${this.baseUrl}/programs/all/student/${id}/`);
  }
  public getProgram(id, schoolId = "", admin = "") {
    return this.httpClient.get(
      `${this.baseUrl}/programs${admin}${schoolId}/id/${id}/`
    );
  }
  public updateProgram(id, data, schoolId = "", admin = "") {
    return this.httpClient.put(
      `${this.baseUrl}/programs${admin}${schoolId}/${id}/`,
      data
    );
  }
  public deleteProgram(id, schoolId = "", admin = "") {
    return this.httpClient.delete(
      `${this.baseUrl}/programs${admin}${schoolId}/${id}`
    );
  }
  public createCourse(data, schoolId = "", admin = "") {
    return this.httpClient
      .post(`${this.baseUrl}/courses${admin}/${schoolId}`, data)
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }
  public listCourses(schoolId = "", admin = "") {
    return this.httpClient.get(`${this.baseUrl}/courses${admin}${schoolId}/`);
  }

  public listCoursesStudent(id) {
    return this.httpClient.get(`${this.baseUrl}/courses/all/student/${id}/`);
  }
  public getCourse(id, schoolId = "", admin = "") {
    return this.httpClient.get(
      `${this.baseUrl}/courses${admin}${schoolId}/id/${id}/`
    );
  }
  public updateCourse(id, data, schoolId = "", admin = "") {
    return this.httpClient.put(
      `${this.baseUrl}/courses${admin}${schoolId}/${id}`,
      data
    );
  }
  public deleteCourse(id, schoolId = "", admin = "") {
    return this.httpClient.delete(
      `${this.baseUrl}/courses${admin}${schoolId}/${id}`
    );
  }
  public getTenant(id) {
    return this.httpClient.get(`${this.baseUrl}/tenant/${id}/`);
  }
  public updateRelationship(id, data) {
    return this.httpClient.put(
      `${this.baseUrl}/schools/relationship/${id}`,
      data
    );
  }
  public deleteRelationship(id, data) {
    return this.httpClient.request(
      "delete",
      `${this.baseUrl}/schools/relationship/${id}`,
      { body: data }
    );
  }

  public updateRelationshipCampus(id, data, admin = "", schoolId = "") {
    return this.httpClient.put(
      `${this.baseUrl}/campus${admin}${schoolId}/relationship/${id}`,
      data
    );
  }
  public deleteRelationshipCampus(id, data, admin = "", schoolId = "") {
    return this.httpClient.request(
      "delete",
      `${this.baseUrl}/campus${admin}${schoolId}/relationship/${id}`,
      { body: data }
    );
  }
  public updateRelationshipPrograms(id, data, admin = "", schoolId = "") {
    return this.httpClient.put(
      `${this.baseUrl}/programs${admin}${schoolId}/relationship/${id}`,
      data
    );
  }
  public deleteRelationshipPrograms(id, data, admin = "", schoolId = "") {
    return this.httpClient.request(
      "delete",
      `${this.baseUrl}/programs${admin}${schoolId}/relationship/${id}`,
      { body: data }
    );
  }
  public updateRelationshipCourses(id, data, admin = "", schoolId = "") {
    return this.httpClient.put(
      `${this.baseUrl}/courses${admin}${schoolId}/relationship/${id}`,
      data
    );
  }
  public deleteRelationshipCourses(id, data, admin = "", schoolId = "") {
    return this.httpClient.request(
      "delete",
      `${this.baseUrl}/courses${admin}${schoolId}/relationship/${id}`,
      { body: data }
    );
  }

  public getAcoountData(query = "", admin = "", schoolId = "") {
    return this.httpClient
      .get(`${this.baseUrl}/schools${admin}${schoolId}/count/metrics/${query}`)
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }
}
