import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Level } from "../../@core/model/school.model";
import { SchoolsService } from "../../@core/api/base-api/schools.api";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { DocumentsService } from "../../@core/api/base-api/documents.api";
import { NbToastrService, NbIconConfig } from "@nebular/theme";

@Component({
  selector: "app-add-school",
  templateUrl: "./add-school.component.html",
  styleUrls: ["./add-school.component.scss"],
})
export class AddSchoolComponent implements OnInit {
  addSchool: FormGroup;
  Level = new Level();
  file: File;
  fileName: string;
  responseFile: any;
  object: {};
  loading: boolean;
  constructor(
    private fb: FormBuilder,
    private api: SchoolsService,
    private router: Router,
    private apiD: DocumentsService,
    private location: Location,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.loading = false;
    this.createForm();
  }

  createForm() {
    this.addSchool = this.fb.group({
      accountStartDateContract: ["", [Validators.required]],
      accountContractExpirationDate: ["", [Validators.required]],
      autoRenew: ["", [Validators.required]],
      accountLevelType: [""],
      accountOwnerPersonalName: ["", [Validators.required]],
      accountOwnerPersonalEmail: ["", [Validators.required, Validators.email]],
      accountOwnerPersonalPhone: [
        "",
        [Validators.required, Validators.pattern("^[0-9]{3}[0-9]{3}[0-9]{4}$")],
      ],
      accountOwnerCompanyName: ["", [Validators.required]],
      DBAName: ["", Validators.required],
    });
  }

  get accountStartDateContractInvalid() {
    return (
      this.addSchool.get("accountStartDateContract").invalid &&
      this.addSchool.get("accountStartDateContract").touched
    );
  }

  get accountContractExpirationDateInvalid() {
    return (
      this.addSchool.get("accountContractExpirationDate").invalid &&
      this.addSchool.get("accountContractExpirationDate").touched
    );
  }

  get autoRenewInvalid() {
    return (
      this.addSchool.get("autoRenew").invalid &&
      this.addSchool.get("autoRenew").touched
    );
  }

  get accountOwnerPersonalNameInvalid() {
    return (
      this.addSchool.get("accountOwnerPersonalName").invalid &&
      this.addSchool.get("accountOwnerPersonalName").touched
    );
  }

  get accountOwnerPersonalEmailInvalid() {
    return (
      this.addSchool.get("accountOwnerPersonalEmail").invalid &&
      this.addSchool.get("accountOwnerPersonalEmail").touched
    );
  }

  get accountOwnerPersonalPhoneInvalid() {
    return (
      this.addSchool.get("accountOwnerPersonalPhone").invalid &&
      this.addSchool.get("accountOwnerPersonalPhone").touched
    );
  }

  get accountOwnerCompanyNameInvalid() {
    return (
      this.addSchool.get("accountOwnerCompanyName").invalid &&
      this.addSchool.get("accountOwnerCompanyName").touched
    );
  }

  get DBANameInvalid() {
    return (
      this.addSchool.get("DBAName").invalid &&
      this.addSchool.get("DBAName").touched
    );
  }

  save() {
    this.loading = true;
    if (this.addSchool.invalid) {
      this.loading = false;
      return Object.values(this.addSchool.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }

    this.api.createSchool(this.addSchool.value).subscribe((data) => {
      this.showToast(
        "Message",
        "School Created!",
        "success",
        "checkmark-outline"
      );
      this.object = { bucketName: data["data"]["bucket"] };
      this.apiD.createBucket(this.object).subscribe(
        (res) => {
          if (this.file) {
            this.object = { file: this.file, bucket: data["data"]["bucket"] };
            this.apiD.uploadFile(this.object).subscribe((response) => {
              this.responseFile = response;
              if (this.responseFile.status == "ok") {
                const update = Object.assign(data["data"], {
                  logo: this.responseFile.file,
                });
                this.api
                  .updateSchool(data["data"]["idAuth"], update)
                  .subscribe((response) => {
                    this.loading = false;
                    this.router.navigate(["/admin/schools"]);
                  });
              }
            });
          } else {
            this.router.navigate(["/admin/schools"]);
          }
        },
        (err) => {
          this.loading = false;
          this.showToast(
            "Message",
            "An error occurred, please try again",
            "danger",
            "alert-circle-outline"
          );
        }
      );
    });
  }

  goBack() {
    this.location.back();
  }

  onFileChanged(event: any) {
    if (event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
      this.fileName = this.file.name;
    }
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
