import { Component, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { usersService } from "../@core/api/base-api/users.api";
import { AuthService } from "../@auth/auth.service";
import { NbDialogService, NbIconConfig, NbToastrService } from "@nebular/theme";
import { WindowDeleteConfirmComponent } from "../shared/window-delete-confirm/window-delete-confirm.component";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
@Component({
  selector: "app-user-title-names",
  templateUrl: "./user-title-names.component.html",
  styleUrls: ["./user-title-names.component.scss"],
})
export class UserTitleNamesComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  data: any;
  roles: any;
  edit: boolean;
  del: boolean;
  school: string;
  loading: boolean;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ["name", "roles", "options"];
  constructor(
    private location: Location,
    private apiU: usersService,
    private auth: AuthService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.apiU.getRoles().subscribe((res) => {
      this.roles = res;
    });
    this.school = localStorage.getItem("urlId");
    this.edit = this.auth.permissions.includes("update:user-title-names");
    this.del = this.auth.permissions.includes("delete:user-title-names");
    this.loadData();
  }

  loadData() {
    this.apiU.getUserTitleNames().subscribe((res) => {
      this.data = res;
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    });
  }

  changeRoles(roles, id) {
    this.apiU.updatetUserTitleNames(id, { roles }).subscribe((data) => {});
  }

  delete(id, name) {
    this.dialogService
      .open(WindowDeleteConfirmComponent, {
        context: {
          title: "User Title Name",
          name,
          id,
        },
      })
      .onClose.subscribe((val) => {
        if (val) {
          this.apiU.deleteUserTitleNames(id).subscribe(
            (res) => {
              this.showToast(
                "Message",
                "User title Name Deleted!",
                "success",
                "checkmark-outline"
              );
              this.loadData();
            },
            (err) => {
              this.showToast(
                "Message",
                "An error occurred, please try again",
                "danger",
                "alert-circle-outline"
              );
            }
          );
        }
      });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  showToast(title, message, status, icon) {
    const iconConfig: NbIconConfig = {
      icon: icon,
      pack: "eva",
      status,
      options: ["10000"],
    };
    this.toastrService.show(message, title, iconConfig);
  }
}
