import { Component, OnInit, ViewChild } from "@angular/core";
import { DocumentsService } from "../@core/api/base-api/documents.api";
import { AuthService } from "../@auth/auth.service";
import { usersService } from "../@core/api/base-api/users.api";
import { ActivatedRoute } from "@angular/router";
import { SchoolService } from "../@core/services/school.service";
import { SchoolsService } from "../@core/api/base-api/schools.api";
import { NbDialogService } from "@nebular/theme";
import { viewDocumentComponent } from "../documents/view-document/view-document";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Location } from "@angular/common";

@Component({
  selector: "app-documents",
  templateUrl: "./documents-students.component.html",
  styleUrls: ["./documents-students.component.scss"],
})
export class DocumentsStudentsComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  public responseFile;
  public data: any = [];
  public users: any = [];
  public userDocument: any = [];
  public role: String;
  public id: String;
  public userId: any;
  public name: String;
  files: FileList;
  object: {};
  Arr = Array;
  flag: Boolean;
  schoolId: string;
  school: string;
  bucket: string;
  isApprover: boolean;
  idTitle: string;
  count: Number;
  objectF: any;
  loading: boolean;
  dataSource = new MatTableDataSource();
  dataSource2 = new MatTableDataSource();
  displayedColumns: string[] = ["firstName", "email", "options"];
  displayedColumns2: string[] = [
    "documentName",
    "approve",
    "acknowledge",
    "attempted",
    "view",
  ];
  displayedColumns3: string[] = [
    "documentName",
    "file",
    "status",
    "Acknowledge",
  ];
  constructor(
    private api: DocumentsService,
    private apiU: usersService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private schoolS: SchoolService,
    private schoolApi: SchoolsService,
    private dialogService: NbDialogService,
    private location: Location
  ) {}

  ngOnInit() {
    this.loading = true;
    this.flag = false;
    this.userId = this.route.snapshot.paramMap.get("studentId");
    this.school = this.route.snapshot.paramMap.get("id");
    this.schoolId = localStorage.getItem("urlId");
    const value = this.school ? this.school : this.schoolId;
    this.schoolApi.getTenant(value).subscribe((res) => {
      this.bucket = res["bucket"];
    });
    if (this.userId) {
      this.apiU.getUserById(this.userId).subscribe((data) => {
        this.name = data["name"];
        this.flag = true;
      });
    }

    this.auth.getUser$().subscribe((data) => {
      this.id = data["sub"];
      this.role = data["https://cor4edu.com/roles"][0];
      this.isApprover = this.auth.permissions.includes(
        "update:approver-documents"
      );

      this.refresh();
    });
    this.objectF = Object.keys;
  }

  public Upload(event) {
    const idDocument = event.target.id;
    this.files = event.target.files;
    this.object = {
      id: this.id,
      file: this.files[0],
      bucket: this.bucket,
    };
    this.api.uploadFile(this.object).subscribe(
      (response) => {
        this.responseFile = response;
        if (this.responseFile.status == "ok") {
          const data = {
            documentId: idDocument,
            userId: this.id,
            file: this.responseFile.file,
          };
          this.api.postDocument(data).subscribe((data) => {
            this.refresh();
          });
        } else {
          console.log("error");
        }
      },
      (error) => {
        const data = {
          documentId: idDocument,
          userId: this.id,
        };
        this.api.postDocument(data).subscribe((data) => {
          this.refresh();
        });
      }
    );
  }

  toggle(checked: boolean, id: String) {
    const object = { iagree: checked };
    this.api.updateStatusDocument(object, id).subscribe((data) => {});
  }

  open(file, document) {
    this.dialogService.open(viewDocumentComponent, {
      context: {
        title: document,
        bucket: this.bucket,
        file: `${
          this.role != "PAID Application, Collect Documents Required to Enroll"
            ? this.userId
            : this.id
        }/${file}`,
      },
    });
  }

  approver(status, approver, idD, idT) {
    const object = {
      "approvers.$.approved": status,
      "approvers.$.approverId": this.id,
      "approvers.$.approvalDate": new Date(),
      "approvers.$.approverTitleRequired": idT,
    };
    this.api.updateStatusDocument(object, idD).subscribe((data) => {
      this.refresh();
    });
  }

  refresh() {
    if (this.role == "PAID Application, Collect Documents Required to Enroll") {
      this.api.getDocumentStudentsbyId(this.id).subscribe((data) => {
        this.data = data;
        this.dataSource2 = new MatTableDataSource(this.data);
        this.dataSource2.paginator = this.paginator;
        this.dataSource2.sort = this.sort;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
          return this.getPropertyByPath(data, sortHeaderId);
        };
        if (Object.keys(data[0]["approvers"][0])) {
          this.count = Object.keys(data[0]["approvers"][0]).length;
        }
        this.loading = false;
      });
    } else {
      if (this.approver) {
        this.apiU.getUserById(this.id).subscribe((data) => {
          this.idTitle = data["userTitleName"];
        });
      }
      this.apiU.getUsersByAccount().subscribe((data) => {
        this.users = data;
        this.dataSource = new MatTableDataSource(this.users);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
          return this.getPropertyByPath(data, sortHeaderId);
        };
        this.loading = false;
      });
      this.api.getDocumentStudentsbyId(this.userId).subscribe((data) => {
        this.data = data;
        this.dataSource2 = new MatTableDataSource(this.data);
        this.dataSource2.paginator = this.paginator;
        this.dataSource2.sort = this.sort;
        this.loading = false;
      });
    }
  }

  getPropertyByPath(obj: Object, pathString: string) {
    return pathString.split(".").reduce((o, i) => o[i], obj);
  }

  goBack() {
    this.location.back();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}
