import { Component, OnInit } from "@angular/core";
import { AuthService } from "../@auth/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { SchoolsService } from "../@core/api/base-api/schools.api";
import { DocumentsService } from "../@core/api/base-api/documents.api";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { usersService } from "../@core/api/base-api/users.api";
import { NbToastrService } from "@nebular/theme";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  schoolId: string;
  logo: string;
  name: string;
  resetPassword: FormGroup;
  bucket: string;
  constructor(
    private auth: AuthService,
    private router: Router,
    private api: SchoolsService,
    private route: ActivatedRoute,
    private apiD: DocumentsService,
    private fb: FormBuilder,
    private userService: usersService,
    private toastrService: NbToastrService,
  ) {}

  ngOnInit() {
    this.createForm();
    // this.singUp.get("userTitleNames").setValue("rol_UEevEAZbUjI6yNlt");
    this.schoolId = this.route.snapshot.paramMap.get("id");
    this.auth.isAuthenticated$.subscribe((data) => {
      if (data) {
        this.router.navigate(["/"]);
        return;
      }
    });

    if(this.schoolId == "admin"){
      this.router.navigate(["/"]);
    }

    this.api.getTenant(this.schoolId).subscribe((data) => {
      this.name = data["accountOwnerCompanyName"];
      if (data["logo"]) {
        this.bucket = data["bucket"];
        this.name = data["accountOwnerCompanyName"];
        this.apiD.getFile(this.bucket, data["logo"]).subscribe((data) => {
          this.logo = data["url"];
        });
      }
    });
  }

  createForm() {
    this.resetPassword = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
    });
  }

 /* get firstNameInvalid() {
    return (
      this.singUp.get("firstName").invalid &&
      this.singUp.get("firstName").touched
    );
  }

  get lastNameInvalid() {
    return (
      this.singUp.get("lastName").invalid && this.singUp.get("lastName").touched
    );
  }

  get cellPhoneInvalid() {
    return (
      this.singUp.get("cellPhone").invalid &&
      this.singUp.get("cellPhone").touched
    );
  }*/

  get emailInvalid() {
    return this.resetPassword.get("email").invalid && this.resetPassword.get("email").touched;
  }

  save() {
    if (this.resetPassword.invalid) {
      return Object.values(this.resetPassword.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }

    this.userService
      .resetPassword(this.resetPassword.value, this.schoolId)
      .subscribe((data) => {
        this.showToast('Message', 'Reset password send!', 'success');
        this.router.navigate(["/", this.schoolId, 'login']);
      });
  }

  showToast(title, message, status) {
    this.toastrService.show(message, title, { status });
  }
}
