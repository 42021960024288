import { Component, OnInit, TemplateRef } from "@angular/core";
import { AuthService } from "../@auth/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { SchoolsService } from "../@core/api/base-api/schools.api";
import * as Cookie from "js-cookie";
import { DocumentsService } from "../@core/api/base-api/documents.api";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { NbDialogService } from "@nebular/theme";
import { ShowcaseDialogComponent } from "../shared/showcase-dialog/showcase-dialog.component";
import { SchoolService } from "../@core/services/school.service";
import { usersService } from "../@core/api/base-api/users.api";
import {
  from,
  of,
  Observable,
  BehaviorSubject,
  combineLatest,
  throwError,
} from "rxjs";
import { JwtHelperService } from "@auth0/angular-jwt";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  schoolId: string;
  logo: string;
  login: FormGroup;
  name: string;
  res: string;
  dialog: TemplateRef<any>;
  private _subject: Subject<any>;
  bucket: string;

  id: string;
  role: string;
  url: string;
  urlUser: string;
  lastLoginAt: string;
  nameFull: string;
  object: {};

  private helper = new JwtHelperService();

  

  constructor(
    private auth: AuthService,
    private router: Router,
    private api: SchoolsService,
    private route: ActivatedRoute,
    private apiD: DocumentsService,
    private fb: FormBuilder,
    private dialogService: NbDialogService,
    private SchoolService: SchoolService,
    private userService: usersService
  ) {
    this._subject = new Subject<any>();
  }

  ngOnInit() {
    this.createForm();
    this.logo = "assets/images/logo.png";

    if (localStorage.getItem("urlId")) {
      this.url = this.route.snapshot.paramMap.get("id");
      this.urlUser = localStorage.getItem("urlId");
      this.router.navigate([this.urlUser]);
      /*if(this.url != this.urlUser ){
        this.res = "The user does not belong to this school. The correct url was applied.";
          this.openUrl(); 
      }*/
    }

    this.auth.isAuthenticated$.subscribe((data) => {
      if (data) {
        this.auth.getUser$().subscribe((data) => {
          if (data != undefined) {
            this.id = data["sub"];
            this.role = data["https://cor4edu.com/roles"][0];
            if (this.role != "cor-admin" && this.role != "ag-admin-us") {
              this.object = {
                lastLoginAt: data["updated_at"],
                firstName: data["given_name"],
                lastName: data["family_name"],
                email: data["email"],
              };    
              this.userService.updateProfile(this.object, this.id).subscribe((data) => {
              });       
            } 

            this.userService.getUserById(this.id).subscribe((data) => {
              if (data != null) {
                if (data["systemAccess"] == false) {
                  this.res = "ACCESS DENIED: " + data["systemStatus"];
                  this.openLogout();
                }
              }
            });
          }
        });
        this.router.navigate([this.urlUser]);
        return;
      }
    });

    this.schoolId = this.route.snapshot.paramMap.get("id");

    this.api.getTenant(this.schoolId).subscribe((data) => {
      if (data == undefined) {
        this.logo = "assets/images/logo.png";
      } else {
        if (data["logo"]) {
          this.name = data["accountOwnerCompanyName"];
          this.bucket = data["bucket"];
          this.apiD.getFile(this.bucket, data["logo"]).subscribe((data) => {
            this.logo = data["url"];
          });
        }
      }
    });
  }
  get emailInvalid() {
    return this.login.get("email").invalid && this.login.get("email").touched;
  }
  get passwordInvalid() {
    return (
      this.login.get("password").invalid && this.login.get("password").touched
    );
  }

  createForm() {
    this.login = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]],
    });
  }

  async save() {
    if (this.login.invalid) {
      return Object.values(this.login.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }
    this.auth.login(this.login.value, this._subject);
    this._subject.subscribe((data) => {
      if (data["description"] == "Wrong email or password.") {
        this.res = "Incorrect email or password";
        this.open();
      } else {
        this.res = "An error occurred. Try again.";
        this.open();
      }
    });
  }

  open() {
    this.dialogService.open(ShowcaseDialogComponent, {
      context: {
        title: "Error",
        message: this.res,
      },
    });
  }

  openUrl() {
    this.dialogService
      .open(ShowcaseDialogComponent, {
        hasBackdrop: true,
        closeOnBackdropClick: false,
        context: { title: "Information", message: this.res },
      })
      .onClose.subscribe((data) => {
        this.router.navigate([this.urlUser]);
      });
  }

  openLogout() {
    this.dialogService
      .open(ShowcaseDialogComponent, {
        hasBackdrop: true,
        closeOnBackdropClick: false,
        context: { title: "Error", message: this.res },
      })
      .onClose.subscribe((data) => {
        this.auth.logout();
      });
  }
}
